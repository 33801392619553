import React from "react";
import ReactDOM from "react-dom/client";
import Popup from "react-popup";
import "./index.css";
import App from "./App";
import store from "./app/store";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { Provider } from "react-redux";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "./index.css";
import GlobalCallModal from "../src/views/messageview/globalModal";
import { CallProvider } from "../src/views/messageview/context";
import { error } from "./app/features/register/registerSlice";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from "react-router-dom";
if (process.env.NODE_ENV === "production") disableReactDevTools();

const root = ReactDOM.createRoot(document.getElementById("root"));
document.getElementById("popupContainer");

root.render(
  <React.StrictMode>
    <CallProvider>
      <GlobalCallModal />
      <Provider store={store}>
        <App />
      </Provider>
    </CallProvider>
  </React.StrictMode>
);
serviceWorkerRegistration.register({bypassNodeEnvProduction:false,});
Popup.alert();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
