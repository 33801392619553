import React,{useRef, useState,useEffect} from 'react'
import backicon from "../../icons/backIcon.svg"
import uploadfileIcon from "../../icons/uploadfileIcon.svg"
import PacmanLoader from "react-spinners/ClockLoader";
import { ToastContainer, toast } from "react-toastify";
import { post_exclusive_img, ProfilechangeStatus,post_exclusive_content } from '../../app/features/profile/profile';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export const Add_exclusive = () => {
    let ref = useRef(null)
    let thumbref = useRef(null)
    let dispatch = useDispatch()
    const token = useSelector((state) => state.register.refreshtoken);
    const posteximgStats = useSelector((state) => state.profile.posteximgStats);
    const testmsg = useSelector((state) => state.profile.testmsg);
    const postexstats = useSelector((state) => state.profile.postexstats);
    const postexIMG = useSelector((state) => state.profile.postexIMG);
    const thumbimg = useSelector((state) => state.profile.thumbimg);
    const userid = useSelector((state) => state.register.userID);
    const navigate = useNavigate()
    let [imagelink, setimagelink] = useState("")
    let [thumbnaillink, setthumbnaillink] = useState("")
    let [thumbname, setthumbname] = useState("choose thumbnail")
    let [filename, setfilename] = useState("choose content file")
    let [imgtype, setimgtype] = useState("image/*")
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#d49115");
    let [disable_form, set_disable_form] = useState(false)
    let [data, setdata] = useState({
        contentname:"",
        price:"",
        content_type:"image",
        contentlink:"",
        userid:userid,
        thumbnaillink:""

    })

    
   
    useEffect(()=>{
        // if(!userid){
        //     navigate(-1)
        // }
        if(posteximgStats === "succeeded"){
            
            if(postexstats !== "loading"){
                dispatch(ProfilechangeStatus("idle"))
                dispatch(post_exclusive_content({contentname:data.contentname,price:data.price,content_type:data.content_type,contentlink:postexIMG,thumblink:thumbimg,userid:data.userid,token}))
               
            }

        }

        if(posteximgStats === "failed"){
            setLoading(false)
            set_disable_form(false)
            setfilename("choose content file")
            dispatch(ProfilechangeStatus("idle"))
            
        }

        if(postexstats === "succeeded"){
            dispatch(ProfilechangeStatus("idle"))
            navigate(-1)
        }

        if(postexstats === "failed"){
            setLoading(false)
            set_disable_form(false)
            setfilename("choose content file")
            dispatch(ProfilechangeStatus("idle"))
            console.log(testmsg)
        }

    },[posteximgStats, postexstats])

    const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

   const handleChange = async (file) => {
    let length = await getVideoDuration(file);

    return length;
   };

  const submit_upload = async(e)=>{
    e.preventDefault()

    if(!imagelink){
        toast.error("please select content file")
        return
    }

    if(!thumbnaillink){
        toast.error("please select thumbnail file")
        return
    }
    
    if(data.content_type && data.price && data.contentname && imagelink && thumbnaillink ){
        setLoading(true)
        set_disable_form(true)
        dispatch(post_exclusive_img({imagelink,thumbnaillink}))
    }else{
        return
    }
    
  }

  const handle_sumit = (e)=>{
    const { name, value, type, files } = e.target;

    if(type === "text"){
        setdata((prev) => ({ ...prev, [name]: value }));
    }else if(type === "number"){
        setdata((prev) => ({ ...prev, [name]: value }));
    }
  }
    
  return (
    <div className='w-full flex flex-col min-h-screen bg-slate-400 p-4 items-center'>
        <div className='w-full max-w-lg flex flex-col h-full bg-slate-200 border rounded-md shadow-lg shadow-slate-300 '>
            <div className='flex flex-row '>
                <button onClick={(e)=>navigate(-1)}>
                    <img
                    alt='backIcon'
                    src={backicon}
                    />
   
                </button>
                <p className='text-center mx-auto mt-3 font-bold text-lg'>Content Upload Form</p>
            </div>

            <div className='p-3 min-h-full overflow-auto '>
            <ToastContainer position="top-center" theme="dark" />
               {loading && (
                            <div className="flex flex-col items-center mt-16 w-full z-10 relative top-3/4">
                                <PacmanLoader
                                color={color}
                                loading={loading}
                                size={30}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                margin={"auto"}
                                />

                                <p className="jost text-yellow-500 font-bold
                                
                                ">uploading...</p>
                            </div>
                            )}
                <fieldset disabled={disable_form}>
                    <form onSubmit={submit_upload}>
                        <div className='flex flex-col h-1/5'>
                            <label className='font-semibold text-sm'>Name your content</label>
                            <input type='text' onChange={handle_sumit} required name='contentname' value={data.contentname} placeholder='Input Content Name' className='rounded-md h-8 w-2/3 placeholder:text-slate-100 bg-slate-500 placeholder:text-center pl-2' />
                        </div>

                       

                        <div className='flex flex-col h-1/5 mt-2'>
                            <label className='font-semibold text-sm'>content gold price</label>
                            <input type='number' onChange={handle_sumit} value={data.price} required name='price' placeholder='Gold Price' className='rounded-md h-8 w-2/3 placeholder:text-slate-100 bg-slate-500 placeholder:text-center pl-2' />
                        </div>

                        <div className='flex flex-col h-1/5 mt-2'>
                            <label className='font-semibold text-sm'>content type</label>
                           <select className='rounded-md w-2/3 h-8' required onChange={(e)=>{
                            if(e.currentTarget.value === "image"){
                                setimgtype("image/*")
                                setdata((prev) => ({ ...prev, ['content_type']: "image" }));
                            }else if(e.currentTarget.value === "video"){
                                setimgtype(".mp4")
                                setdata((prev) => ({ ...prev, ['content_type']: "video" }));
                            }
                           }}>
                            <option value={'image'}>Image</option>
                            <option value={'video'}>Video</option>
                           </select>
                        </div>

                        <div className='w-full flex flex-col mt-2 overflow-auto'>
                            <label className='font-semibold text-sm'>Choose thumbnail for your Content</label>
                            <div className='border border-slate-800 border-dotted h-32 rounded-md w-full flex flex-col justify-center items-center'>
                                <details className='mb-1' >
                                    <summary className='rounded-md border border-solid border-slate-500 p-1 font-bold bg-black text-white'>What Is Thumbnail ?</summary>
                                    <p className='p-1 rounded-md text-xs bg-slate-700 text-white'>A thumbnail is a small image that represents a larger image or video</p>
                                </details>

                                <details className='mb-1'>
                                    <summary className='rounded-md border border-solid border-slate-500 p-1 font-bold bg-black text-white text-sm'>Importants Of Thumbnail</summary>
                                    <p className='p-1 rounded-md text-xs bg-slate-700 text-white'>Good thumbnail helps attract buyers especially on Video content</p>
                                </details>
                                
                                <button className='mx-center' onClick={(e)=>{
                                    e.preventDefault()
                                    thumbref.current.click()
                                    }} >
                                    <div className='flex flex-col'>
                                        <img
                                        alt="uploadIcon"
                                        src={uploadfileIcon}
                                        className="h-7"
                                        />
                                    <p className='font-bold text-sm text-blue-600'>{thumbname}</p>

                                    </div>

                                </button>
                                <input type='file' accept="image/*" name='thumbnail'   hidden ref={thumbref} onChange={async(e)=>{
                                    if(e.target.files[0]){
                                        setthumbname(e.target.files[0].name)
                                        setthumbnaillink(e.target.files[0])
                                        }
                                }}></input>
                                
                            </div>
                        </div>

                        <div className='w-full h-2/5 flex flex-col mt-2'>
                            <label className='font-semibold text-sm'>Content File</label>
                            <div className='border border-slate-800 border-dotted h-32 rounded-md w-full flex flex-col justify-center items-center'>
                                <button className='mx-center' onClick={(e)=>{
                                    e.preventDefault()
                                    ref.current.click()
                                    }} >
                                    <div className='flex flex-col'>
                                        <img
                                        alt="uploadIcon"
                                        src={uploadfileIcon}
                                        className="h-7"
                                        />
                                    <p className='font-bold text-sm text-blue-600'>{filename}</p>

                                    </div>

                                </button>
                                <input type='file' accept={imgtype} name='fileinput'   hidden ref={ref} onChange={async(e)=>{
                                   setfilename("please wait...")
                                    if(e.target.files[0]){
                                        if(imgtype === "image/*"){
                                            setfilename(e.target.files[0].name)
                                            setimagelink(e.target.files[0])
                                            
                                        }else if(imgtype === ".mp4"){
                                            if(await handleChange(e.target.files[0]) > 65){
                                                setfilename("choose content file")
                                                toast.error("image must be less or eqal to 2min",{auto:2000})
    
                                            }else{
                                                setfilename(e.target.files[0].name)
                                                setimagelink(e.target.files[0])
    
                                            }

                                        }
                                      

                                        }
                                }}></input>
                                
                            </div>
                        </div>

                        <div className='flex flex-col w-full justify-center items-center mt-3 h-1/5'>
                        <button className='bg-blue-600 w-1/3 h-fit pt-1 pb-1 rounded-md font-semibold text-white hover:bg-blue-400 active:to-blue-300' 
                        type='submit'
                        >Upload content
                        </button>
                         
                        </div>

                        

                    </form>
                </fieldset>
            </div>

        </div>
        
    </div>
  )
}
