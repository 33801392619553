import React, {useState, useEffect} from 'react'
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Switch } from "../../components/switch";
import { useSelector, useDispatch } from "react-redux";
import { updatesetting, ProfilechangeStatus , getprofile} from "../../app/features/profile/profile";


export const Emailnotification = () => {

  const navigate = useNavigate();
  let updatesettingstats = useSelector((state) => state.profile.updatesettingstats);
  let emailnote = useSelector((state) => state.profile.emailnote);
  const [isOn, setIsOn] = useState(emailnote);
  const [notification, setnotification] = useState(false);
  const token = useSelector((state) => state.register.refreshtoken);
  const userid = useSelector((state) => state.register.userID);
  const dispatch = useDispatch()

  useEffect(()=>{
    if(updatesettingstats === "succeeded"){
      dispatch(getprofile({ userid }));
      setIsOn(!isOn)
      dispatch(ProfilechangeStatus("idle"))
    }
  },[updatesettingstats])

  const handleToggle = () => {
    if(updatesettingstats !== "loading"){
     
      dispatch(updatesetting({token, userid,pushnot:"nothing",emailnot:!isOn}))
    }
  }

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black text-white">
  
    <div className='w-full md:w-2/4 flex flex-col'>
    <header className="flex gap-4 items-center">
      <FaAngleLeft
        color="white"
        size={30}
        onClick={() => {
          navigate("/notification");
        }}
      />

      <h4 className="font-bold text-lg text-white">Email NOTIFICATION</h4>
    </header>

    <div className="flex justify-between items-center  ">
        <div className="flex gap-2 items-center">
          <h4 className="text-md text-white font-semibold">
          Receive notification on your email
          </h4>
        </div>
        <div>
          <Switch isOn={isOn} handleToggle={handleToggle} />
        </div>
      </div>
    </div>
   
    </div>
  )
}
