import React, { useState,useEffect } from "react";
import { FaRegEyeSlash } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { ChangePass, changeStatus } from "../../app/features/register/registerSlice";
import { useSelector, useDispatch } from "react-redux";
import PacmanLoader from "react-spinners/ClockLoader";


export const ChangePasswordPage = () => {

  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const navigate = useNavigate();
  const chagepassword = useSelector(state=> state.register.chagepassword)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setpassword] = useState("")
  const [newpassword, setnewpassword] = useState("")
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#d49115");

  const dispatch = useDispatch()

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const changepassword = (e)=>{
    if(!newpassword || !password){
      toast.info("Fill in the two input boxes")
      return
    }

    if(newpassword.toLowerCase() !== password.toLowerCase()){
      toast.error("password mismatch")
      return
    }

    if(chagepassword !== "loading"){
      setLoading(true)
      dispatch(ChangePass({id:userid,token,password, isuser:true}))
    }
  }

  useEffect(()=>{
    if(!userid){
      window.location.href = "/";
    }
  },[])

  useEffect(()=>{
    if(chagepassword === "succeeded"){
      setLoading(false)
      toast.success("password changed successfull")
      dispatch(changeStatus("idle"))
    }
  },[chagepassword])

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black text-white">
      <div className='w-full md:w-2/4 flex flex-col'>
      <ToastContainer position="top-center" theme="dark" />
      {/* Header */}
      <header className="flex gap-4 items-center">
        <FaAngleLeft
          color="white"
          size={30}
          onClick={() => {
            navigate(-1);
          }}
        />
        <h4 className="font-bold text-lg text-white">
          PASSWORD SECURITY AND SAFETY
        </h4>
      </header>

              {loading && (
                    <div className="flex flex-col items-center mt-16 w-full z-10 relative top-3/4">
                                <PacmanLoader
                                color={color}
                                loading={loading}
                                size={30}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                margin={"auto"}
                                />

                                <p className="jost text-yellow-500 font-bold
                                
                                ">wait a moment...</p>
                    </div>
                )}

      {/* Form */}
      <div className="w-full max-w-md space-y-6 mt-10">
        {/* Password Field */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-white">
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              disabled={false}
              className="w-full px-4 py-4 bg-black text-white border border-gray-600 rounded-md"
              onChange={(e)=>setpassword(e.currentTarget.value)}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-4 flex items-center text-gray-400 hover:text-white"
            >
              {showPassword ? (
                <FaRegEyeSlash size={20} />
              ) : (
                <IoEyeOutline size={20} />
              )}
            </button>
          </div>
        </div>

        {/* Confirm Password Field */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-white">
            Confirm Password
          </label>
          <div className="relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              value={newpassword}
              disabled={false}
              className="w-full px-4 py-4 bg-black text-white border border-gray-600 rounded-md"
              onChange={(e)=>setnewpassword(e.currentTarget.value)}
            />
            <button
              type="button"
              onClick={toggleConfirmPasswordVisibility}
              className="absolute inset-y-0 right-4 flex items-center text-gray-400 hover:text-white"
            >
              {showConfirmPassword ? (
                <FaRegEyeSlash size={20} />
              ) : (
                <IoEyeOutline size={20} />
              )}
            </button>
          </div>
        </div>

        {/* Logout Button */}
        <button className="mt-6 w-full max-w-md px-4 py-3 bg-white text-black font-medium rounded-lg hover:bg-gray-500" onClick={changepassword}>
          Log out all devices and accounts
        </button>
      </div>
      </div>
    </div>
  );
};
