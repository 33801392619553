import io from "socket.io-client";

// export const URL = "http://localhost:3500";
// export const sucess_url = "http://localhost:3000/payment-sucess";
// export const fail_url = "http://localhost:3000/payment-fail";

 //export const URL = "http://localhost:3000";

export const URL = "https://mmekoapi.onrender.com";
export const sucess_url = "https://mmekosocial.onrender.com/payment-sucess";
export const fail_url = "https://mmekosocial.onrender.com/payment-fail";
export const tawkapi_key = "d6764e979a5013ff154092da79824f4a39bf403f"

export const socket = io.connect(`${URL}`);
