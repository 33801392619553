import React from 'react'

export const Bookingsuccess = ({setrequested}) => {
  return (
    <div className='w-48 h-fit bg-slate-200 mx-auto p-5 rounded-lg'>

        <p className='text-center pt-2 text-sm text-black'><span className='text-bold text-green-600'>Request sent, </span>please wait for model to accept your request</p>

        <div className='flex justify-center'>
            <button className='bg-green-500 mr-1 text-sm p-2 ml-1 mt-3 rounded-lg w-1/2 shadow-2xl' onClick={(e)=>{
              setrequested(false)
            }}>
                Ok
            </button>

            
        </div>
        
    </div>
  )
}
