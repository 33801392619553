import React from "react";

const Ruleslist = () => {
  const rules = [
    "Image must be clear",
    "Your ID must be fully in frame",
    "Photo must be clear and in color.",
    "ID must be fully in frame.",
    "Must be in color",
    "Text must be clearly visible",
    "Background must be minimal",
    "Image should not be edited, resized or rotated",
    "File must be png or jpg",
    "Must be under 7MB in size",
    "ID must be valid and not expired",
    "Facial verification is required to ensure the face is clear",
  ];

  return (
    <div className="mb-6">
      <h4 className="font-bold text-lg text-white mb-4">
        Rules: Please read for fast approval
      </h4>
      <ul className="list-disc list-inside text-gray-400">
        {rules.map((rule, index) => (
          <li key={index} className="text-sm mb-2">
            {rule}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Ruleslist;
