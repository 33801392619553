import React from "react";
import { useState, useEffect } from "react";
import person from "../../../icons/icons8-profile_Icon.png";
import { useNavigate } from "react-router-dom";
//import ImageSample from "../../../icons/model.jpeg";
import DropdownMenu from "../components/dropdown";
import { downloadImage } from "../../../api/sendImage";
import { unfollow, getfollow, ProfilechangeStatus } from "../../../app/features/profile/profile";
import { useSelector, useDispatch } from "react-redux";

export const FollowerCard = ({ name, image, following, id, setLoading, canmessage, modelid }) => {
  //const [isFollowing, setIsFollowing] = useState(following);
  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const fllowmsg = useSelector((state) => state.profile.fllowmsg);
  const [proimage, setproimage] = useState(person)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const unfollow_stats = useSelector((state) => state.profile.unfollow_stats);
  const getfollow_stats = useSelector((state) => state.profile.getfollow_stats);
  const [modelid1, setmodelid1] = useState([]);

  useEffect(()=>{
  setmodelid1([id,userid])
    if(image){
      let img = downloadImage(image,"profile")
      
      if(img){
        setproimage(img)
      }

    }
  

  })

  useEffect(()=>{
    if (unfollow_stats === "succeeded") {
      //console.log("unfollow success")
       dispatch(getfollow({userid,token}))
     
    }

    

  },[unfollow_stats])

  useEffect(()=>{
    if(getfollow_stats === "succeeded"){
      //console.log("success follow")
     // setLoading(false)
      dispatch(ProfilechangeStatus("idle"))
    }

    

  },[getfollow_stats])

  const deleteFollow = ()=>{
    if(following === true){
      //am followerid here, which means this are list of people that i follow
      if(unfollow_stats !== "loading"){
        //setLoading(true)
        dispatch(unfollow({userid:id,followerid:userid,token}))
      }
    }else if(following === false){
      // am userid here which means this is my followers list
      if(unfollow_stats !== "loading"){
        //setLoading(true)
        dispatch(unfollow({userid:userid,followerid:id,token}))
      }
    }
  }

  // const handleFollow = () => {
  //   setIsFollowing(!isFollowing);
  // };

  return (
    <div className="flex items-center justify-between py-4 px-2  border-gray-200">
      <div className="flex items-center cursor-pointer" onClick={() => navigate(`/profile/${id}`)}>
        <img
          src={proimage}
          alt={name}
          className="w-16 h-16 rounded-full mr-4"
        />
        <div>
          <h2 className="text-md font-semibold text-white">{name}</h2>
        </div>
      </div>

      <div className="relative">

      <DropdownMenu>
        <a
          onClick={(e)=>navigate(`/message/${modelid1.toString()}`)}
          className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
          role="menuitem"
        >
          Message
        </a>
        <a
          onClick={(e)=>deleteFollow()}
          className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
          role="menuitem"
        >
          Unfollow
        </a>
       
      </DropdownMenu>
      </div>
    </div>
  );
};
