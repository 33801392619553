// App.jsx
import React from "react";
import ModelImage from "../../icons/model.jpeg";
import { FaHandHoldingUsd } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


export const VerificationPage = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black text-white">
  
     <div className='w-full md:w-2/4 flex flex-col mb-12'>
         {/* Header */}
      <div className=" py-4">
        <h1 className="text-2xl font-bold text-left">Become A Model</h1>
      </div>
      <div className="max-w-md w-full bg-gray-800 rounded-lg overflow-hidden">
        {/* Main Content */}
        <div className="relative">
          <img
            src={ModelImage}
            alt="Model"
            className="w-full h-58 object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center">
            <p className="text-center text-slate-200 text-lg font-medium px-4 pb-10">
              Apply and be a Verified Model within a Couple Hours!
            </p>
            <button
              onClick={() => navigate("/verify")}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded"
            >
              Verification
            </button>

            <h2 className="text-lg font-bold text-center mt-10">
              What sets us Apart?
            </h2>
          </div>
        </div>

        {/* Footer Section */}
        <div className="py-6 px-4 bg-gray-900">
          <div className="flex flex-col items-center">
            <FaHandHoldingUsd color="white" size={34} />

            <div className="ml-3 pt-4">
              <h3 className="font-bold text-lg text-center">
                Internal Promotion
              </h3>
              <p className="text-sm text-center text-gray-400">
                Benefit from internal promotion via our EVP media swipe browser
                and user tailored suggestion algorithm. Gaining followers and
                subscribers directly through mmeko allows you to speed your time
                perfecting your content doing the things you enjoy
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center mt-10">
            <FaHandHoldingUsd color="white" size={34} />

            <div className="ml-3">
              <h3 className="font-bold text-lg text-center">Referra Bonuses</h3>
              <p className="text-sm text-center text-gray-400">
                Ypu will be given a special link that will link customers to
                your acount . You will earn 1% commissoon on ecerything the
                custommer spends on mmeko for 90 days. Also, you will earn 5%
                commission fo the models you rfer to mmeko for on year
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};
