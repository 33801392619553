import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Revenueshare = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">What is My Revenue Share ?</h4>
            
          </header>
         
         <p className="font-semibold">
         
         💡 No Hidden Fees!
          Mmeko keeps it simple—there are no extra fees for payment processing, payouts, or currency conversion. 🎯✅
            
         </p>

          </div>
      </div>
     
    </div>
  );
  
};