import React,{useState, useEffect} from "react";
import Locker from "../../icons/locker.svg";
import { downloadImage } from "../../api/sendImage";
import { ProfilechangeStatus, buy_exclusive_content, delete_exclusive_content, delete_exclusive_thumb } from "../../app/features/profile/profile";
import { getprofilebyid } from "../../app/features/profile/comprofile";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Notifybuy } from "./Notifybuy";
import { Viewcontent } from "./Viewcontent";

let disable_form = false
let timeout

export const Exclusive = ({ image,price,content_type,buy,contentlink,contentname,userid,setisbuying,id, me, thumblink }) => {
  let detail = `This Content is locked click to buy @${price} Gold`
  let [file, setfile] = useState(image)
  let balance = useSelector((state) => state.profile.balance);
  const testmsg = useSelector((state) => state.profile.testmsg);
  const thumbdelstats = useSelector((state) => state.profile.thumbdelstats);
  const buyexstats = useSelector((state) => state.profile.buyexstats);
  const deleteexstats = useSelector((state) => state.profile.deleteexstats);
  const [thumbimg, setthumbimg] = useState(image)
  const myuserid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [onpreesed, set_onpressed] = useState(false)
  const toastId = React.useRef(null);
  const contenttoast = React.useRef(null);
  

 
  useEffect(()=>{
    if(contentlink){
      disable_form = false
      setfile(downloadImage(contentlink,"post"))
    }

    if(thumblink){
      setthumbimg(downloadImage(thumblink,"post"))
    }

  },[])

  useEffect(()=>{
    if(buyexstats === "succeeded"){
      dispatch(ProfilechangeStatus("idle"))
      disable_form = false
      if(toastId.current){
        toast.dismiss(toastId.current)
      }
      setisbuying(true)
     
    }

    if(buyexstats === "failed"){
      detail = `This Content is locked click to buy @${price} Gold`
      if(toastId.current){
        toast.dismiss(toastId.current)
      }
      disable_form = false
      dispatch(ProfilechangeStatus("idle"))
    }

  

    if(deleteexstats === "succeeded"){
      
      dispatch(ProfilechangeStatus("idle"))
      dispatch(delete_exclusive_thumb(thumblink))
      
    }

    if(deleteexstats === "failed"){
      
      dispatch(ProfilechangeStatus("idle"))
      disable_form = false
    }

  },[buyexstats,deleteexstats])

  useEffect(()=>{
    
    if(thumbdelstats === "succeeded"){
      dispatch(ProfilechangeStatus("idle"))
      disable_form = false
      set_onpressed(false)
      dispatch(getprofilebyid({ userid: myuserid, clientid: myuserid }));
    }

  },[thumbdelstats])
  
  const clicktobuy = ()=>{

    if(buy === false){
      return true
    }
  }

  const buynow = ()=>{
    let mybalance = parseFloat(balance)
    let buyprice = parseFloat(price)
    let my_current_balance = mybalance - buyprice
 
    // check user balnace first
    if(mybalance >= buyprice){
      
     if(buyexstats !== "loading"){
       disable_form = true
       detail = "purchasing please wait..."
      
       dispatch(buy_exclusive_content({userid:myuserid,token, exclusiveid:id, price:price,pricebalance:my_current_balance,exclusivename:contentname,exclusivelink:contentlink}))
     }
 
    }else{
      if(toastId.current){
        toast.dismiss(toastId.current)
      }
      
       navigate("/topup")
    }
  }

  const cancel = ()=>{
    toast.dismiss(toastId.current)
  }

  

  const showcontent = ()=>{
    if(buy === true){
      if(content_type === "image"){
        return (
        <img
        src={file}
        alt="exclusive_img"
        className=" h-36 w-full object-cover rounded-md "
        onClick={(e)=>{
          contenttoast.current = toast(
            <Viewcontent  photo={file} phototype={content_type}/>,
            {autoClose:false}
          )
        }}
      />
        )

      }else if(content_type === "video"){
      return (<video
        src={file}
        alt="exclusive_img"
        className=" h-36 w-full object-cover rounded-md "
        autoplay
        controls
      />)
      }
    }else{
      return (
        <img
      src={thumbimg}
      alt="exclusive_img"
      className=" h-36 w-full object-cover rounded-md "
    />
      )
    }
  }

  const buycontent = ()=>{ 
    toastId.current =  toast(
    <Notifybuy price={price} buy={buynow} cancel={cancel}/>,
    {autoClose:false}
   )

  }

  const deletecontent = ()=>{
    if(deleteexstats !== "loading"){
      disable_form = true
      detail = "purchasing please wait..."
      dispatch(delete_exclusive_content({token,id}))
    }
  }

  
  return (
    <div className="relative my-1" 
    onMouseDown={(e)=>{
      if(me){
        timeout = window.setTimeout(()=>set_onpressed(true),1300)
      }
    }}
    onTouchStart={(e)=>{
      if(me){
        timeout = window.setTimeout(()=>set_onpressed(true),1300)
      }
    }}

    onMouseUp={(e)=>{
      clearTimeout(timeout)
    }}

    onTouchEnd={(e)=>{
      clearTimeout(timeout)
    }}
    >
      <ToastContainer position="top-center" theme="dark" />
      {onpreesed && (<button className="absolute top-1 left-0  w-full bg-slate-800" onClick={(e)=>deletecontent()}>
       <p className="font-bold text-white">Delete Content</p>

      </button>)}
     {showcontent()}

     {clicktobuy() && (<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-full px-4">
        <img
          src={Locker}
          alt="exclusive_img"
          className="w-6 h-6 object-cover mx-auto pb-1"
        />
        <h4 className="bg-green-600 text-white  rounded text-xs w-full">
          <button onClick={(e)=>buycontent()} disabled={disable_form}>
          {detail}

          </button>
          
        </h4>
      </div>)}
      <p className="text-sm text-white  overflow-hidden truncate">{contentname}</p>
    </div>
  );
};
