import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Contentnotallow = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">Content Not Allowed On Mmeko</h4>
            
          </header>
         
         <p>
          Mmeko is accepting of most styles of content, we try to be as inclusive as possible to all members of the 
          community. Due to regulations, laws and restrictions, there is a select number of content styles we cannot 
          have featured on fanvue and are classified as prohibited.
            
         </p>
         
         <h6 className="font-semibold">Do not upload, post, or share:</h6>

         <p className="pt-1">
         content that depicts, advertises, promotes, encourages, facilitates, or solicits (real, simulated, or implied) 
         sexual activities involving:
            <ul className="list-disc list-inside mt-2 text-gray-300">
              <li>minors</li>
              <li>incest</li>
              <li>bestiality</li>
              <li>violence</li>
              <li>rape</li>
              <li>lack of consent</li>
              <li>hypnosis</li>
              <li>intoxication</li>
              <li>sexual assault</li>
              <li>torture</li>
              <li>sadomasochistic abuse</li>
              <li>hardcore bondage</li>
              <li>asphyxiation</li>
              <li>extreme fisting</li>
              <li>genital mutilation</li>
              <li>necrophilia</li>
              <li>consumption of urine</li>
              <li>blood (including fake blood)</li>
              <li>excrement-related content (including scatological, vomit)</li>
              <li>age-play</li>
              <li>suggestions of underage participants</li>
              <li>illegal prostitution</li>
              <li>human trafficking</li>
              <li>escort services</li>
              <li>revenge porn</li>
              
              
            </ul>
          </p>

          </div>
      </div>
     
    </div>
  );
  
};