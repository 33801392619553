import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { downloadImage } from '../../../api/sendImage'
import { useSelector } from 'react-redux'

export const ViewIDcard = () => {
    const [ids,setids] = useState([])
    const navigate = useNavigate()
    const ref = useRef()
    const {id} = useParams()
    const imagelinks = id.split(",")
    const login = useSelector(state => state.register.logedin)

    useEffect(()=>{
        if(!login){
           navigate("/")
        }
        if(!id){
            navigate("/")
        }
        if(!ref.current){
            ref.current = true
            imagelinks.forEach(index =>{
                const image = downloadImage(index,"model")
                setids(value=> [...value,image])
            })


        }

    },[])

    const displayids = ()=>{
        if(ids.length > 0){
            ref.current = false
            return ids.map(value =>{
                return <img alt='IDcard' src={value} className='mb-4 rounded-md object-cover'></img>
              })

        }
     
    }
  return (
    <div className='sm:w-1/2 overflow-auto'>
    <p className='text-yellow-600 text-center font-semibold mt-2'>View Host Verification ID card</p>    
    
    <div className='p-5 flex flex-col item-center mb-4 sm:ml-8'>
        {
          displayids()
        }

    </div>
    </div>
  )
}
