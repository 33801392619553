import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Purchasegold = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Fans" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">How to purchase Golds ?</h4>
            
          </header>
         
         <p>
         Click the hamburger icon at the top right corner to expand the navigation bar, Then click Get more, 
         Choose the amount of golds you would like to purchase and proceed to payment page.
            
         </p>

          </div>
      </div>
     
    </div>
  );
  
};