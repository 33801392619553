import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { IoHelpBuoy } from "react-icons/io5";
import { BiSupport } from "react-icons/bi";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { GiWorld } from "react-icons/gi";
import { FaListCheck } from "react-icons/fa6";


export const HelpSupportPage = () => {
  const navigate = useNavigate();

  const navdata = [
   {
      name: "Speaker with support",
      icon: <BiSupport color="white" size={20} />,
      linktitle: "speaker",
    },
    {
      name: "Term and conditions",
      icon: <FaListCheck color="white" size={20} />,
      linktitle: "term_condition",
    },

    {
      name: "Privacy policy",
      icon: <MdOutlinePrivacyTip color="white" size={20} />,
      linktitle: "privacy_policy",
    },

    {
      name: "Community guidelines",
      icon: <GiWorld color="white" size={20} />,
      linktitle: "community",
    },
  ];
  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">

     <div className='w-full md:w-2/4 flex flex-col'>
      <header className="flex gap-4 items-center">
        <FaAngleLeft
          color="white"
          size={30}
          onClick={() => {
            navigate("/setting");
          }}
        />

        <h4 className="font-bold text-lg text-white">HELP AND SUPPORT</h4>
      </header>
      <p className="text-slate-400 text-sm">
        See information about support option, and our policies
      </p>
      <div className="pt-4">
        {navdata.map((nav, index) => (
          <div
            className="flex justify-between items-center mb-6 "
            key={index}
            onClick={() => navigate(`/${nav.linktitle}`)}
          >
            <div className="flex gap-2 items-center">
              <div>{nav.icon}</div>
              <h4 className="text-lg text-white font-semibold">{nav.name}</h4>
            </div>
            <div>
              <FaAngleRight color="white" />
            </div>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};
