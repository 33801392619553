import React, { useEffect, useState } from 'react'
import person from '../../icons/person.svg'
import PacmanLoader from "react-spinners/PacmanLoader";
import idcardicon from '../../icons/idcardIcon.svg'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast , ToastContainer } from 'react-toastify';
import { updatemodel, changemodelstatus, deletemodelImage } from '../../app/features/model/modelSlice';
import { userlocation } from '../../api/userlocation';
import deleteIcon from "../../icons/deleteicon.svg"
import { downloadImage } from '../../api/sendImage';


let times = [];
let hours = [];
let Interested = [];

export const Editmodel = () => {

    const login = useSelector(state => state.register.logedin)
    const token = useSelector(state => state.register.refreshtoken)
    const modelupdatestatus = useSelector(state => state.model.modelupdatestatus)
    const model = useSelector(state => state.model.modelbyid)
    const modelID = model.hostid
    const message = useSelector(state => state.model.message)
    const deletmodeImage_stats = useSelector(state => state.model.deletmodeImage_stats)
    const navigate= useNavigate()
    const dispatch = useDispatch()
  
  
      let [loading, setLoading] = useState(false);
      let [color, setColor] = useState("#d49115");
  
      const [name,setname] = useState(model.name)
      const [age,setage] = useState(model.age)
      const [location,setlocation] = useState()
      const [bodytype,setbodytype] = useState(model.bodytype)
      const [height,setheight] = useState(model.height)
      const [weight,setweight] = useState(model.weight)
      const [gender,setgender] = useState(model.gender)
      const [smoke,setsmoke] = useState(model.smoke)
      const [drink,setdrink] = useState(model.drink)
      const [pm,setpm] = useState('PM')
      const [duration,setduration] = useState('')
      const [days,setdays] = useState(model.duration)
      const [price,setprice] = useState(model.price)
      const [discription,setdiscription] = useState(model.description)
      const [imglist,setimglist] = useState([])
      const [disablebut,setdisablebut] = useState(false)
      const [hosttype,sethosttype] = useState(model.hosttype)
      const [photolink,setphotolink] = useState([])
      const [photocount,setphotocount] = useState();
      const [oldlink,setoldlink] = useState([])
    
  
  
      useEffect(()=>{
  
        if(modelupdatestatus === "succeeded"){

          if(deletmodeImage_stats !== "loading"){
            dispatch(deletemodelImage(oldlink))

          }
          
         
        }


        if(deletmodeImage_stats === "succeeded"){
         toast.success(`${message}`,{autoClose:2000})
         dispatch(changemodelstatus('idle'))
         navigate(`/modelbyid/${modelID}`)
        }
  
        if(modelupdatestatus === "failed"){
          setdisablebut(false)
          setLoading(false)
          toast.error(`${message}`,{autoClose:5000})
          dispatch(changemodelstatus('idle'))
          
        }
  
      },[modelupdatestatus])
  

       useEffect(()=>{
  
       
        if(deletmodeImage_stats === "succeeded"){
         toast.success(`${message}`,{autoClose:2000})
         dispatch(changemodelstatus('idle'))
         navigate(`/modelbyid/${modelID}`)
        }
  
  
      },[ deletmodeImage_stats])
  
     
  
      useEffect(()=>{
  
        if(!login){
          window.location.href = "/"
        }
       

       getLocation()


      })

      useEffect(()=>{
        if(model.photolink){
        
          const images = model.photolink.split(",")
          setoldlink(images)
          
          images.forEach(index =>{
            const IMG = downloadImage(index,'model')

            if(photolink.length < images.length){
              setphotolink(value=> [...value,IMG])
              setimglist(value=> [...value,IMG])
          }
           
          })
        
         
         }
      },[])
  
      const checkuserInput = ()=>{
      if(!age){
        toast.error(`age Empty`)
        return
  
      }
      if(!hosttype){
        toast.error(`Select host type`)
        return
  
      }
     
      if(!photolink){
        toast.error(`host image Empty`)
        return
      }
      if(!location){
        toast.error(`location Empty`)
        return
      }
      if(!price){
        toast.error(`price Empty`)
        return
      }
      if(!height){
        toast.error(`height Empty`)
        return
      }
      if(times.length <= 0){
        toast.error(`please select avalable hours`)
        return
      }
      if(hours.length <= 0){
        toast.error(`please select avalable days`)
        return
      }
     
      if(Interested.length <= 0){
        toast.error(`please select  Interested In client field`)
        return
      }
      if(!discription){
        toast.error(`Write your discription`)
        return
      }
  
      if(modelupdatestatus !== 'loading'){
        setdisablebut(true)
        setLoading(true)
        dispatch(
          updatemodel(
            {
              age,
              location,
              price,
              duration:days,
              bodytype,
              smoke,
              drink,
              interestedin:Interested.toString(),
              height,
              weight,
              description:discription,
              gender,
              timeava:times.toString(),
              daysava:hours.toString(),
              photolink:photolink,
              token,
              hosttype,
              hostid:model.hostid,
              imglist,
              photocount,
              name
  
            }
          )
        )
      }
  
      }

      const getLocation = async()=>{
        let loc = await userlocation();
  
        if(loc.success !== "failed"){
          setlocation(`${loc.country}`)
        }
      }
  
  
  
  
  return (
    <div className='w-full flex flex-col justify-center items-center mx-auto min-h-screen'>

      {/* <div className='mx-auto bg-red-200 w-full'></div> */}
      <div className='p-7 w-screen h-screen overflow-auto flex-col  m-auto'>
        <ToastContainer position='top-center'  theme='dark'/>
       <p className='text-yellow-600 font-semibold text-center underline underline-offset-4 text-2xl sm:w-1/2'>
          Edit model
       </p>
  
       
  
       <fieldset className='sm:w-1/2 sm:ml-10 mt-5 border p-5 rounded-2xl border-yellow-600 ' disabled={disablebut}>
          <label className='inpt mb-3 block text-yellow-600 font-semibold text-center'> {`${model.name}`}
          </label>
          
          <label className='text-yellow-600'>Edit age</label>
          <div className='flex justify-between'>
          <input type="range" min="18" max="70" class="slider" value={age}
          onChange={(e)=>{
              setage(e.currentTarget.value)
          }}
          ></input>
          <label className='text-yellow-600'>{age} years</label>
  
          </div>
  
          
          <label className='inpt mt-3 mb-3 block text-yellow-600 text-center' >{location}
          </label>
  
      
       <label for="bodytype" className='text-yellow-600 mr-2'>Select body type:</label>
  
        <select name="bodytype" className='btn mb-3' value={bodytype} onChange={(e)=>{
          setbodytype(e.currentTarget.value)
        }}>
        <option value="Slim">Slim</option>
        <option value="Curvy">Curvy</option>
        <option value="Chubby">Chubby</option>
        <option value="Normal">Normal</option>
        <option value="Muscular">Muscular</option>
        <option value="Althetic">Althetic</option>
        <option value="Skinny">Skinny</option>
        </select>
  
  
        <div>
      <div>
         <label  className='text-yellow-600 mr-2 font-semibold'>Height?  {height}</label>
      </div>
        

            <select  className='btn mb-3 bg-pink-900 w-1/2' value={height} onChange={(e)=>{
              setheight(e.currentTarget.value)
            }}size={4}>

{
                Array.from({length: 200}, (_, i) => i + 57).map(value =>{
                  return  <option value={`${value} cm`} className='bg-pink-900 text-slate-100 rounded-2xl w-full border mt-1 mb-1'>{`${value} cm`}</option>
                })
               }
           
           
           
            </select>
     </div>

     <div>
      <div>
         <label  className='text-yellow-600 mr-2 font-semibold'>Weight?  {weight}</label>
      </div>
        

            <select  className='btn mb-3 bg-pink-900 w-1/2' value={weight} onChange={(e)=>{
              setweight(e.currentTarget.value)
            }}size={4}>

              {
                Array.from({length: 120}, (_, i) => i + 40).map(value =>{
                  return  <option value={`${value} kg`} className='bg-pink-900 text-slate-100 rounded-2xl w-full border mt-1 mb-1'>{`${value} kg`}</option>
                })
               }
           
           
           
            </select>
     </div>

  
          <label for="gender" className='text-yellow-600 mr-2'> Gender:</label>
  
        <select name="gender" className='btn mb-3' value={gender} onChange={(e)=>{
          setgender(e.currentTarget.value)
        }}>
        <option value="Man">Man</option>
        <option value="Woman">Woman</option>
        <option value="Trans">Trans</option>
        </select>
  
        <div>
        <label for="smoke" className='text-yellow-600 mr-2'>Smoke:</label>
        <select name="smoke" className='btn mb-3' onChange={(e)=>{
          setsmoke(e.currentTarget.value)
        }} value={smoke}>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
        </select>
        </div>
  
        <div>
        <label for="drink" className='text-yellow-600 mr-2'>Drink:</label>
        <select name="drink" className='btn mb-3' onChange={(e)=>{
          setdrink(e.currentTarget.value)
        }} value={drink}>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
        </select>
        </div>
  
      
  
       </fieldset>
      
       <fieldset className='sm:w-1/2 sm:ml-10 mt-5 border p-5 rounded-2xl border-yellow-600 mb-10' disabled={disablebut}>
         
       <div className='flex-col border border-yellow-300 rounded-2xl p-4'>
  
          <p className='text-yellow-600'>Avalable hours</p>
  
          
         
          <div className='flex-col'>
  
          </div>
             <select name="pm/am" className='btn mb-3 flex-wrap' onChange={(e)=>{
                  
                  setpm(e.currentTarget.value)
              }}>
              <option value="PM">PM</option>
              <option value="AM">AM</option>
              </select>
          <div>
             
  
          <div className='grid  grid-cols-3'>
  
                  {
                      [1+`:00`+`${pm}`,2+`:00`+`${pm}`,3+`:00`+`${pm}`,4+`:00`+`${pm}`,5+`:00`+`${pm}`,6+`:00`+`${pm}`,7+`:00`+`${pm}`,8+`:00`+`${pm}`,9+`:00`+`${pm}`,10+`:00`+`${pm}`,11+`:00`+`${pm}`,12+`:00`+`${pm}`].map(value=>{
                      return  <div>
                              <label className='text-yellow-600 mr-1'>{value}</label>
                                  <input id='hourscheck' type='checkbox' value={`${value}`} className='mr-3 shrink' onClick={(e)=>{
                                     let input = e.currentTarget.value;
                                     let check = times.findIndex(value =>{
                                      return input === value
                                     })
                                     if(check !== -1){
                                      times.splice(check,1)
                                     }else{
                                      times.push(e.currentTarget.value)
                                     }
                                   
                                      console.log(times)
                                  }}></input>
                              </div>
                      })
                  }
  
  
           </div>
  
         
       
           
         
          </div>
  
         
  
       
  
       </div>
  
  
       <div className='flex-col border border-yellow-300 rounded-2xl p-4 mt-3'>
  
          <p className='text-yellow-600'>Avalable DAYS</p>
  
  
  
          <div className='flex-col'>
  
          <div className='grid  grid-cols-3'>
  
                  {
                      ["MON","TUE","WED","THUR","FRI","SAT",'SUN'].map(value=>{
                      return  <div className=''>
                              <label className='text-yellow-600 mr-1'>{value}</label>
                                  <input type='checkbox' value={`${value}`} className='mr-3 shrink' onClick={(e)=>{
                                  let input = e.currentTarget.value;
                                  let check = hours.findIndex(value =>{
                                      return input === value
                                  })
                                  if(check !== -1){
                                      hours.splice(check,1)
                                  }else{
                                      hours.push(e.currentTarget.value)
                                  }
                                  
  
                                  }}></input>
                              </div>
                      })
                  }
  
  
          </div>
  
  
          </div>
  
  
       </div>
  
          <div className='mt-3 border border-yellow-300 rounded-2xl p-2'>
  
              <label className='text-yellow-600'>Duration</label>
              <div className='flex justify-between'>
                  <input type="range" min="1" max="30" class="slider"
                  onChange={(e)=>{
                      setduration(e.currentTarget.value)
                  }}
                  ></input>
  
            <select name="days" className='btn mb-3 flex-wrap' value={duration} onChange={(e)=>{
                  
                  setdays(e.currentTarget.value)
              }}>
              <option value={`${duration}min`}>{duration}MIN</option>
              <option value={`${duration}hour`}>{duration}HOUR</option>
              <option value={`${duration}day`}>{duration}DAY</option>
              </select>  
  
            
             
         
            </div>
            
            <div className='flex-col'>
  
  <label className='text-yellow-600 mr-2'>Choose category</label>
  
  <select name="hosttype" value={hosttype} className='btn mb-3 flex-wrap mt-2 border-solid border border-1 p-1 rounded-lg border-yellow-600' onChange={(e)=>{
                  
                  sethosttype(e.currentTarget.value)
              }}>
              <option value={`Fan meet`}>Fan meet</option>
              <option value={`Fan date`}>Fan date</option>
              <option value={`Private show`}>Private show</option>
              </select>  
  
  
  </div>
  
          <label className='text-yellow-600'>Please enter your desired tip amount</label>
        <input className='inpt mt-3 mb-3 w-2/3' type='number' placeholder={`${price}`} onInput={(e)=>{
            setprice(e.currentTarget.value+' GOLD')
        }}>
        </input>
  
       </div>
  
  
       <div className='flex-col mt-4'>
  
          <label className='text-yellow-600 '>Interested In</label>
  
          <div className='grid  grid-cols-3'>
  
                  {
                      ["MEN","WOMEN","COUPLE","TRANS"].map(value=>{
                      return  <div className='whitespace-nowrap'>
                              <label className='text-yellow-600 mr-1'>{value}</label>
                                  <input type='checkbox' value={`${value}`} className='mr-3 shrink' onClick={(e)=>{
                                  let input = e.currentTarget.value;
                                  let check = Interested.findIndex(value =>{
                                      return input === value
                                  })
                                  if(check !== -1){
                                      Interested.splice(check,1)
                                  }else{
                                      Interested.push(e.currentTarget.value)
                                  }
                                  
  
                                  }}></input>
                              </div>
                      })
                  }
  
  
          </div>
  
  
        </div>
  
        {/* <div className='flex-col'>
  
  <label className='text-yellow-600 mr-2'>Choose category</label>
  
  <select name="hosttype" value={hosttype} className='btn mb-3 flex-wrap mt-2 border-solid border border-1 p-1 rounded-lg border-yellow-600' onChange={(e)=>{
                  
                  sethosttype(e.currentTarget.value)
              }}>
              <option value={`Fan meet`}>Fan meet</option>
              <option value={`Fan date`}>Fan date</option>
              <option value={`Private show`}>Private show</option>
              </select>  
  
  
  </div> */}
  
        <div className='mt-2'>
          <textarea className='inpt h-24 placeholder:text-center' placeholder={`${discription}`} onInput={(e)=>{
          setdiscription(e.currentTarget.value)
          }}>
  
          </textarea>
        </div>
  
  
      
  
       </fieldset>
  
       <fieldset className='sm:w-1/2 sm:ml-10 mt-5 border p-5 rounded-2xl border-yellow-600 mb-10' disabled={disablebut}>
       
       <div className='p-3 flex flex-col'>
        <label className='text-yellow-600 text-center'> Update your photo?</label> 
        <p className='text-yellow-600 text-center text-sm font-semibold mt-2'>{imglist.length} Photo Selected</p>
        <div className='flex flex-wrap'>

           {
         
          imglist.map(value =>{

           
              return  <div className='flex-row bg-blue-500 md:w-fit mb-1 m-auto'>
              <button onClick={(e)=>{
               setimglist(index => index.filter(item => item !==value))
               setphotolink(index => index.filter(item => item !==value))
                
              }}>
                <img alt='deleteIcon' src={deleteIcon}>
                </img>
              </button>
            <img alt='modelIMG' src={value} className='object-cover rounded-md shrink h-52 w-full m-auto'>
               </img>
            </div>
          
            
          

          })
        }
        </div>
         
    
     <input type='file' className='btn w-1/2 mt-2'  accept='image/*' 
    
    onChange={(e)=>{
       
        
         if(e.target.files[0]){

          if(photolink.length < photocount){
            setimglist(value => [...imglist,URL.createObjectURL(e.target.files[0])])
            setphotolink(value => [...photolink,e.target.files[0]])
          //  imglist.push()
          }
             
            }

    }}
    >
    </input>
     
      </div>
  
        <hr className='bg-yellow-600 mb-3'></hr>
  
  
       <button className='btn w-full text-center block rounded-2xl h-7' disabled={disablebut}
        onClick={() =>{
  
          checkuserInput()
          }
        } 
       
        >
           Procced
        </button>
  
        <div className=' mt-3 flex justify-between overflow-hidden'>
        <PacmanLoader
          color={color}
          loading={loading}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
         
        />
  
     <PacmanLoader
          color={color}
          loading={loading}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
         
        />
         <PacmanLoader
          color={color}
          loading={loading}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        
        />
       </div>
        </fieldset>
  
      </div></div>
    )
}
