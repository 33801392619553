import React, { useState,useEffect } from 'react'
import successicon from '../icons/successicon.svg'
import {useNavigate } from 'react-router-dom'
import PacmanLoader from "react-spinners/RingLoader";
import { deposit, ProfilechangeStatus } from '../app/features/profile/profile';
import { useSelector, useDispatch } from 'react-redux';

export const Success = () => {

   const navigate = useNavigate()
   let amount = localStorage.getItem("amount")
   let token = localStorage.getItem("token")
   let userid = localStorage.getItem("userid")
   let [loading, setLoading] = useState(true);
   let [color, setColor] = useState("#FFFFFF");
   const deposit_stats =   useSelector(state=> state.profile.deposit_stats)
   const deposit_message =   useSelector(state=> state.profile.deposit_message)

   const dispatch = useDispatch()

   useEffect(()=>{
    pay_deposit()
   },[])

   useEffect(()=>{
    if(deposit_stats === "succeeded"){
      setLoading(false)
      ProfilechangeStatus("idle")
      localStorage.removeItem("token")
      localStorage.removeItem("userid")


    }

     if(deposit_stats === "failed"){
      
    }
   },[deposit_stats])

   const pay_deposit = ()=>{
   
    if(!token){
      navigate("/")
    }

    if(deposit_stats !== "loading"){
      dispatch(
        deposit({
          token,
          userid,
          amount
        })
      )
    }
   }

   const check_pay = ()=>{
    if(loading === false){
      return true
    }else{
      return false
    }
   }
  return (
     <div className='sm:flex  sm:justify-center sm:w-1/2 sm:ml-16 sm:rounded-xl sm:mb-0 mb-8 relative'>

       {
            loading &&
             <div className='flex w-full mt-16 flex-col items-center'>
                            <PacmanLoader
                            color={color}
                            loading={loading}
                            size={35}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                          <p className='text-sm text-white'>do not cancel...</p>
                          <p className='text-sm text-white'>wait a moment.....</p>
        </div>

        }

   {check_pay() && <div className='text-black mx-auto text-center bg-black my-auto
    border-0 flex flex-col mt-16
    '>
        <div className='flex justify-center'>
        <p className='text-green-500 text-xl font-bold text-center mt-2'>{amount} Gold Deposit Successful</p>

         <img
           alt='messageicon'
           src={successicon}
           className='w-10 h-10 object-contain ml-1'
        >
        </img>

        </div>

    

        <div className='mt-4 px-3'>

             
             <button className='btn w-full mt-10 h-8 mb-5'
             onClick={()=>{
              navigate("/")
             }}
             >
              Continue Home
             </button>

           

        </div>
    </div>}

    </div>
  )
}
