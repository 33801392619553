import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { MdAlternateEmail } from "react-icons/md";
import { MdLaptopChromebook } from "react-icons/md";
import { IoMdNotificationsOutline } from "react-icons/io";


export const NotificationPage = () => {
  const navigate = useNavigate();

  const navdata = [
    {
      name: "Push Notification",
      icon: <IoMdNotificationsOutline color="white" size={20} />,
      linktitle: "pushnotification",
    },

    {
      name: "Email Notification",
      icon: <MdAlternateEmail color="white" size={20} />,
      linktitle: "emailnotification",
    },
    
  ];
  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black text-white">
    
    <div className='w-full md:w-2/4 flex flex-col'>
    <header className="flex gap-4 items-center">
        <FaAngleLeft
          color="white"
          size={30}
          onClick={() => {
            navigate("/setting");
          }}
        />

        <h4 className="font-bold text-lg text-white">NOTIFICATION</h4>
      </header>

      <p className="text-sm text-slate-400 py-4">
        See informationa about your account, change Password or learn more about
        your account deactivation
      </p>
      <div className="pt-4">
        {navdata.map((nav, index) => (
          <div
            className="flex justify-between items-center mb-6 "
            key={index}
            onClick={() => navigate(`/${nav.linktitle}`)}
          >
            <div className="flex gap-2 items-center">
              <div>{nav.icon}</div>
              <h4 className="text-lg text-white font-semibold">{nav.name}</h4>
            </div>
            <div>
              <FaAngleRight color="white" />
            </div>
          </div>
        ))}
      </div>
    </div>
     
    </div>
  );
};
