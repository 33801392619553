const TextInput = ({ label, name, type = "text", value, onChange }) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium mb-2" htmlFor={name}>
        {label}:
      </label>
      {type === "textarea" ? (
        <textarea
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          required
          className="w-full p-3 border border-gray-300 rounded-md bg-black"
        />
      ) : (
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          required
          className="w-full p-3 border border-gray-300 rounded-md bg-black"
        />
      )}
    </div>
  );
};

export default TextInput;
