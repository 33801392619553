import { Client, Storage, ID, Role } from "appwrite";

const client = new Client()
  .setEndpoint("https://cloud.appwrite.io/v1") // Your API Endpoint
  .setProject("668f9f8c0011a761d118"); // Your project ID

// const client = new Client()
//     .setEndpoint("https://cloud.appwrite.io/v1")
//     .setProject("668f9f8c0011a761d118");

const storage = new Storage(client);

export const saveImage = async (image, bucket) => {
  console.log("inside save fun");

  // return promise
  const response = await storage.createFile(bucket, ID.unique(), image);
  console.log("after inside save fun");

  return response.$id;
};

export const downloadImage = (photoLink, bucket) => {
  const result = storage.getFileDownload(bucket, photoLink);

  return result;
};

export const deleteImage = async (photoLink, bucket) => {
  const result = await storage.deleteFile(bucket, photoLink);

  return result;
};

export const updateImage = async (photoLink, bucket, image) => {
  const response = await storage.createFile(bucket, photoLink, image);

  return response.$id;
};
