import React,{useState,useEffect} from 'react'
import person from "../../../icons/icons8-profile_Icon.png"
import PacmanLoader from "react-spinners/RotateLoader";
import { downloadImage } from '../../../api/sendImage';
import { deletecollection, ProfilechangeStatus, getcollection } from '../../../app/features/profile/profile';
import { useSelector, useDispatch } from 'react-redux';
import { Viewcontent } from '../../profileview/Viewcontent';
import { ToastContainer, toast } from "react-toastify";

export const Mycontentlist = ({exclusiveid,name,id,exclusivelink,contenttype}) => {
let timeout
const deletecolstats = useSelector((state) => state.profile.deletecolstats);
const userid = useSelector((state) => state.register.userID);
const token = useSelector((state) => state.register.refreshtoken);
const [loading, setloading] = useState(false);
let [color, setColor] = useState("#d49115");
let [buttonpressed, set_buttonpressed] = useState(false)
let [image,setimage] = useState(person)
let [checkIMG, setcheckIMG] = useState(true)
const dispatch = useDispatch()
const contenttoast = React.useRef(null);

useEffect(()=>{
  if(!contenttype){
    setcheckIMG(true)
  }else if(contenttype === "video"){
    setcheckIMG(false)
  }else if(contenttype === "image"){
    setcheckIMG(true)
  }
  if(exclusivelink){
   setimage(downloadImage(exclusivelink,"post"))
  }
},[])


useEffect(()=>{
  
  if(deletecolstats === "succeeded"){
    dispatch(ProfilechangeStatus("idle"))
    dispatch(getcollection({userid,token}))
    setloading(false)
    set_buttonpressed(false)
  }
},[deletecolstats])

let deletecontent = ()=>{
  if(deletecolstats !== "loading"){
    setloading(true)
    dispatch(deletecollection({token,id}))
  }
}



  return (
        <div className="relative my-1" 
        onMouseDown={(e)=>{
     
          timeout = window.setTimeout(()=>set_buttonpressed(true),1300)
        
      }}
      onTouchStart={(e)=>{
        
          timeout = window.setTimeout(()=>set_buttonpressed(true),1300)
        
      }}
  
      onMouseUp={(e)=>{
        clearTimeout(timeout)
      }}
  
      onTouchEnd={(e)=>{
        clearTimeout(timeout)
      }}
        >
          <ToastContainer position="top-center" theme="dark" />
             {loading && (
                <div className="w-full flex flex-col items-center">
                    <PacmanLoader
                    color={color}
                    loading={loading}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />

                    <p className="text-xs">Please wait...</p>
                </div>
                )}
           {checkIMG ?  (<img
                src={image}
                alt="exclusive_img"
                className=" h-36 w-full object-cover rounded-md "
                onClick={(e)=>{
                  contenttoast.current = toast(
                    <Viewcontent  photo={image} phototype={contenttype}/>,
                    {autoClose:false}
                  )
                }}
                />) :
                (<video
                  src={image}
                  alt="exclusive_img"
                  className=" h-36 w-full object-cover rounded-md "
                  autoplay
                  controls
                  />)
                }
            <p className="text-sm text-white pl-2">{name}</p>

           {buttonpressed &&( <div className='w-full flex flex-row items-center justify-center text-center'>
                <button onClick={(e)=>{deletecontent()}}
                className="bg-blue-600 p-2 mt-2 text-xs rounded-md shadow-red-200 shadow-2xl text-white font-bold">
                  Delete Content
                </button>

            </div>)}
        </div>
      );
}
