import React, { useEffect, useState } from "react";
import dodo from "../../../icons/icons8-profile_Icon.png";
import { downloadImage } from "../../../api/sendImage";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { removenotification } from "../../../app/features/message/messageSlice";
import onlineIcon from "../../../icons/onlineIcon.svg";
import offlineIcon from "../../../icons/offlineIcon.svg";
import { format, isToday } from "date-fns";

let taken = false;

export const UnreadList = ({
  photolink,
  username,
  content,
  count,
  toid,
  fromid,
  key,
  date,
  online,
}) => {
  const [userphoto, setuserphoto] = useState(dodo);
  const [modelid, setmodelid] = useState([]);
  const navigate = useNavigate();
  let myid = useSelector((state) => state.register.userID);
  
    let date1 = new Date(Number(date)).toString(); 
    const dates = isToday(date1) ? format(date1, "h:mm a") : format(date1, "MM/dd/yyyy");

  useEffect(() => {
    //  console.log("this is client "+client)
   

    setmodelid((value) => [...value, fromid, toid ]);
   
      if (photolink) {
        let photo1 = downloadImage(photolink, "profile");

        //console.log('Good Photo '+photo1)
        setuserphoto(photo1);
      }
    
    //console.log('modelid'+modelid)
  }, []);

  const sliceContent = () => {
    if (content) {
      return content.slice(0, 10) + `...`;
    }
  };

  return (
    <li className="mb-1 "
      onClick={(e) => {
        //console.log("inside UreadList "+modelid)
       // removenotification(date);
        navigate(`/message/${modelid.toString()}`);
      }}
      
    >
      <div className="flex justify-between items-center rounded-lg px-4 py-3 sm:px-2 mx-2  bg-slate-800">
        <div className="flex items-center gap-4 ">
          <div className=" relative w-12 h-12">
            <img
              src={userphoto}
              alt="message-image"
              className="w-full h-full object-cover rounded-full"
            />
            <div className="absolute top-6 left-6 m-1 w-6 h-6 z-10">
              <img
                alt={online ? "online" : "offline"}
                src={online ? onlineIcon : offlineIcon}
                className={`object-cover rounded-full w-5 h-5 ${
                  online ? "bg-[#d3f6e0]" : "bg-[#ffd8d9]"
                }`}
              />
            </div>
          </div>

          <div>
            <h4 className="text-slate-300 text-md sm:text-sm font-semibold">
              {username.split(" ")[0]}
            </h4>

            <p className="text-slate-400 text-sm sm:text-xs">
              {sliceContent()}
            </p>
          </div>
        </div>

        <div>
          <h4 className="text-slate-400 text-sm">{dates}</h4>
          <div className="justify-items-end pt-1">
            <h4 className="text-black bg-slate-300 rounded-full w-5  text-center h-5 text-sm">
              {count}
            </h4>
          </div>
        </div>
      </div>
    </li>
  );
};
