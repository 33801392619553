import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  unverifiedHost,
  changemodelstatus,
} from "../../app/features/model/modelSlice";
import { useNavigate } from "react-router-dom";
import PacmanLoader from "react-spinners/RingLoader";
import { Hostlist } from "../modelviews/confirmhostAdmin/Hostlist";
import dummy from "../../Images/dummy.jpg"


export const Verify_models = () => {
   const listofhost = useSelector((state) => state.model.Listofunverifiedhost);

  // Expected data

  // const listofhost = [
  //   {
  //     userid: "tywdcvwcwewhv",
  //     firstname: "Emma",
  //     lastname: "Ola",
  //     username: "@tender",
  //     email: "emmylove961@gmail.com",
  //     dob: "2/2/1990",
  //     city: "Lagos",
  //     country: "Nigeria",
  //     recident_address: "Lagos",
  //     document_type: "International Passport",
  //     postlinkid: dummy,
  //     userphotolink: dummy,
  //   },
  //   {
  //     userid: "tywdcvwcwewhv",
  //     firstname: "Emma",
  //     lastname: "Ola",
  //     username: "@tender",
  //     email: "emmylove961@gmail.com",
  //     dob: "2/2/1990",
  //     city: "Lagos",
  //     country: "Nigeria",
  //     recident_address: "Lagos",
  //     document_type: "International Passport",
  //     postlinkid: dummy,
  //     userphotolink: dummy,
  //   },
  // ]
  const unverifiedhoststatus = useSelector(
    (state) => state.model.unverifiedhoststatus
  );
  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const admin = useSelector((state) => state.profile.admin);
  const rejectmodelstatus = useSelector(
    (state) => state.model.rejectmodelstatus
  );
  const verifymodelstatus = useSelector(
    (state) => state.model.verifymodelstatus
  );
  const [disablehost, setdisable] = useState(false);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#d49115");
  const dispatch = useDispatch();

  useEffect(() => {
    //checkadmin();
    if (unverifiedhoststatus !== "loading") {
      //console.log("CALLING HOST")
      dispatch(
        unverifiedHost({
          userid,
          token,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (unverifiedhoststatus === "succeeded") {
      setLoading(false);
    }

    if (unverifiedhoststatus === "failed") {
      setLoading(false);
    }
  }, [unverifiedhoststatus]);

  useEffect(() => {
    if (rejectmodelstatus === "succeeded") {
      setLoading(false);
      setdisable(false);
      dispatch(changemodelstatus("idle"));
    }

    if (verifymodelstatus === "succeeded") {
      setLoading(false);
      setdisable(false);
      dispatch(changemodelstatus("idle"));
    }
  }, [rejectmodelstatus, verifymodelstatus]);

  const checkadmin = () => {
    if (admin === true) {
      return true;
    } else {
      window.location.href = "/";
    }
  };

  const checkhost = () => {
    if (loading === false) {
      if (listofhost.length > 0) {
        return (
          <ul className="w-full pl-2 pr-2">
            {listofhost.map((value,index) => {
              return (
                <li key={`${value.id}_${index}`}>
                  {
                    <Hostlist
                    prob={value}
                   
  
                  />
                  }
                </li>
              );
            })}
          </ul>
        );
      } else {
        return (
          <div className="w-full h-16 flex justify-center mt-16">
            <p className="text-white text-sm">
              No unverified host at the moment!!
            </p>
          </div>
        );
      }
    }
  };

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
      <div className="w-full  h-full flex flex-col items-center md:w-2/4 ">
        <p className="jost text-[1.3em] text-white py-4 font-[500] ">
          Verify new models
        </p>

        {loading && (
          <div className="flex flex-col items-center mt-16 w-full">
            <PacmanLoader
              color={color}
              loading={loading}
              size={70}
              aria-label="Loading Spinner"
              data-testid="loader"
              margin={"auto"}
            />

            <p className="jost text-white">Getting list of unverified host</p>
          </div>
        )}

        {checkhost()}
      </div>
    </div>
  );
};
