import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Privateshowwork = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">How does Private show work ?</h4>
            
          </header>
         
          <p>
             Once you accept a private show request, The fan will receive a notification to start the private show, 
             During the private show, You will be recieving your desired tip amount per minute as the private show 
             goes on.
          </p>
          </div>
      </div>
     
    </div>
  );
  
};