import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Whouse = () => {
   
  
    return (
      <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
        <Header />
        <div className="p-4">
          <Breadcrumbs title="About" />
          <div>
            <header className="my-4">
              <h4 className="font-bold  text-2xl">Who uses Mmeko ?</h4>
              
            </header>
            <p>
             Mmeko is home to a vast variety of Models and content creators. These include influencers, 
             NSFW creators, fitness models, musicians, and so many others.

             You are able to use Mmeko as a content creator or as someone who wants to access content and eventually 
             become a fan. Mmeko is a great platform for anyone that wants to share or view exclusive content made by 
             talented people.

             To use Mmeko you must first register and create a User account. You must provide a valid email address and 
             a password or authenticate using a Gmail account. Your password should be unique and must comply with the 
             technical requirements of the Mmeko site for the composition of passwords.
            </p>
            <p className="pt-2">
             To register as a User:
              <ul className="list-disc list-inside mt-2 text-gray-300">
                <li>you must be at least 18 years old, and you will be required to confirm this.</li>
                <li>if the laws of the country or State/province where you live provide that you can only be legally 
                    bound by a contract with us at an age which is higher than 18 years old, then you must be old enough to be 
                    legally bound by a contract with us under the laws of the country or State/province where you live</li>
                <li>you must be permitted by the laws of the country or State/province where you are located to join Mmeko and 
                    to view any Content available on it and to use any functionality provided by it</li>
              </ul>
            </p>

            </div>
        </div>
       
      </div>
    );
    
  };