import React,{useState} from 'react'
import cancelIcon from "../../icons/closeIcon.svg"

export const Viewcontent = ({photo,phototype}) => {
    
    const [image,setimage] = useState(photo)
    const showImage = ()=>{
        if(!phototype){
            return   <img
            src={image}
            alt='dispalycontent'
            className='object-cover w-full h-full'
            />
        }else if(phototype === "image"){
            return   <img
            src={image}
            alt='dispalycontent'
            className='object-cover w-full h-full'
            />
        }else if(phototype === "image"){
            return   <video
            src={image}
            alt='dispalycontent'
            className='object-cover w-full h-full'
            autoPlay
            controls
            />
        }
    }
  return (
    <div className='w-80 min-h-60 flex flex-col'>
        <div className='w-full flex flex-row justify-end bg-blue-500 shadow-xl shadow-slate-400'>
         
           
        </div>

        {showImage()}
        
    </div>
  )
}
