import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Howdoprivate = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Fans" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">How do Private show work ?</h4>
            
          </header>
         
         <p>
         When you request for a private show, You have to wait for the model to accept or decline your request, 
         Once your request is accepted, you will receive a notification that says Start Private show, During the 
         private show,  Your gold balance will be deducteing per minute as a result of required tip by the model.
         </p>

          </div>
      </div>
     
    </div>
  );
  
};