import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import VerificationImage from "../../icons/verificationImage2.jpeg";
import TextInput from "../../components/input/textinput";
import FileInput from "../../components/input/fileUpload";
import CheckboxWithLabel from "../../components/input/checkbox";
import PacmanLoader from "react-spinners/ClockLoader";
import Checklist from "./components/checklist";
import Ruleslist from "./components/ruleslist";
import { useSelector, useDispatch } from "react-redux";
import { changemodelstatus, post_exclusive_ids, post_exclusive_docs } from "../../app/features/model/modelSlice";
import { useNavigate } from "react-router-dom";


export const VerifiedUserForm = () => {
  const userid = useSelector((state) => state.register.userID);
  const exclusive_holdphoto = useSelector((state) => state.model.exclusive_holdphoto);
  const exclusive_idphoto = useSelector((state) => state.model.exclusive_idphoto);
  const exclusive_ids_stats = useSelector((state) => state.model.exclusive_ids_stats);
  const exclusive_docs_stats = useSelector((state) => state.model.exclusive_docs_stats);
  const token = useSelector((state) => state.register.refreshtoken);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#d49115");

  const [documentType, setDocumentType] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    country: "",
    city: "",
    address: "",
    idPhotofile: null,
    holdingIdPhotofile: null,
    idPhoto: "",
    holdingIdPhoto: "",
    acceptTerms: false,
    userid,
    documentType:"",
    idexpire:"",
    token
  });

  useEffect(()=>{
     if(!userid){
      window.location.href = "/";
     }
  },[])

  useEffect(()=>{
    if(exclusive_ids_stats === "succeeded"){
      dispatch(changemodelstatus("idle"))
      formData.holdingIdPhoto = exclusive_holdphoto
      formData.idPhoto = exclusive_idphoto
      formData.holdingIdPhotofile = ""
      formData.idPhotofile = ""

      if(exclusive_docs_stats !== "loading"){
        dispatch(post_exclusive_docs(formData))
      }
      
      // post details to server
    }

    if(exclusive_ids_stats === "failed"){
      dispatch(changemodelstatus("idle"))
      setLoading(false)
    }

    if(exclusive_docs_stats === "succeeded"){
      console.log("exclusive success")
      dispatch(changemodelstatus("idle"))
      navigate("/modelnotify");
      setLoading(false)

    }

    if(exclusive_docs_stats === "failed"){
      dispatch(changemodelstatus("idle"))
      setLoading(false)

    }

  },[exclusive_ids_stats, exclusive_docs_stats])

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({ ...prev, [name]: checked }));
    } else if (type === "file") {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDocumentTypeChange = (e) => {
    //navigate("/modelnotify");
    
    setDocumentType(e.currentTarget.value);
    formData.documentType = e.currentTarget.value
    console.log("doc type "+formData.documentType)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(exclusive_ids_stats !== "loading"){
      setLoading(true)
      dispatch(post_exclusive_ids({holdingIdPhotofile:formData.holdingIdPhotofile, idPhotofile:formData.idPhotofile}))
      
    }
    
  };

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black text-white">
  
       <div className='w-full md:w-2/4 flex flex-col mb-12 '>
      <div className="p-4 rounded-lg shadow-lg w-full max-w-lg">
        <h1 className="text-2xl font-semibold text-center mb-4">
          Verified User Application
        </h1>
        <p className="text-slate-400 mb-6">
          Please provide us with information for verification. Once verified,
          you'll be able to start a creator account.
        </p>
        {loading && (
          <div className="flex flex-col items-center mt-16 w-full z-10 relative top-3/4">
            <PacmanLoader
              color={color}
              loading={loading}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
              margin={"auto"}
            />

            <p className="jost text-yellow-500 font-bold
            
            ">submitting...</p>
          </div>
        )}

        <fieldset disabled={loading}>

           <form onSubmit={handleSubmit}>
          <h4 className="font-bold text-md mb-4">Personal Information</h4>
          <TextInput
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            
          />
          <TextInput
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            
          />
          <TextInput
            label="Email Address"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
          <TextInput
            label="Date of Birth"
            name="dob"
            type="date"
            value={formData.dob}
            onChange={handleChange}
          />
          <TextInput
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
          />
          <TextInput
            label="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
          <TextInput
            label="Residential Address"
            name="address"
            type="textarea"
            value={formData.address}
            onChange={handleChange}
          />

          <h4 className="font-bold text-md my-4">Document Upload</h4>
          <Ruleslist />
          <img
            src={VerificationImage}
            alt="Verification Example"
            className="w-full mb-4 h-80"
          />
          <div className="mb-4">
            <label
              htmlFor="documentType"
              className="block text-sm font-medium mb-2"
            >
              Document Type:
            </label>
            <select
              id="documentType"
              name="documentType"
              value={documentType}
             
              onChange={handleDocumentTypeChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none bg-black"
            >
              <option value="">Select Document Type</option>
              <option value="passport">Passport</option>
              <option value="driversLicense">Driver's License</option>
            </select>
          </div>

          <FileInput
            label="Photo of ID"
            name="idPhotofile"
            icon={<FaPlus />}
            onChange={handleChange}
          />
          <FileInput
            label="Photo of You Holding ID with Handwritten Note"
            name="holdingIdPhotofile"
            icon={<FaPlus />}
            onChange={handleChange}
          />

          <div className="flex gap-6 items-center my-4">
            <TextInput
              label="ID Expirational Date"
              name="idexpire"
              type="date"
              value={formData.idexpire}
              onChange={handleChange}
            />

          </div>

          <Checklist />

          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-600"
          >
            Submit Application
          </button>
        </form>

        </fieldset>
      </div>
      </div>
      
    </div>
  );
};
