import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Makeyourfirst = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">Steps to make your first $10k on Mmeko :</h4>
            
          </header>
         
          <p>
             At the end of the day, being successful on Mmeko is probably your goal. If you want to see success, you need 
             to work hard. 
             So we’ve put together this guide to help our models and creators reach their goals. 
            <ol className="list-decimal list-inside mt-2 text-gray-300">
             <li>
                <article>
                <h3 className="font-semibold">Setting up your profile</h3>
                <p className="text-sm">Before you can even think about making money on Mmeko, you need to ensure your profile is complete. 

                   Have a look at this article for more details on setting up your profile for success 🚀

                  But let’s dive into more depth, </p>

                <p className="text-sm">
                    <h6>Setting a bio</h6>

                   <p>
                    A good bio is a window for your potential fans to find out more about you and what they can expect if 
                    they tip! It should be brief and authentic. Try to use engaging language to get people intrigued & provide 
                    information about what type of content you do, information around messaging/speaking to you on there, and anything 
                    else you offer to your tippers!🔝
                   </p>

                   <p>
                     <h6>Your profile and banner image </h6>
                     Your profile picture and banner give people a visual preview of the type of content you make. 
                     Ensure you pick a high-quality photo that is in line with the content you post. It needs to encourage potential 
                     fans to go ahead and tip!
                   </p>
                    </p>
                </article>
            </li>
            <li>
                Create a new model and Finalize your account details, Choose category you wish to offer your fans, 
                Enter your desired tip amount and complete your profile.
            </li>

            <li> 
                <article>
                <h4>Uploading content and populating your page</h4>
                <p>When it comes to sharing content on your Mmeko, you want to only upload content that is exclusive to the platform, 
                  this is what makes it worth paying for.
                </p>
                <p>If you are still new to Mmeko, it is important to upload content in bulk before you start promoting your account. 
                    Potential tippers will be able to see how much content there is on your page, the more you have, the higher chances they 
                    will convert. We recommend having at least 20 pieces of content on your page to begin with. Once you’ve initially populated 
                    your page, continue to make at least one post a day. Consistency on Mmeko will keep your fans happy.
                </p>

                <p>
                  Along with posting Exclusive content to your Exclusive page for your fans to see and purchase, Exclusive content is going 
                  to skyrocket your earnings and give your fans something to be excited about. This content needs to be more exclusive than 
                  the content you post on your page. Ensure your Exclusive content is worth it, otherwise your purchasing fans might feel 
                  ripped off and won't purchase again.
                </p>

                <p>
                  It’s important however to get the right balance when posting Exclusive content. Ensuring you provide great content & value to 
                  purchasing fans is essential if you want them to keep on purchasing. 
                </p>
                </article>

            </li>

            <li>
              <article>
                <h6>Promoting your Profile</h6>
                <p>
                  <p>
                    While Mmeko does have discoverability, it's still essential you promote your profile to your audience. You need to do this, 
                    to bring fans in and generate revenue. You want to let people know, repeatedly about your exclusive content platform, and 
                    the most effective way to do this is to your existing social media following! 
                  </p>
                  <p>
                    Have a look at this article for more details on promoting your Mmeko!
                    When promoting your Mmeko, always keep in mind the below tips, 
                    <ul className="list-disc list-inside mt-2 text-gray-300">
                      <li>
                      Promote consistently: Promote your Mmeko on at least one platform every day, the more you promote, 
                      the higher the chances are of success. 
                      </li>

                      <li>
                      Give people FOMO: You want people to be intrigued, and have a fear of missing out on something if 
                      they don’t purchase, you need to sell it! Explain what content you do, and why anyone who’s not 
                      purchasing is missing out!
                      </li>

                      <li>
                      Ensure people can find your Mmeko link: Having the link in your bio, or at the top of a LinkTree is 
                      super important, people can’t purchase, if they can’t find the link!
                      </li>

                    </ul>
                  </p>
                </p>
              </article>
            </li>
            </ol>
            </p>
  
        
          </div>
      </div>
     
    </div>
  );
  
};