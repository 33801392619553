import React, {useState, useEffect} from 'react'
import PacmanLoader from "react-spinners/RotateLoader";
import person from "../../../icons/icons8-profile_Icon.png"
import onlineIcon from "../../../icons/onlineIcon.svg"
import offlineIcon from "../../../icons/offlineIcon.svg"
import { getCountryData } from '../../../api/getCountries';
import { useNavigate } from 'react-router-dom';
import { downloadImage } from '../../../api/sendImage';
import { remove_Crush, changemodelstatus } from '../../../app/features/model/modelSlice';
import { useSelector,useDispatch } from 'react-redux';
import { getcollection } from '../../../app/features/profile/profile';

export const Crushlist = ({photolink,name,id,userid,hosttype,modelid,location,online}) => {

let timeout
const [loading, setloading] = useState(false);
const remove_crush_stats = useSelector((state) => state.model.remove_crush_stats);
let [color, setColor] = useState("#d49115");
let [image, setimage] = useState(person);
let [buttonpressed, set_buttonpressed] = useState(false)
const [modelID, setmodelID] = useState([modelid, userid]);
const navigate = useNavigate()
const myuserid = useSelector((state) => state.register.userID);
const token = useSelector((state) => state.register.refreshtoken);
const dispatch = useDispatch()


const [countryData, setCountryData] = useState({
  flag: "",
  abbreviation: "",
  fifa: "",
});

useEffect(() => {
  const fetchData = async () => {
    const data = await getCountryData(location);
    if (data) setCountryData(data);
  };
  fetchData();
}, []);

useEffect(()=>{
  if(photolink){
    setimage(downloadImage(photolink,"model"))
  }
},[])

useEffect(()=>{
  if(remove_crush_stats === "succeeded"){
    dispatch(changemodelstatus("idle"))
    dispatch(getcollection({userid,token}))
    setloading(false)
    set_buttonpressed(false)
  }
},[remove_crush_stats])

const removeCrush = ()=>{
  if(remove_crush_stats !== "loading"){
    dispatch(remove_Crush({ userid:myuserid, token, modelid}));
  }
}


  return (
    <div className="bg-slate-300 p-1 w-full rounded-lg"
    onMouseDown={(e)=>{
     
        timeout = window.setTimeout(()=>set_buttonpressed(true),1300)
      
    }}
    onTouchStart={(e)=>{
      
        timeout = window.setTimeout(()=>set_buttonpressed(true),1300)
      
    }}

    onMouseUp={(e)=>{
      clearTimeout(timeout)
    }}

    onTouchEnd={(e)=>{
      clearTimeout(timeout)
    }}
    >
      {buttonpressed && <button className='w-full text-center bg-slate-800' onClick={(e)=>removeCrush()}>
        <p className='text-white font-bold'>Remove Crush</p>
      </button>}

    {loading && (
      <div className="w-full flex flex-col items-center">
        <PacmanLoader
          color={color}
          loading={loading}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />

        <p className="text-xs">Please wait...</p>
      </div>
    )}

    

    <div className="" onClick={(e)=>{
     
      navigate(`/modelbyid/${modelID.toString()}`);
    }}>
    <div className="relative">
        <div>
          <img
            alt="verified"
            src={image}
            className="rounded h-80 w-full object-cover"
          />
        </div>

        <div className="absolute top-0 m-1 w-6 h-6 ">
          <img
            alt={online ? "online" : "offline"}
            src={online ? onlineIcon : offlineIcon}
            className={`object-cover rounded-full w-5 h-5 ${
              online ? "bg-[#d3f6e0]" : "bg-[#ffd8d9]"
            }`}
          />
        </div>

        <div className="absolute bottom-1">
          <div className="flex flex-row gap-2 items-center px-1  ">
            <div className="flex items-center p-1 gap-1 bg-black bg-opacity-40 rounded-lg ">
              <img
                src={countryData.flag}
                alt={`${countryData.abbreviation} flag`}
                className="w-3 h-3 object-cover rounded-full"
              />
              <span className="text-white text-xs ">{countryData.fifa}</span>
            </div>
            <h4 className="text-xs bg-black bg-opacity-40 rounded-lg p-1">
              {hosttype}
            </h4>
            <h4 className="text-xs bg-black bg-opacity-50 rounded-lg p-1">
              {name.slice(0, 4)}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
