import React, { useEffect, useState } from 'react'
import person from "../../icons/icons8-profile_Icon.png"
import { useNavigate } from 'react-router-dom'
import { downloadImage } from '../../api/sendImage'

export const UserList = ({photolink,name,country ,gender, age, userid, nickname}) => {

    let [userpics, setuserpics] = useState(person)
    let navigate = useNavigate()
    
    useEffect(()=>{
        if(photolink){
          setuserpics(downloadImage(photolink,"profile"))
        }
    },[])
  return (
    <li className='flex flex-row border border-blue-400 p-1 rounded-md mb-3' onClick={(e)=>{
        navigate(`/profile/${userid}`);
    }}>
        <div className='w-10 h-10 rounded-full bg-blue-600'>
         <img alt='proimage' src={userpics} className='w-10 h-10 rounded-full object-cover'></img>
        </div>
        <div className='flex flex-col ml-2'>
            <div className='flex flex-col mb-1'>
            <p className='text-slate-100 font-bold text-start'>{name}</p>
            <p className='text-slate-300 text-xs text-start'>{nickname}</p>

            </div>
           
            <p className='text-slate-300 font-semibold text-start'>{country}</p>
            <p className='text-slate-300 font-semibold text-start'>{gender}</p>
            <p className='text-slate-300 font-semibold text-start'>{age} years</p>

        </div>
        
    </li>
  )
}
