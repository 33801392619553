import React from 'react'

export const UserMessage = () => {
  return (
    <div className='sm:flex  sm:justify-center sm:w-1/2 sm:ml-16 sm:rounded-xl sm:mb-0 mb-8 relative'>
       <div className='w-full  h-full flex flex-col items-center '>
         <p className='text-yellow-500 font-bold border border-b-2 border-t-0 border-r-0 border-l-0 border-yellow-500'>Important Messages</p>
         <div className="w-full pl-3 pr-3">
            <ul className='mt-2'>
              
            </ul>
         </div>  
        </div>  
     </div>       
  )
}
