import React from 'react'

export const Viewdetails = ({setdetailclick, time, date, venue, name, type}) => {
  return (
    <div className='bg-slate-300 rounded-lg shadow-black mt-2 shadow-2xl'>
        <p className='text-center underline-offset-8 underline text-xs'>{`${name}`} {`${type}`} Details</p>
        <div className='flex flex-col'>
            <p>TIME: {`${time}`}</p>
             <p>DATE: {`${date}`}</p>
              <p>VENUE: {`${venue}`}</p>


        </div>
        <div className='w-full flex justify-center'>
           <button className='p-2 text-xs bg-green-500 mb-1 rounded-xl' onClick={(e)=>setdetailclick(false)}>Close details</button>
        </div>
       
    </div>
  )
}
