import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Becomeamodel = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">Becoming a model</h4>
            
          </header>
          <p>
            <span className="font-semibold text-green-500 underline">So you’ve decided to become a Mmeko model?</span> We are excited to have you on board! Luckily creating model 
            account is simple and only requires 10 minutes of your time, your ID, and a device with a camera.
            Before we get started, did you know you can be completely anonymous on Mmeko? We understand the importance 
            of privacy, especially if you're creating specific content like adult content. So feel free to use a stage 
            name and email address with no association with your real persona. However, it is important to keep in mind 
            that to earn money, you'll need to verify your real identity. Don't worry, this information is GDPR-protected and won't be shared with anyone. 
          </p>
       
        
          </div>
      </div>
     
    </div>
  );
  
};