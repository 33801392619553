import React from "react";
import { Gennavigation } from "./Gennav";
import { useEffect } from "react";

export const Mainheaderbar = ({
  modelMenu,
  isOpen,
  handleToggleTab,
  login,
  handleGenderSearchQuery,
  click,
  setclick
  
}) => {


  return (
    <div className="sm:w-full bg-black border-b border-slate-600 sticky top-0 z-50 mb-5 ">
      <Gennavigation
        isOpen={isOpen}
        modelMenu={modelMenu}
        handleGenderSearchQuery={handleGenderSearchQuery}
        stoptop={true}
        click={click}
        setclick={setclick}
      />
      <div className="flex justify-between mx-10 md:mr-[7rem] lg:mr-[7rem] mt-2">
        <button
          className={`border-b-4 ml-10 ${
            !isOpen ? "border-orange-500" : "border-transparent"
          }`}
          onClick={handleToggleTab}
        >
          <p className="text-slate-400 sm:text-sm lg:text-xl">Hot</p>
        </button>

        <button
          className={`border-b-4 mr-10 ${
            isOpen ? "border-orange-500" : "border-transparent"
          }`}
          onClick={handleToggleTab}
        >
          <p className="text-slate-400 sm:text-sm lg:text-xl">Models</p>
        </button>
      </div>
    </div>
  );
};
