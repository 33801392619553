const APIKEY = "pk.b128a250e5132338f808650c268095cd"

export const fetchLocation = async(lat,long)=>{

    let response;


    let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)"
      }


      try {
        response = await fetch(`https://us1.locationiq.com/v1/reverse?key=pk.b128a250e5132338f808650c268095cd&lat={${lat}&lon=${long}&format=json`)
        ////console.log(await response.json())

      } catch (error) {
        return {
            err:"failed to fetch your location"
        }
      }


      
      // Uses the 'optional chaining' operator
      if (response?.ok) {

        //let data = await response.json()
        //let datas = data.data.results[0];
        

        let data = await response.json()
        console.log(data.address.country)
        return{
            err:"",
            state: data.address.state,
            country: data.address.country

        }
      } else {
        return{
            err:`${  response?.status}`
        }
      
      }



}