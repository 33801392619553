import React,{useEffect,useState} from 'react'
import cancelbutton from "../../icons/closeIcon.svg"
import PacmanLoader from "react-spinners/RingLoader";
import { getall_request } from '../../app/features/booking/booking';
import { useSelector, useDispatch } from 'react-redux';
import {delete_MSG,changemodelstatus} from "../../app/features/model/modelSlice"
import {format} from "date-fns"

export const List_of_message = ({id, message, time, Mymodel}) => {
   
  const delete_msg_stats = useSelector(state => state.model.delete_msg_stats)
  const dispatch = useDispatch()
  const token = useSelector(state => state.register.refreshtoken)
  const userid = useSelector((state) => state.register.userID);
  const [loading, setloading] = useState(false);
  let [color, setColor] = useState("#c2d0e1");
  let [lastID, set_lastID] = useState("")

   let date1 = new Date(parseInt(time))
    //console.log('post date '+ date)
    const date =   format(date1, "MM/dd/yyyy 'at' h:mm a");

    useEffect(()=>{
     if(delete_msg_stats === "suceeded"){
      dispatch(getall_request({ userid, token, modelid: Mymodel }));
      dispatch(changemodelstatus("idle"))
      setloading(false)

      if(delete_msg_stats ==="failed"){
        setloading(false)
        dispatch(changemodelstatus("idle"))
      }

     }
    },[delete_msg_stats])


   const deletebutton = ()=>{
      if(delete_msg_stats !== "loading"){
        setloading(true)
        dispatch(
        delete_MSG({token,id})
        )
      }
   }

  return (
    <li className='w-full bg-slate-500 rounded-md mb-2 '>
      <div className='flex justify-between'>
         <p className='text-xs  font-bold text-green-500 ml-1'>*Mmeko</p>
         <button onClick={(e)=>deletebutton()}><img src={cancelbutton} alt='closeIcon'></img></button>

      </div>
    <p className='pl-1'>{message}</p>
    <p className='text-white text-xs font-bold mt-1 pr-1'>{date}</p>

     {loading && (
          <div className="w-full h-full flex flex-col justify-center items-center">
            <PacmanLoader
              color={color}
              loading={loading}
              size={35}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <p className="text-slate-400 text-xs">please wait...</p>
          </div>
      )}

    </li>
  )
}
