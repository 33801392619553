import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { LiaUserSlashSolid } from "react-icons/lia";


export const PrivacySafetyPage = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black text-white">
    
    <div className='w-full md:w-2/4 flex flex-col'>
    <header className="flex gap-4 items-center">
        <FaAngleLeft
          color="white"
          size={30}
          onClick={() => {
            navigate(-1);
          }}
        />

        <h4 className="font-bold text-lg text-white">PRIVACY & SAFETY</h4>
      </header>

      <p className="text-sm text-slate-400 py-4">
        See informationa about your account, change Password or learn more about
        your account deactivation
      </p>
      <div className="pt-4" onClick={(e)=>navigate("/blockedusers")}>
        <div className="flex justify-between items-center mb-6 ">
          <div className="flex gap-2 items-center">
            <LiaUserSlashSolid color="white" size={30} />
            <h4 className="text-lg text-white font-semibold">Blocked users</h4>
          </div>
          <div>
            <FaAngleRight color="white" />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
