import React from "react";
import setting from "../../icons/settings.png";
import { NavButton } from "./components/navbutton";
import { Header } from "./components/header";
import settingIcon from "../../../src/icons/addemojis.js.svg";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { MdLockOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";


export const AccountPage = () => {
  const navigate = useNavigate();

  const navdata = [
    {
      name: "Password & security setting",
      icon: <MdLockOutline color="white" size={20} />,
      linktitle: "changepassword",
    },
    {
      name: "Delete my account",
      icon: <RiDeleteBinLine color="white" size={20} />,
      linktitle: "deleteaccount",
    },
  ];
  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
      
      <div className='w-full md:w-2/4 flex flex-col'>
      <header className="flex gap-4 items-center">
        <FaAngleLeft
          color="white"
          size={30}
          onClick={() => {
            navigate(-1);
          }}
        />

        <h4 className="font-bold text-lg text-white">ACCOUNT INFO</h4>
      </header>

      <p className="text-sm text-slate-400 py-4">
        See informationa about your account, change Password or learn more about
        your account deactivation
      </p>
      <div className="pt-4">
        {navdata.map((nav, index) => (
          <div
            className="flex justify-between items-center mb-6 "
            key={index}
            onClick={() => navigate(`/${nav.linktitle}`)}
          >
            <div className="flex gap-2 items-center">
              <div>{nav.icon}</div>
              <h4 className="text-lg text-white font-semibold">{nav.name}</h4>
            </div>
            <div>
              <FaAngleRight color="white" />
            </div>
          </div>
        ))}
      </div>
      </div>
     
    </div>
  );
};
