import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Datemeetandfanwork = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Fans" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">How do Fan meet or Fan date work ?</h4>
            
          </header>
         
         <p>
         Once a model accepts your Fan meet or Fan date request, The desired tip amount by the model will be deducted 
         from your gold balance to pending, and when the Fan meet or Fan date is completed, You will see a button on 
         your notification that says "Mark as completed" and once you mark it as completed, the model will receive 
         the tip.
         </p>

         <h6 className="font-semibold mt-2">What happens when a model cancel a Fan meet or Fan date request that has already been accepted?</h6>
         <p className="text-sm">You will automatically receive refund.</p>

          </div>
      </div>
     
    </div>
  );
  
};