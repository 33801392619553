import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { CiWarning } from "react-icons/ci";
import { deleteprofile, ProfilechangeStatus } from "../../app/features/profile/profile";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import PacmanLoader from "react-spinners/RotateLoader";


export const DeleteaccountPage = () => {

const userid = useSelector((state) => state.register.userID);
const token = useSelector((state) => state.register.refreshtoken);
const deleteaccstats = useSelector((state) => state.profile.deleteaccstats);
const testmsg = useSelector((state) => state.profile.testmsg);
const [loading, setloading] = useState(false);
let [color, setColor] = useState("#d49115");
let [buttonstop, set_buttonstop] = useState(false)
const navigate = useNavigate();
const dispatch = useDispatch()

const deleteClick = ()=>{

  if(deleteaccstats !== "loading"){
    setloading(true)
    set_buttonstop(true)
    dispatch(deleteprofile({userid,token}))
  }
  
}

useEffect(()=>{

  if(deleteaccstats === "succeeded"){
    localStorage.removeItem('login')
    dispatch(ProfilechangeStatus("idle"))
    navigate("/")
  }

  if(deleteaccstats === "failed"){
    toast.error(`${testmsg}`)
    dispatch(ProfilechangeStatus("idle"))
    
  }

},[deleteaccstats])

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black text-white">
  
    <div className='w-full md:w-2/4 flex flex-col'>
    <div className="bg-black min-h-screen flex flex-col  p-4 text-white">
        {/* Header */}
        <header className="flex gap-4 items-center">
          <FaAngleLeft
            color="white"
            size={30}
            onClick={() => {
              navigate(-1);
            }}
          />

          <h4 className="font-bold text-lg text-white">DELETE MY ACCOUNT</h4>
        </header>

        <div className="mt-5">
        <ToastContainer position="top-center" theme="dark" />
        {loading && (
                <div className="w-full flex flex-col items-center">
                    <PacmanLoader
                    color={color}
                    loading={loading}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />

                    <p className="text-xs">Deleting Please wait...</p>
                </div>
                )}
          <h4 className="mb-4">
            <span className="font-bold text-md ">PERMANENTILY DELETE</span> your
            account with all the data? All the data associated with your mmeko
            account will be permanently deleted
          </h4>
          <div className="w-full px-4 py-2 bg-red-600 bg-opacity-25 text-white border border-gray-600 rounded-md opacity-75 opacity-red-300">
            <div className="flex gap-4 w-full items-center">
              <CiWarning />
              <h4>Warning! This cannnot be undone </h4>
            </div>
          </div>
          <button className="mt-6 w-full max-w-md px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-gray-500" onClick={deleteClick} disabled={buttonstop}>
            Delete Account
          </button>
        </div>
      </div></div>  
    </div>
  );
};
