import { FollowerCard } from "./components/followerCard";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import Tabs from "../../components/tab";
import SearchBar from "./components/searchbar";
import React, { useState , useEffect} from "react";
import PacmanLoader from "react-spinners/SquareLoader";
import { useSelector, useDispatch } from "react-redux";
import { getfollow, ProfilechangeStatus } from "../../app/features/profile/profile";
import { Gennavigation } from "../../navs/Gennav";


const users = [
  {
    name: "Gideon Adeojo",
    image: "../../../icons/model.jpeg",
    following: true,
  },
  {
    name: "Habeeb Alabi Hba",
    image: "./icons/model.jpeg",
    following: false,
  },
];

export const FollowerPage = () => {
  const navigate = useNavigate();
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#d49115");
  const login = useSelector((state) => state.register.logedin);
  const firstname = useSelector((state) => state.profile.firstname);
  const lastname = useSelector((state) => state.profile.lastname);
  const getfollow_stats = useSelector((state) => state.profile.getfollow_stats);
  const fllowmsg = useSelector((state) => state.profile.fllowmsg);
  const getfollow_data = useSelector((state) => state.profile.getfollow_data);
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch()
  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);

  useEffect(()=>{

    if (!login) {
        window.location.href = "/";
      }

    if(getfollow_stats !== "loading"){
     dispatch(getfollow({userid,token}))
    }

  },[])

  useEffect(()=>{
    if(getfollow_stats === "succeeded"){
     // console.log("success follow")
      setLoading(false)
      dispatch(ProfilechangeStatus("idle"))
    }

    if(getfollow_stats === "failed"){
     // console.log("failed follow "+fllowmsg)
      //setLoading(false)
      dispatch(ProfilechangeStatus("idle"))
    }

  },[getfollow_stats])

  const followers = () => {

    if(loading === false){

      return (
        <div className="">
          <h1 className="text-lg font-bold  text-white px-2">{getfollow_data.followers.length} Fans</h1>
          {getfollow_data.followers.map((user, index) => (
            <FollowerCard key={'${index}_${user.id}'} setLoading={setLoading} image={user.image} name={user.name} id={user.id} canmessage={user.canmessage} modelid={user.modelid} following={user.following} />
          ))}
        </div>
      );

    }
   
  };

  const following = () => {

    if(loading === false){

      return (
        <div className="">
         <h1 className="text-lg font-bold  text-white px-2">{getfollow_data.following.length} Following</h1>
          {getfollow_data.following.map((user, index) => (
            <FollowerCard key={'${index}_${user.id}'} image={user.image} setLoading={setLoading} name={user.name} id={user.id} canmessage={user.canmessage} modelid={user.modelid} following={user.following} />
          ))}
        </div>
      );

    }
   
  };

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
          {/* <div className="chat_nav"><Gennavigation/></div> */}
    
      <div className='w-full  mx-auto md:w-3/5 text-white my-6 p-4 md:mr-auto md:ml-0'>
      <div className="flex justify-between py-4 items-center bg-black px-2 top-0 sticky z-10">
        <FaAngleLeft
          color="white"
          size={30}
          onClick={() => {
            navigate(-1);
          }}
        />
        <h4 className="text-white font-bold">{`${firstname} ${lastname}`}</h4>
        <FiSearch
          color="white"
          size={30}
          onClick={() => {
            setToggle(!toggle);
          }}
        />
      </div>

      <div className="mr-2">{toggle && <SearchBar />}</div>

      <div>
        {loading && (
              <div className="flex flex-col items-center mt-1">
                <PacmanLoader
                  color={color}
                  loading={loading}
                  size={35}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <p className="text-center text-slate-400 text-xs">
                  getting data...
                </p>
              </div>
            )}
        <Tabs
          tabs={[
            { label: "Following", content: following() },
            { label: "Fans", content: followers() },
          ]}
        />
      </div>
      </div>
    </div>
  );
};
