import React, { useState, useEffect } from "react";
import person from "../../icons/icons8-profile_Icon.png";
import postimageIcon from "../../icons/postimageicon.svg";
import vidoeicon from "../../icons/videoIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { createpost, PostchangeStatus } from "../../app/features/post/post";
import { ToastContainer, toast } from "react-toastify";
import { PostImage } from "./postImage";
import { Postvideo } from "./Postvideo";

export const Mainpost = () => {
  const dispatch = useDispatch();
  const photo = useSelector((state) => state.comprofile.profilephoto);
  const token = useSelector((state) => state.register.refreshtoken);
  const poststatus = useSelector((state) => state.post.poststatus);
  const posterror = useSelector((state) => state.post.error);
  const userid = useSelector((state) => state.register.userID);

  const [propics, setpropics] = useState(person);

  const [postcontent, setpostcontent] = useState("");

  useEffect(() => {
    if (photo) {
      setpropics(photo);
    }
  });

  useEffect(() => {
    if (poststatus === "succeeded") {
      toast.dismiss();
      dispatch(PostchangeStatus("idle"));
      
    }
    if (poststatus === "failed") {
      toast.dismiss();
      dispatch(PostchangeStatus("idle"));
      
    }
  }, [poststatus]);

  const mypost = () => {
    if (!postcontent) {
      toast.error("you have not said anything", { autoClose: 2000 });
      return;
    }
    if (poststatus === "idle") {
      toast.info("please wait...", { autoClose: false });
      dispatch(
        createpost({
          userid,
          postlink: "",
          content: postcontent,
          token,
          posttype: "text",
        })
      );
      setpostcontent("");
    }
  };

  return (
    <div className="bg-black ml-2 border border-slate-600 p-2 border-t-0 border-l-0 border-r-0 overflow-auto">
      <ToastContainer position="top-center" theme="dark" />
      <fieldset className="">
        <div className="flex">
          <div className="bg-slate-400 w-fit h-fit rounded-full">
            <img
              alt="user post image"
              src={propics}
              className="rounded-full  h-10 w-10 object-cover"
            ></img>
          </div>

          <textarea
            className="textinpt placeholder:font-thin placeholder:pt-2 w-3/4
        placeholder:text-balance placeholder:text-sm"
            placeholder="What's hot?!"
            value={postcontent}
            onInput={(e) => {
              setpostcontent(e.currentTarget.value);
            }}
          ></textarea>
        </div>

        <div className="flex mt-1 place-content-between sm:w-5/6">
          <div className="flex">
            <button
              onClick={(e) => {
                toast(<PostImage contents={postcontent} />, {
                  autoClose: false,
                });
              }}
            >
              <img
                alt="post image"
                src={postimageIcon}
                className="w-6 h-6 object-cover "
              ></img>
            </button>

            <button
              onClick={() => {
                toast(<Postvideo contents={postcontent} />, {
                  autoClose: false,
                });
              }}
            >
              <img
                alt="post image"
                src={vidoeicon}
                className="w-6 h-6 object-cover ml-2"
              ></img>
            </button>
          </div>

          <button
            className="btn rounded-2xl "
            onClick={(e) => {
              mypost();
            }}
          >
            Post
          </button>
        </div>
      </fieldset>
    </div>
  );
};
