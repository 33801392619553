import {urlBase64ToUnit8Array} from "./touintArray"

class WebPushService{
    static hasPermission(){
        return Notification.permission === "granted"
    }

    static async requestPermission(){
        return await Notification.requestPermission()
    }

    static async getSubscription(){
        return await navigator.serviceWorker.ready.then(async (registration)=>{
            return  await registration.pushManager?.getSubscription()
        })
    }

    static async subscribe(){
        const registration = await navigator.serviceWorker.ready;
        const subcription = await registration.pushManager.subscribe({
            userVisibleOnly:true,
            applicationServerKey:urlBase64ToUnit8Array("BFOop0dhgbA4z797vPVoKUvMf_aTocG5baoucv2r14ZOv2xXwIc3QYPWcRtxHUPBIm9wiHUjlLM30wTVVLi_GDk")
        });

        return subcription
    }

    static async unsubscribe(){
        const subcription = await this.getSubscription()

        if(subcription){
            await subcription.unsubscribe()
        }
    }
}

export default WebPushService
