import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Refound = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Fans" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">Can I get a refund ?</h4>
            
          </header>
         
         <p>
            Payments on Mmeko are non-refundable
         </p>

        
         <p className="text-sm mt-1">
           Any attempts to reclaim funds through other means will lead to account blocking and email banning from Mmeko.com
         </p>

         <p className="text-sm mt-1">
         If you attempt to issue a chargeback through your bank, we will no longer allow you on the platform.
         </p>

          </div>
      </div>
     
    </div>
  );
  
};