import React, { useState, useEffect } from "react";
import { Requestlist } from "./modelnotifylist/Requestlist";
import PacmanLoader from "react-spinners/RingLoader";
import { getmyrequest, resetstat } from "../../../app/features/booking/booking";
import { useSelector, useDispatch } from "react-redux";

export const Requestview = () => {
  const [loading, setloading] = useState(false);
  const [success, setsuccess] = useState(false);
  let [color, setColor] = useState("#c2d0e1");
  const [message, setmessage] = useState("");

  const Request = useSelector((state) => state.booking.requests);
  const requestmessage = useSelector((state) => state.booking.requestmessage);
  const requeststats = useSelector((state) => state.booking.requeststats);
  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const dispatch = useDispatch();

  useEffect(() => {
    getrequest();
  }, []);

  useEffect(() => {
    if (requeststats === "succeeded") {
      setloading(false);
      setsuccess(true);
    }

    if (requeststats === "failed") {
      setloading(false);
    }
  }, [requeststats]);

  const getrequest = () => {
    if (requeststats !== "loading") {
      setloading(true);
      dispatch(getmyrequest({ userid, token }));
    }
  };

  const Showrequest = () => {
    if (Request.length > 0) {
      return Request.map((value) => {
        return (
          <ul className="flex flex-col items-center pl-2 pr-2">
            <Requestlist
              photolink={value.photolink}
              modelname={value.name}
              modeltype={value.type}
              date={value.date}
              time={value.time}
              modelid={value.modelid}
            />
          </ul>
        );
      });
    } else {
      return (
        <p className="text-slate-400 text-center mt-16 text-xs">
          !You currently don`t have any awaiting request
        </p>
      );
    }
  };

  const showloader = () => {
    return (
      <div className="w-full flex flex-col items-center mt-16">
        <PacmanLoader
          color={color}
          loading={loading}
          size={35}
          aria-label="Loading Spinner"
          data-testid="loader"
        />

        <p className="text-slate-400 text-xs">please wait...</p>
      </div>
    );
  };

  return (
    <div>
      {loading && showloader()}

      {success && <div>{Showrequest()}</div>}
    </div>
  );
};
