import React from "react";
import hamburger from "../icons/hamburger.png";
import "../navs/Navs.css";
import person from "../icons/person.svg";

export const Profilenav = ({
  isOpen,
  handleMenubar,
  modelMenu,
  handleModelToggle,
}) => {
  return (
    <header className="mt-2 w-7 h-7 flex flex-row md:hidden">
      {!modelMenu ? (
        <img alt="hamburger" src={hamburger} onClick={handleMenubar} />
      ) : (
        <img alt="hamburger" src={hamburger} onClick={handleModelToggle} />
      )}

      <div className="flex md:hidden">
        <img alt="menuicon" src={person}></img>
      </div>
    </header>
  );
};
