import React, { useEffect, useState } from 'react'
import person from '../../../icons/icons8-profile_Icon.png'
import {format} from 'date-fns'
import { downloadImage } from '../../../api/sendImage'
import editcommenticon from "../../../icons/editcommenticon.svg"
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import deleteicon from '../../../icons/deleteicon.svg'
import editicon from '../../../icons/edit.svg'
import { deletecomment, resetcomment } from '../../../app/features/comment/comment'
import { getpostbyid ,PostchangeStatus } from '../../../app/features/post/post'
import { getpostcomment } from '../../../app/features/comment/comment'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const Commentlist = ({
  commentuserphoto,
  commentusername,
  content,
  commenttime,
  key,
  commentid,
  commentuserid,
  setcommentcontent,
  setbuttontext,
  setbuttonpress,
  setcommentsids,
  postid,
  nickname
}) => {
  const toastId = React.useRef(null);
  const getpostbyidstatus = useSelector(
    (state) => state.post.getpostbyidstatus
  );

  const alert = (message, type, close) => {
    toastId.current = toast(`${message}`, {
      type: `${type}`,
      autoClose: close,
    });
  };

  const dismissalert = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
    }
  };

  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const deletecommentstatus = useSelector(
    (state) => state.comment.deletecommentstatus
  );
  const error = useSelector((state) => state.comment.error);

  const dispatch = useDispatch();

  const [options, setoption] = useState(false);

  useEffect(() => {
    if (deletecommentstatus === "succeeded") {

      dispatch(getpostbyid({ postid, token }));
       dispatch(getpostcomment({ postid, token }));
      dismissalert();
      toast.dismiss();

      dispatch(resetcomment("idle"));
    }
    if (deletecommentstatus === "failed") {
      dispatch(getpostbyid({ postid, token }));
      dismissalert();
      toast.dismiss();
      dispatch(resetcomment("idle"));
    }

    if (getpostbyidstatus === "succeeded") {
      dispatch(PostchangeStatus("idle"));
    }

    if (getpostbyidstatus === "failed") {
      dispatch(PostchangeStatus("idle"));
    }
  }, [deletecommentstatus, getpostbyidstatus]);

  const showedit = () => {
    if (userid === commentuserid) {
      return true;
    } else {
      return false;
    }
  };

  const editcomment = () => {
    setcommentcontent(content);
    setbuttontext("replace comment");
    setbuttonpress(true);
    setcommentsids(commentid);
  };

  const deletemycomment = () => {
    if (deletecommentstatus === "idle") {
      alert("deleting comment please wait...", "loading", false);

      dispatch(deletecomment({ token, commentid }));
    }
  };

  let date1 = new Date(Number(commenttime)).toString();
  //console.log('post date '+ date)
  const date = format(date1, "MM/dd/yyyy 'at' h:mm a");

  const [userphoto, setuserphoto] = useState(person);

   const getphotos = async()=>{
    if(commentuserphoto){
            setuserphoto(downloadImage(commentuserphoto,'profile'))
    }
    

   }

   useEffect(()=>{
    getphotos()
   },[])




  return (
    <li key={key}>
      <SkeletonTheme>
      {getpostbyidstatus === "loading" ? (
      
        <div className="space-y-4">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="flex space-x-3 p-3 bg-gray-800 rounded-lg">
           
              <Skeleton circle height={40} width={40} />
      
              <div className="flex flex-col space-y-2 w-full">
               
                <Skeleton width={120} height={15} className="rounded-md" />
      
               
                <Skeleton width="90%" height={12} className="rounded-md" />
                <Skeleton width="70%" height={12} className="rounded-md" />
      
           
                <div className="flex space-x-4 mt-1">
                  <Skeleton width={50} height={12} />
                  <Skeleton width={50} height={12} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex-col mb-3 border pl-1 pr-1 rounded-2xl mt-2 ">
          <div className="flex justify-between pl-1 pt-1 mb-1 flex-nowrap flex-shrink-0">
            <div className="flex flex-row">
              <div className="w-fit h-fit bg-slate-500 rounded-full">
                <img
                  alt="post image"
                  src={userphoto}
                  className="w-10 h-10 object-cover flex-nowrap rounded-full"
                ></img>
              </div>
              <div className="flex flex-col">
                <p className="ml-2 font-semibold text-slate-200 text-sm">
                  {commentusername}
                </p>

                <p className="ml-2  text-slate-400 text-sm">{nickname}</p>
              </div>
            </div>

            <div>
              {showedit() && (
                <button
                  onClick={() => {
                    setoption(true);
                  }}
                >
                  <img src={editcommenticon}></img>
                </button>
              )}

              {options && (
                <div className="flex flex-col w-fit h-fit z-10 rounded-md">
                  <button
                    className="rounded-2xl"
                    onClick={(e) => {
                      setoption(false);
                      deletemycomment();
                    }}
                  >
                    <img className="object.cover" src={deleteicon}></img>
                  </button>

                  <button className="rounded-2xl">
                    <img
                      className="object.cover"
                      src={editicon}
                      onClick={(e) => {
                        setoption(false);
                        editcomment();
                      }}
                    ></img>
                  </button>
                </div>
              )}
            </div>
          </div>
          <p className="text-slate-400 text-xs p-2 break-words">{content}</p>

          <p className="text-green-300 font-bold text-xs pl-1 pb-1">
            posted :{date}
          </p>
          </div>)}
          </SkeletonTheme>
    </li>
  );
};
