export const getCountryData = async (countryName) => {
  const apiUrl = `https://restcountries.com/v3.1/name/${countryName}`;

  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error("Country not found.");
    }
    const data = await response.json();
    return {
      flag: data[0]?.flags?.png || "",
      abbreviation: data[0]?.cca3 || "",
      fifa: data[0]?.fifa || "",
    };
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
