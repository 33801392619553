import React, {useState, useEffect} from 'react'
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Listofblockusers } from './components/Listofblockusers';
import { getblockedUsers, ProfilechangeStatus } from '../../app/features/profile/profile';
import PacmanLoader from "react-spinners/RotateLoader";
import { useDispatch, useSelector } from 'react-redux';
export const Blockusers = () => {

    let blockuserstats = useSelector((state) => state.profile.blockuserstats);
    let listofblockuser = useSelector((state) => state.profile.listofblockuser);
    const navigate = useNavigate();
    const userid = useSelector((state) => state.register.userID);
    const token = useSelector((state) => state.register.refreshtoken);
    const [loading, setloading] = useState(true);
    let [color, setColor] = useState("#d49115");
    const dispatch = useDispatch()

    let showcontent = ()=>{
       if(loading === false){
        if(listofblockuser.length > 0){
          
          return(
              <div className="grid grid-cols-2 gap-2 mb-3 p-2 ">
                  {
                      listofblockuser.map((value,index)=>{
                          return <Listofblockusers id={value.id} photolink={value.photolink} location={value.location} online={value.online} name={value.name} key={index}/>
                      })
                  }
  
              </div>
          )
      }else{
          return <div className='w-full'>
  
                 <p className='mt-16 text-yellow-200 text-xs w-full text-center'>No Blocked user!</p>
  
                 </div> 
      }
       }
       
    
    }

    useEffect(()=>{
      if(blockuserstats !== "loading"){
        dispatch(getblockedUsers({token,userid}))
      }
    },[])

    useEffect(()=>{
      if(blockuserstats === "succeeded"){
        setloading(false)
        dispatch(ProfilechangeStatus("idle"))
      }
    },[blockuserstats])
 
    return (
      <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
  
      <div className='w-full md:w-2/4 flex flex-col'>
      <header className="flex gap-4 items-center">
        <FaAngleLeft
          color="white"
          size={30}
          onClick={() => {
            navigate(-1);
          }}
        />

        <h4 className="font-bold text-lg text-white">List Of blocked users</h4>
      </header>

      {loading && (
                <div className="w-full flex flex-col items-center">
                    <PacmanLoader
                    color={color}
                    loading={loading}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />

                    <p className="text-xs">Please wait...</p>
                </div>
      )}

     {showcontent()}
      </div>
      
    </div>
  )
}
