import React ,{useState, useEffect} from 'react'
import personIcon from "../../icons/person.svg"
import { downloadImage } from '../../api/sendImage'
import deleteIcon from "../../icons/deleteicon.svg"
import { useSelector, useDispatch } from 'react-redux'
import PacmanLoader from "react-spinners/RingLoader";
import { deletereview, changemodelstatus } from '../../app/features/model/modelSlice'
import { format } from 'date-fns'


export const Model_review = ({name, photolink, content, id,userid,posttime}) => {
    

    let date1 = new Date(parseInt(posttime))
    //console.log('post date '+ date)
    const date =   format(date1, "MM/dd/yyyy 'at' h:mm a");

    const [image, setimage] = useState(personIcon)
    const myID = useSelector(state => state.register.userID)
    const review_delete_stats = useSelector(state => state.model.review_delete_stats)
     const token = useSelector(state => state.register.refreshtoken)
    const dispatch = useDispatch()

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#d49115");

    useEffect(()=>{
       setimage(downloadImage(photolink,"profile"))
    },[])

    useEffect(()=>{
        if(review_delete_stats === "succeeded"){
            setLoading(false)
            dispatch(changemodelstatus("idle"))
        }

        if(review_delete_stats === "failed"){
            setLoading(false)
            dispatch(changemodelstatus("idle"))
        }
    },[review_delete_stats])

    const show_delete = ()=>{
        if(myID === userid){
            return true
        }else{
            return false
        }
    }

    const delete_my_review = ()=>{

        if(review_delete_stats !== "loading"){
            setLoading(true)
            dispatch(
              deletereview({token, id})
            )
        }

    }



  return (
    <div className='flex mb-2 flex-col'>

        <div className='flex mb-1'>
            <img alt='clientIcon' src={image} className='rounded-full w-5 h-5 object-cover mt-3'></img>
                      <div className='flex flex-col ml-2 bg-slate-500 p-2 rounded-md'>
                        <p className='bg-slate-300 font-bold text-white text-sm'>{`${name}`}</p>
                        <p className='text-xs text-yellow-500'>{`${content}`}</p>

                      </div>
                      {
                        show_delete() &&
                        <button onClick={(e)=>delete_my_review()}>
                          <img alt='deleteIcon' src={deleteIcon} ></img>
                        </button>
                      }

                      {
                        loading &&
                         <div className='flex flex-col justify-center mb-3'>
                            <PacmanLoader
                            color={color}
                            loading={loading}
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                          <p className='text-xs text-yellow-500'>deleteing wait...</p>

                        </div>
                      }

        </div>

      <p className='font-bold text-yellow-500 text-xs ml-4'>{date}</p>
       
    </div>
              
  )
}
