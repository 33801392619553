const pos = {
    latitude:"",
    longtitude:"",
    err:''
}
export const getGeo = ()=>{
  if(navigator.geolocation){
    var location_timeout = setTimeout("geolocFail", 10000);
    navigator.geolocation.getCurrentPosition((position)=>{
      clearTimeout(location_timeout);
      console.log(position)
       pos.latitude = position.coords.latitude;
       pos.longtitude = position.coords.longitude;
     },function(error){
      clearTimeout(location_timeout)
      pos.err = "Can't fetch your location , turn on your location"
      return pos;
     })

     return pos;
  }else{
    pos.err = "Can't fetch your location , turn on your location"
    return pos;
  }
}