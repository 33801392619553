import React,{useState} from 'react'
import { Mycontentlist } from './collectionList/Mycontentlist'
import { useSelector } from 'react-redux';

export const Content = () => {
    
    const listofcontent = useSelector((state) => state.profile.listofcontent);
   


    let showcontent = ()=>{

        if(listofcontent.length > 0){
            
            return(
                <div className="grid grid-cols-2 gap-2 mb-3 p-2">
                    {
                        listofcontent.map((value,index)=>{
                            return <Mycontentlist exclusiveid={value.exclusiveid} name={value.name} id={value.id} exclusivelink={value.exclusivelink} key={index} contenttype={value.contenttype}/>
                        })
                    }
    
                </div>
            )
        }else{
            return <div className='w-full'>
    
                   <p className='mt-16 text-yellow-200 text-xs w-full text-center'>you have bought zero content</p>
    
                   </div> 
        }
    
    }
  return (
    <div className='w-full flex flex-col'>
        <p className='text-slate-50 text-center font-bold mt-2'>Content List</p>

        {showcontent()}
        
        
    </div>
  )
}
