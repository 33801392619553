import React, { useEffect, useState } from "react";
import person from "../../icons/person.svg";
import PacmanLoader from "react-spinners/PacmanLoader";
import idcardicon from "../../icons/idcardIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  createmodel,
  changemodelstatus,
} from "../../app/features/model/modelSlice";
import deleteIcon from "../../icons/deleteicon.svg";
import { userlocation } from "../../api/userlocation";

let times = [];
let hours = [];
let Interested = [];
let MIN = ""

export const CreateModelview = () => {
  const firstname = useSelector((state) => state.profile.firstname);
  const lastname = useSelector((state) => state.profile.lastname);
  const login = useSelector((state) => state.register.logedin);
  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const modelpoststatus = useSelector((state) => state.model.modelpoststatus);
  const message = useSelector((state) => state.model.message);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#d49115");

  const [name, setname] = useState(`${firstname} ${lastname}`);
  const [age, setage] = useState("18");
  const [location, setlocation] = useState("");
  const [bodytype, setbodytype] = useState("Slim");
  const [height, setheight] = useState("");
  const [weight, setweight] = useState("");
  const [gender, setgender] = useState("Man");
  const [smoke, setsmoke] = useState("Yes");
  const [drink, setdrink] = useState("Yes");
  const [pm, setpm] = useState("PM");
  const [duration, setduration] = useState("1");
  const [days, setdays] = useState("1hour");
  const [price, setprice] = useState("");
  const [discription, setdiscription] = useState("");
  const [disablebut, setdisablebut] = useState(false);
  const [hosttype, sethosttype] = useState("fan Meet");
  const [imglist, setimglist] = useState([]);
  const [photolink, setphotolink] = useState([]);
  

  useEffect(() => {
    if (modelpoststatus === "succeeded") {
      toast.success(`${message}`, { autoClose: 2000 });
      dispatch(changemodelstatus("idle"));
      navigate("/model");
    }

    if (modelpoststatus === "failed") {
      setdisablebut(false);
      setLoading(false);
      toast.error(`${message}`, { autoClose: 5000 });
      dispatch(changemodelstatus("idle"));
    }
  }, [modelpoststatus]);

  useEffect(() => {
    if (!login) {
      window.location.href = "/";
    }
    imglist.reverse();
  });

  useEffect(() => {
    getLocation();
  });

  const checkuserInput = () => {
    if (!age) {
      toast.error(`age Empty`);
      return;
    }
    if (!hosttype) {
      toast.error(`Select host type`);
      return;
    }
   
    if (photolink.length < 0) {
      toast.error(`host image Empty`);
      return;
    }
    if (!location) {
      toast.error(`location Empty`);
      return;
    }
    if (!price) {
      toast.error(`price Empty`);
      return;
    }
    if (!height) {
      toast.error(`height Empty`);
      return;
    }
    if (times.length <= 0) {
      toast.error(`please select avalable hours`);
      return;
    }
    if (hours.length <= 0) {
      toast.error(`please select avalable days`);
      return;
    }

    if (Interested.length <= 0) {
      toast.error(`please select  Interested In client field`);
      return;
    }
    if (!discription) {
      toast.error(`Write your discription`);
      return;
    }

    if (modelpoststatus !== "loading") {
      setdisablebut(true);
      setLoading(true);
      dispatch(
        createmodel({
          name,
          age,
          location,
          price,
          duration: days,
          bodytype,
          smoke,
          drink,
          interestedin: Interested.toString(),
          height,
          weight,
          discription,
          gender,
          timeava: times.toString(),
          daysava: hours.toString(),
          photolink: photolink,
          userid,
          token,
          hosttype,
        })
      );
    }
  };

  const getLocation = async () => {
    let loc = await userlocation();

    if (loc.success !== "failed") {
      setlocation(`${loc.country}`);
    }
  };

  return (
    <>
    
    <div className="p-7 w-screen h-screen overflow-auto flex-col">
      <ToastContainer position="top-center" theme="dark" />
      <p className="text-slate-300 font-semibold text-center underline underline-offset-4 text-2xl sm:w-1/2">
         Create New Model
      </p>

      <fieldset
        className="sm:w-1/2 sm:ml-10 mt-5 border p-5 rounded-2xl border-slate-400"
        disabled={disablebut}
      >
        <label className="inpt mb-3 block text-slate-300 font-semibold text-center">
          {" "}
          {`${firstname} ${lastname}`}
        </label>

        <label className="text-slate-300">Select age</label>
        <div className="flex justify-between">
          <input
            type="range"
            min="18"
            max="70"
            class="slider"
            value={age}
            onChange={(e) => {
              setage(e.currentTarget.value);
            }}
          ></input>
          <label className="text-slate-400">{age} years</label>
        </div>

        <input
          className="inpt mt-3 mb-3"
          placeholder="Fetching location..."
          disabled={true}
          value={location}
        ></input>

        <label for="bodytype" className="text-slate-300 mr-2">
          Select body type:
        </label>

        <select
          name="bodytype"
          className="btn mb-3"
          onChange={(e) => {
            setbodytype(e.currentTarget.value);
          }}
        >
          <option value="Slim">Slim</option>
          <option value="Curvy">Curvy</option>
          <option value="Chubby">Chubby</option>
          <option value="Normal">Normal</option>
          <option value="Muscular">Muscular</option>
          <option value="Althetic">Althetic</option>
          <option value="Skinny">Skinny</option>
        </select>

        <div>
          <div>
            <label className="text-slate-300 mr-2 font-semibold">
              What is your height? {height}
            </label>
          </div>

          <select
            className="btn mb-3 bg-gray-800 w-1/2"
            onChange={(e) => {
              setheight(e.currentTarget.value);
            }}
            size={4}
          >
            {Array.from({ length: 200 }, (_, i) => i + 57).map((value) => {
              return (
                <option
                  value={`${value} cm`}
                  className="bg-gray-800 text-slate-100 rounded-2xl w-full border mt-1 mb-1"
                >{`${value} cm`}</option>
              );
            })}
          </select>
        </div>

        <div>
          <div>
            <label className="text-slate-300 mr-2 font-semibold">
              What is your weight? {weight}
            </label>
          </div>

          <select
            className="btn mb-3 bg-gray-800 w-1/2"
            onChange={(e) => {
              setweight(e.currentTarget.value);
            }}
            size={4}
          >
            {Array.from({ length: 120 }, (_, i) => i + 40).map((value) => {
              return (
                <option
                  value={`${value} kg`}
                  className="bg-gray-800 text-slate-100 rounded-2xl w-full border mt-1 mb-1"
                >{`${value} kg`}</option>
              );
            })}
          </select>
        </div>

        <label for="gender" className="text-slate-300 mr-2">
          Gender:
        </label>

        <select
          name="gender"
          className="btn mb-3"
          onChange={(e) => {
            setgender(e.currentTarget.value);
          }}
        >
          <option value="Man">Man</option>
          <option value="Woman">Woman</option>
          <option value="Trans">Trans</option>
          <option value="Trans">Couple</option>
        </select>

        <div>
          <label for="smoke" className="text-slate-300 mr-2">
            Smoke:
          </label>
          <select
            name="smoke"
            className="btn mb-3"
            onChange={(e) => {
              setsmoke(e.currentTarget.value);
            }}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div>
          <label for="drink" className="text-slate-300 mr-2">
            Drink:
          </label>
          <select
            name="drink"
            className="btn mb-3"
            onChange={(e) => {
              setdrink(e.currentTarget.value);
            }}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </fieldset>

      <fieldset
        className="sm:w-1/2 sm:ml-10 mt-5 border p-5 rounded-2xl border-slate-300 mb-10"
        disabled={disablebut}
      >
        <div className="flex-col border border-slate-300 rounded-2xl p-4">
          <p className="text-slate-300">Avalable hours</p>

          <div className="flex-col"></div>
          <select
            name="pm/am"
            className="btn mb-3 flex-wrap"
            onChange={(e) => {
              setpm(e.currentTarget.value);
            }}
          >
            <option value="PM">PM</option>
            <option value="AM">AM</option>
          </select>
          <div>
            <div className="grid  grid-cols-3">
              {[
                1 + `:00` + `${pm}`,
                2 + `:00` + `${pm}`,
                3 + `:00` + `${pm}`,
                4 + `:00` + `${pm}`,
                5 + `:00` + `${pm}`,
                6 + `:00` + `${pm}`,
                7 + `:00` + `${pm}`,
                8 + `:00` + `${pm}`,
                9 + `:00` + `${pm}`,
                10 + `:00` + `${pm}`,
                11 + `:00` + `${pm}`,
                12 + `:00` + `${pm}`,
              ].map((value) => {
                return (
                  <div>
                    <label className="text-slate-400 mr-1">{value}</label>
                    <input
                      id="hourscheck"
                      type="checkbox"
                      value={`${value}`}
                      className="mr-3 shrink"
                      onClick={(e) => {
                        let input = e.currentTarget.value;
                        let check = times.findIndex((value) => {
                          return input === value;
                        });
                        if (check !== -1) {
                          times.splice(check, 1);
                        } else {
                          times.push(e.currentTarget.value);
                        }

                        console.log(times);
                      }}
                    ></input>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="flex-col border border-slate-300 rounded-2xl p-4 mt-3">
          <p className="text-slate-300">Avalable DAYS</p>

          <div className="flex-col">
            <div className="grid  grid-cols-3">
              {["MON", "TUE", "WED", "THUR", "FRI", "SAT", "SUN"].map(
                (value) => {
                  return (
                    <div className="">
                      <label className="text-slate-400 mr-1">{value}</label>
                      <input
                        type="checkbox"
                        value={`${value}`}
                        className="mr-3 shrink"
                        onClick={(e) => {
                          let input = e.currentTarget.value;
                          let check = hours.findIndex((value) => {
                            return input === value;
                          });
                          if (check !== -1) {
                            hours.splice(check, 1);
                          } else {
                            hours.push(e.currentTarget.value);
                          }
                        }}
                      ></input>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>

        <div className="mt-3 border border-slate-300 rounded-2xl p-2">
          <label className="text-slate-300">Duration</label>
          <div className="flex justify-between">
            <input
              type="range"
              min="1"
              max="30"
              class="slider"
              value={duration}
              onChange={(e) => {
                setduration(e.currentTarget.value);
              }}
            ></input>

            <select
              name="days"
              className="btn mb-3 flex-wrap"
              onChange={(e) => {
                setdays(e.currentTarget.value);
              }}
            >
              <option value={`${duration}min`}>{duration}MIN</option>
              <option value={`${duration}hour`}>{duration}HOUR</option>
              <option value={`${duration}day`}>{duration}DAY</option>
            </select>
          </div>

          <div className="flex-col">
          <label className="text-slate-300 mr-2">Choose Category</label>

          <select
            name="hosttype"
            className="btn mb-3 flex-wrap mt-2 border-solid border border-1 p-1 rounded-lg border-slate-400"
            onChange={(e) => {
              sethosttype(e.currentTarget.value);
              if(e.currentTarget.value === "Private show"){
                MIN = "per minute"
              }else{
                MIN = ""
              }
            }}
          >
            <option value={`Fan meet`}>Fan meet</option>
            <option value={`Fan date`}>Fan date</option>
            <option value={`Private show`}>Private show</option>
          </select>
        </div>

          <label className="text-slate-300">
            Enter desired tip amount {`${MIN}`}
          </label>
          <input
            className="inpt mt-3 mb-3 w-2/3"
            type="number"
            placeholder="Enter tip in gold"
            onInput={(e) => {
              
              if(MIN){
                setprice(e.currentTarget.value + " GOLD"+ `${MIN}`);

              }else{
                setprice(e.currentTarget.value + " GOLD");
              }
             
            }}
          ></input>
        </div>

        <div className="flex-col">
          <label className="text-slate-300">Interested In</label>

          <div className="grid  grid-cols-3">
            {["MEN", "WOMEN", "COUPLE", "TRANS"].map((value) => {
              return (
                <div className="whitespace-nowrap">
                  <label className="text-slate-400 mr-1">{value}</label>
                  <input
                    type="checkbox"
                    value={`${value}`}
                    className="mr-3 shrink"
                    onClick={(e) => {
                      let input = e.currentTarget.value;
                      let check = Interested.findIndex((value) => {
                        return input === value;
                      });
                      if (check !== -1) {
                        Interested.splice(check, 1);
                      } else {
                        Interested.push(e.currentTarget.value);
                      }
                    }}
                  ></input>
                </div>
              );
            })}
          </div>
        </div>

        {/* <div className="flex-col">
          <label className="text-slate-300 mr-2">Select host type</label>

          <select
            name="hosttype"
            className="btn mb-3 flex-wrap mt-2 border-solid border border-1 p-1 rounded-lg border-slate-400"
            onChange={(e) => {
              sethosttype(e.currentTarget.value);
              if(e.currentTarget.value === "Private show"){
                MIN = "par minute"
              }else{
                MIN = ""
              }
            }}
          >
            <option value={`Private show`}>Private show</option>
            <option value={`Fan meet`}>Fan meet</option>
            <option value={`Fan date`}>Fan date</option>
          </select>
        </div> */}

        <div className="mt-2">
          <textarea
            className="inpt h-24"
            placeholder="About me"
            onInput={(e) => {
              setdiscription(e.currentTarget.value);
            }}
          ></textarea>
        </div>

      </fieldset>

      <fieldset
        className="sm:w-1/2 sm:ml-10 mt-5 border p-5 rounded-2xl border-slate-300 mb-10"
        disabled={disablebut}
      >
        <div className="p-3 flex flex-col">
          <label className="text-slate-300 text-center">
            Please Upload Your Photo
          </label>
          <p className="text-slate-400 text-center text-sm font-semibold mt-2">
            {imglist.length} Photo Selected
          </p>
          <div className="flex flex-wrap">
            {imglist.map((value) => {
              return (
                <div className="flex-row bg-blue-500 md:w-fit mb-1 gap-y-3 w-full m-auto ">
                  <button
                    onClick={(e) => {
                      setimglist((index) =>
                        index.filter((item) => item !== value)
                      );
                      setphotolink((index) =>
                        index.filter((item) => item !== value)
                      );
                    }}
                  >
                    <img alt="deleteIcon" src={deleteIcon}></img>
                  </button>
                  <img
                    alt="modelIMG"
                    src={value}
                    className="object-cover rounded-md shrink h-52 w-full m-auto"
                  ></img>
                </div>
              );
            })}
          </div>

          <input
            type="file"
            className="btn w-1/2 mt-2"
            accept="image/*"
            onChange={(e) => {
              if (e.target.files[0]) {
                setimglist((value) => [
                  ...value,
                  URL.createObjectURL(e.target.files[0]),
                ]);
                setphotolink((value) => [...value, e.target.files[0]]);
                //  imglist.push()
              }
            }}
          ></input>
        </div>

        <hr className="bg-slate-300 mb-3"></hr>

        <button
          className="btn w-full text-center block rounded-2xl h-7 truncate"
          disabled={disablebut}
          onClick={() => {
            checkuserInput();
          }}
        >
          Procced
        </button>

        <div className=" mt-3 flex justify-between overflow-hidden">
          <PacmanLoader
            color={color}
            loading={loading}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
            margin={"auto"}
          />

          <PacmanLoader
            color={color}
            loading={loading}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
            margin={"auto"}
          />
          <PacmanLoader
            color={color}
            loading={loading}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
            margin={"auto"}
          />
        </div>
      </fieldset>
      </div>
      </>
  );
};
