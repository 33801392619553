import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";


export const Contentcreators = () => {
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
    <Header />
    <div className="p-4">
      <Breadcrumbs title="About" />
      <div>
        <header className="my-4">
          <h4 className="font-bold  text-2xl">Does Mmeko allow adult models and content creators?</h4>
          
        </header>
        <p className='font-semibold pt-1'>
          Yes, absolutely!
        </p>

        <p>
         Yes, we are committed to providing the best platform for ALL creators, including those who 
         create adult content. Our priority is to offer a safe and secure space for creators to share, 
         earn, and connect with their audience. We do not restrict content, as long as it meets moral 
         and legal standards.
         if you have any questions, please don't hesitate to reach out to us via the live chat support on 
         this page. We're here to help with any inquiries you may have.
        </p>
        
        
        </div>
    </div>
  </div>
  )
}