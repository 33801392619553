import React, { useState, useEffect } from "react";
import { Acceptedlist } from "./modelnotifylist/Acceptedlist";
import { useSelector, useDispatch } from "react-redux";
import {
  acceptedr_req,
  resetstat,
} from "../../../app/features/booking/booking";
import PacmanLoader from "react-spinners/RingLoader";

export const Acceptedview = () => {
  const Accepted = useSelector((state) => state.booking.acceptedList);
  const [loading, setloading] = useState(false);
  const [success, setsuccess] = useState(false);
  let [color, setColor] = useState("#c2d0e1");

  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const acceptedReqstat = useSelector((state) => state.booking.acceptedReqstat);
  const acceptedReqMes = useSelector((state) => state.booking.acceptedReqMes);
  const dispatch = useDispatch();

  useEffect(() => {
    showAccpets();
  }, []);

  useEffect(() => {
    if (acceptedReqstat === "succeeded") {
      setloading(false);
      dispatch(resetstat());
    }
  }, [acceptedReqstat]);

  const Showaccepted = () => {
    if (loading === false) {
      if (Accepted.length > 0) {
        return (
          <ul className="flex flex-col items-center mr-2 ml-2">
            {Accepted.map((value,index) => {
              return (
                <Acceptedlist
                  key={`${index}_${userid}`}
                  name={value.name}
                  photolink={value.photolink}
                  status={value.status}
                  type={value.type}
                  date={value.date}
                  time={value.time}
                  modelid={value.modelid}
                  modeluserid={value.modeluserid}
                  amount={value.amount}
                />
              );
            })}
          </ul>
        );
      } else {
        return (
          <p className="text-slate-400 text-center mt-16 text-xs">
            !You currently don`t have any accepted request
          </p>
        );
      }
    }
  };

  const showAccpets = () => {
    if (acceptedReqstat !== "loading") {
      setloading(true);
      dispatch(acceptedr_req({ userid, token }));
    }
  };

  return (
    <div>
      {loading && (
        <div className="flex w-full items-center flex-col mt-16">
          <PacmanLoader
            color={color}
            loading={loading}
            size={35}
            aria-label="Loading Spinner"
            data-testid="loader"
          />

          <p className="text-slate-400 text-xs">please wait...</p>
        </div>
      )}
      <div>{Showaccepted()}</div>
    </div>
  );
};
