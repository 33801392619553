import React, { useState, useEffect } from "react";
import { Confirmemail } from "./authviews/Confirmemail";
import Popup from "reactjs-popup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  registernewUser,
  status,
  error,
} from "../app/features/register/registerSlice";
import { changeStatus } from "../app/features/register/registerSlice";
import locationIcon from "../icons/locationIcon.svg";
import { userlocation } from "../api/userlocation";

export const Register = () => {
  const stats = useSelector(status);
  const errs = useSelector(error);
  const dispatch = useDispatch();

  const toastId = React.useRef(null);

  const alert = (message, type, close) => {
    toastId.current = toast(`${message}`, {
      type: `${type}`,
      autoClose: close,
    });
  };

  const dismissalert = () => {
    toast.dismiss(toastId.current);
  };

  let regex = /^[a-zA-Z0-9_@]+$/

  let firstLatter = /^@.*/

  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [dob, setdob] = useState("");
  const [gender, setgender] = useState("");
  const [nickname, setnickname] = useState("");
  const [country, setcountry] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [compas, setcompas] = useState("");
  const [dateofbirth, setdateofbirth] = useState("");
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [agreedPrivacy, setAgreedPrivacy] = useState(false);

  const notify = (message) =>
    toast.error(`${message}`, {
      autoClose: 1000,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
    });

  const checkInput = () => {
    if (!firstname) {
      notify("Enter first name");
      return true;
    }

    if (firstname.length > 12) {
      notify("Name must be maximum of 12 characters");
      return true;
    }
    if (!lastname) {
      notify("Enter last name");
      return true;
    }
    if (lastname.length > 12) {
      notify("Name must be maximum of 12 characters");
      return true;
    }
    if (!dob) {
      notify("Enter date of birth");
      return true;
    }
    if (!gender) {
      notify("Select gender");
      return true;
    }
    if (!nickname) {
      notify("user must choose username");
      return true;
    }
    if (nickname.length > 20) {
      notify("Username must be maximum of 20 characters");
      return true;
    }
   
    if (!country) {
      notify("Enter country");
      return true;
    }
    if (!email) {
      notify("Enter email");
      return true;
    }
    if (!password) {
      notify("Enter password");
      return true;
    }
    if (password !== compas) {
      notify("Password mismatch");
      return true;
    }

    if (!agreedTerms || !agreedPrivacy) {
      notify("You must read and agree to the Terms and Privacy Policy");
      return true;
    }
    
    return false;
  };

  useEffect(() => {
    if (stats.toString() === "succeeded") {
      dismissalert();
      alert("Success!", "success", true);
      dispatch(changeStatus("idle"));
      toast(
        <Confirmemail
          email={email}
          alert={alert}
          dismissalert={dismissalert}
        />,
        { autoClose: false }
      );
    }
    if (stats.toString() === "failed") {
      dismissalert();
      alert(`${errs}`, "error", true);
      dispatch(changeStatus("idle"));
    }
  }, [stats, errs, email, dispatch]);

  useEffect(() => {
    getLocation();
  });

  const getLocation = async () => {
    let loc = await userlocation();

    if (loc.success !== "failed") {
      setcountry(`${loc.country}`);
    }

    
  };
  return (
    <div className="text-black bg-black h-full overflow-auto pb-8 pl-4 pr-4 pt-2">
      <ToastContainer position="top-center" theme="dark" />
      <p className="text-yellow-600 text-center text-2xl font-bold mt-5">
        Register
      </p>
      <p className="text-slate-400 text-center">
        Create a Page for a Model or Business.
      </p>
      <div className="mt-2 px-3 mb-4">
        <input
          type="text"
          placeholder="First Name"
          className="inpt w-full"
          onChange={(e) => {
            if (e.target.value.length > 12) {
              toast.info("name must be maximum of 12 characters", {
                autoClose: false,
              });
            }
            setfirstname(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Last Name"
          className="inpt w-full mt-2"
          onChange={(e) => {
            if (e.target.value.length > 12) {
              toast.info("name must be maximum of 12 characters", {
                autoClose: false,
              });
            }
            setlastname(e.target.value);
          }}
        />
        <label className="text-slate-300 font-semibold text-sm mt-2 block">
          Date of Birth
        </label>
        <input
          type="date"
          className="inpt w-full mt-2"
          onChange={(e) => {
            let date = new Date(String(e.target.value));
            let current_date = new Date(Number(Date.now()));
            let years = current_date.getFullYear() - date.getFullYear();
            setdob(String(years));
            setdateofbirth(date)
          }}
        />
        <label className="text-slate-300 font-semibold text-sm mt-2 block">
          Gender
        </label>
        <div className="flex justify-between mt-2">
          <label className="flex items-center">
            <input
              type="radio"
              value="Male"
              checked={gender === "Male"}
              onChange={() => setgender("Male")}
            />
            <span className="ml-2 text-slate-300">Male</span>
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              value="Female"
              checked={gender === "Female"}
              onChange={() => setgender("Female")}
            />
            <span className="ml-2 text-slate-300">Female</span>
          </label>
        </div>

        <div  className="inpt w-full mt-2 flex flex-row">
          <p className="pt-2 ml-1">@</p>
        <input
          required={true}
          type="text"
          placeholder="Username"
          className="inpt w-full"
          onChange={(e) => {
            
            if(!regex.test(e.target.value)){
                toast.info("invalid input format", {
                autoClose: false,
              });
              return

            }
            if (e.target.value.length > 20) {
              toast.info("username must be maximum of 20 characters", {
                autoClose: false,
              });
              return
            }
            setnickname("@".concat(e.target.value));
          }}
        />
        </div>
        
       
        <p className="inpt w-full mt-2 pl-2">{country}</p>
                     
        
        <input
          type="email"
          placeholder="Email"
          className="inpt w-full mt-2"
          onChange={(e) => setemail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          className="inpt w-full mt-2"
          onChange={(e) => setpassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          className="inpt w-full mt-2"
          onChange={(e) => setcompas(e.target.value)}
        />

        {/* Terms and Privacy */}
        <div className="mt-4">
          <label className="flex items-center text-slate-300 mb-2">
            <input
              type="checkbox"
              checked={agreedTerms}
              onChange={(e) => setAgreedTerms(e.target.checked)}
              className="mr-2"
            />
            <a href="/privacy_policy">
            <span>
              I read and agree to the{" "}
              <span className="text-yellow-500 underline cursor-pointer">
                Terms and Conditions
              </span>
              .
            </span>
            </a>
           
          </label>
          <label className="flex items-center text-slate-300">
            <input
              type="checkbox"
              checked={agreedPrivacy}
              onChange={(e) => setAgreedPrivacy(e.target.checked)}
              className="mr-2"
            />

            <a href="/privacy_policy">
            <span>
              I read and agree to the{" "}
              <span className="text-yellow-500 underline cursor-pointer">
                Privacy Policy
              </span>
              .
            </span>
            </a>
           
          </label>
        </div>

        <button
          className="btn w-full mt-4 text-center h-9 mb-7"
          onClick={() => {
            if (!checkInput()) {
              

             
              alert("Please wait...", "info", false);
              dispatch(
                registernewUser({
                  firstname,
                  lastname,
                  age: dob,
                  gender,
                  nickname,
                  country,
                  email,
                  password,
                  dob:dateofbirth
                })
              );
            }
          }}
        >
          Register
        </button>
      </div>
    </div>
  );
};
