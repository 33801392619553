import React,{useEffect, useState} from 'react'
import sendIcon from "../../icons/emailsendIcon.svg"
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ToastContainer,toast } from 'react-toastify'
import PacmanLoader from "react-spinners/RingLoader";
import { sendmessage,clear_users, reset_alluser } from '../../app/features/admin/admin'

export const Message_users = () => {


  const navigate = useNavigate()
  const marked_users = useSelector(state => state.admin.marked_users)
  const send_stats = useSelector(state => state.admin.send_stats)
  const send_message = useSelector(state => state.admin.send_message)
  const token = useSelector(state => state.register.refreshtoken)
  const dispatch = useDispatch()
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#d49115");
  const [input,  setinput] = useState("")

  useEffect(()=>{
  if(send_stats === "succeeded"){
    dispatch(reset_alluser())
    dispatch(clear_users())
    setinput("")
    toast.success(`send success`,{autoClose:2000})
    setLoading(false)
  }

  if(send_stats === "failed"){
    toast.error(`${send_message}`,{autoClose:2000})
    setLoading(false)
  }
  },[send_stats])

  const send_Message =()=>{
    if(!input){
      toast.info("enter text",{autoClose:2000})
      return
    }
    
    if(send_message !== "loading"){
      setLoading(true)
      dispatch(sendmessage({token,data:marked_users,message:input}))
    }

  }

  return (
   <div className='sm:flex  sm:justify-center sm:w-1/2 sm:ml-16 sm:rounded-xl sm:mb-0 mb-8 relative'>
       <div className='w-full  h-full flex flex-col items-center '>
         <p className='text-yellow-500 font-bold border border-b-2 border-t-0 border-r-0 border-l-0 border-yellow-500'>Message Users</p>
         <div className="w-full pl-3 pr-3">
          <ToastContainer position='top-center'  theme='dark'/>

           <textarea value={input} placeholder='send message' className='w-full h-52 mt-2 mb-2 rounded-lg bg-slate-500 placeholder:text-center placeholder:text-xs'onInput={(e)=>{
            setinput(e.currentTarget.value)
           }}></textarea>

         </div>

          <button className='text-white flex bg-blue-500 p-1 rounded-full shadow shadow-white hover:bg-blue-400 active:bg-blue-300' onClick={(e)=>{
            send_Message()
          }}>
            <label className='text-white  text-sm font-bold' >send</label>
             <img alt='sendicon' src={sendIcon}></img>
            </button>
             <p className='text-yellow-500 font-bold border border-b-2 border-t-0 border-r-0 border-l-0 border-yellow-500 mt-2'>Send to {marked_users.length} Users</p>

              {
              loading && 
              <div className='flex flex-col items-center mt-5 w-full'>
              <PacmanLoader
              color={color}
              loading={loading}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
              margin={ "auto"}
            />

            <p className='text-yellow-500 text-xs'>Sending please wait...</p>
            </div>
          }

        </div>

       
    </div>
  )
}
