import React from "react";
import Breadcrumbs from "./components/breadcrumb";
import TitleSection from "./components/titleSection";
import ArticleList from "./components/articlelist";
import SearchBar from "./components/searchbar";
import Header from "./components/header";
import { FaGraduationCap } from "react-icons/fa";
import { TawkToIntegration } from "../../api/tawk";

export const GeneralPage = () => {
  

  
};
