import React, {useState, useEffect} from 'react'
import { List_of_users } from './List_of_users'
import searchIcon from "../../icons/searchicon.svg"
import sendIcon from "../../icons/emailsendIcon.svg"
import PacmanLoader from "react-spinners/RingLoader";
import { useSelector, useDispatch } from 'react-redux';
import { getalluser, reset_alluser, add_user } from '../../app/features/admin/admin';
import { ToastContainer,toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';


let mark_user = []

export const Users = () => {

  const [male_click, setmale_click] = useState(false)
  const [female_click, setfemale_click] = useState(false)
  const [showall_click, setshowall_click] = useState(false)
  const [markall, setmarkall_click] = useState(false)
  const [mark_click, setmark_click] = useState(false)
  const [alluser_list, setalluser_list] = useState([])

  const alluser_stats = useSelector(state => state.admin.alluser_stats)
  const alluser_message = useSelector(state => state.admin.alluser_message)
  const user_list = useSelector(state => state.admin.alluser_list)
  const token = useSelector(state => state.register.refreshtoken)
  const admin = useSelector(state=> state.profile.admin)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#d49115");
  let [display, setdisplay] = useState(false)
  let [search_text , set_search_text] = useState("")
 

  useEffect(()=>{

    checkadmin()
    if(alluser_stats !== "loading"){
      dispatch(
        getalluser({token})
      )
    }
  },[])

  useEffect(()=>{
    if(alluser_stats === "succeeded"){
      setalluser_list(user_list)
      dispatch(reset_alluser("idle"))
      setLoading(false)
      setdisplay(true)
    }

      if(alluser_stats === "failed"){
      console.log(alluser_message)
      dispatch(reset_alluser("idle"))
      setLoading(false)
    }
  },[alluser_stats])

  const diplay_users = ()=>{
    if(loading === false){
      if(alluser_list.length > 0){
         return  <div className='w-full'>
           <ul className='w-full p-2'>
            {
               alluser_list.map(value=>{
                return <List_of_users mark={mark_click} markall={markall} firstname={value.firstname} lastname={value.lastname} gender={value.gender} country={value.country} id={value._id} photolink={value.photolink} mark_user={mark_user}/>
               })
            }
           </ul>
           </div>
      }else{
          return <div className='w-full h-16 flex justify-center mt-16'>
            <p className='text-yellow-500 text-xs'>No register user yet!!!</p>
          </div>
      }
    }
  }

  const checkadmin = ()=>{
      if(admin === true){
        return true

      }else{
       window.location.href = "/"
      }
    }


  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
    
       <div className='w-full  h-full flex flex-col items-center md:w-2/4'>

         <p className='text-yellow-500 font-bold border border-b-2 border-t-0 border-r-0 border-l-0 border-yellow-500'>List Of App Users</p>

          {
          loading && 
            <div className='flex flex-col items-center mt-16 w-full'>
              <PacmanLoader
              color={color}
              loading={loading}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
              margin={ "auto"}
            />

            <p className='text-yellow-500 text-xs'>fetching all user...</p>
            </div>
          }

         <ToastContainer position='top-center'  theme='dark'/>

         {display &&
         <div className='w-full'>
            <div className='w-full flex-col pl-2 mb-2'>
          
          <div className='flex  mt-2'>
            <label className='text-white mr-2 text-sm font-bold'>Name or Email</label>
            <input type='text' className='rounded-lg bg-slate-400 placeholder:text-slate-600 placeholder:text-xs mr-1 pl-1  placeholder:text-center' placeholder='search by name/email' onInput={(e)=>{
              if(e.currentTarget.value){

                set_search_text(e.currentTarget.value)

                setalluser_list( alluser_list.filter(value =>{
                  let name = `${value.firstname} ${value.lastname}`
                    let name1 = `${value.lastname} ${value.firstname}`
                    return value.firstname.toLowerCase().trim() === e.currentTarget.value.toLowerCase().trim() || value.lastname.toLowerCase().trim() === e.currentTarget.value.toLowerCase().trim() ||
                    e.currentTarget.value === name || e.currentTarget.value === name1 || e.currentTarget.value.toLowerCase().trim() === value.email.toLowerCase().trim()
                }))

                if(alluser_list.length <= 0){
                  setalluser_list(user_list)
                }

              
              }else{
                setalluser_list(user_list)
              }
            }}>
            </input>
            <button className='bg-yellow-500 w-fit h-fit rounded-full' onClick={(e)=>{
              if(search_text){
                  setalluser_list( alluser_list.filter(value =>{
                    let name = `${value.firstname} ${value.lastname}`
                    let name1 = `${value.lastname} ${value.firstname}`
                    return value.firstname.toLowerCase().trim() === search_text.toLowerCase().trim() || value.lastname.toLowerCase().trim() === search_text.toLowerCase().trim() ||
                    search_text === name || search_text === name1 || search_text.toLowerCase().trim() === value.email.toLowerCase().trim()
                }))

                if(alluser_list.length <= 0){
                  setalluser_list(user_list)
                }
              }
            }}>
               <img alt='searchIcon' src={searchIcon}>
            </img>

            </button>
          </div>

            <div className='flex '>
            <label className='text-white mr-2 text-sm font-bold'>Filter by Gender:</label>

            <div className='flex'> 
               <label className='text-white  text-xs mt-1 font-bold'>Male</label>
               <input type='radio' className=' mr-2 mt-1' checked={male_click} onClick={(e)=>{
                 setfemale_click(false)
                 setmale_click(true)
                 setshowall_click(false)

                 setalluser_list(user_list)
               
                   setalluser_list( alluser_list.filter(value =>{
                  
                    return value.gender.toLowerCase().trim() === "male"
                  }))

                if(alluser_list.length <= 0){
                  setalluser_list(user_list)
                }

                 
               }} >
                
               </input>

               <label className='text-white  text-xs mt-1 font-bold'>Female</label>
               <input type='radio' className='mt-1' checked={female_click} onClick={(e)=>{
                setmale_click(false)
                setfemale_click(true)
                setshowall_click(false)
                 setalluser_list(user_list)
                  setalluser_list( alluser_list.filter(value =>{
                  
                    return value.gender.toLowerCase().trim() === "female"
                  }))

                if(alluser_list.length <= 0){
                  setalluser_list(user_list)
                }
               }}>
                
               </input>

               <label className='text-white  text-xs ml-2 mt-1 font-bold'>Show all</label>
               <input type='radio' className='mt-1 ' checked={showall_click} onClick={(e)=>{
                setmale_click(false)
                setfemale_click(false)
                setshowall_click(true)
                 
              
                  setalluser_list(user_list)
                
               }}>
                
               </input>
            

            </div>
           
           </div>

           <div className='text-white flex'>
            <label className='text-white  text-sm font-bold'>Mark all</label>
            <input type='radio' className=' ml-1' checked={markall} onClick={(e)=>{
              setmark_click(false)
              setmarkall_click(true)
              let user_ids = []
              alluser_list.forEach(value=>{
                 user_ids.push(value._id)
              }) 
              mark_user = user_ids
            }}></input>
           </div>

            <div className='text-white flex'>
            <label className='text-white  text-sm font-bold' >Mark</label>
            <input type='radio' className=' ml-1' checked={mark_click} onClick={(e)=>{
              setmarkall_click(false)
              setmark_click(true)
              mark_user = []
            }}></input>
            </div>

             <button className='text-white flex bg-blue-500 p-1 rounded-full shadow shadow-white hover:bg-blue-400 active:bg-blue-300' onClick={(e)=>{
              if(mark_user.length <= 0){
               toast.info("select user to contiue",{autoClose:2000})
              }else{
                dispatch(add_user(mark_user))
                navigate("/messageusers")
              }
             }}>
            <label className='text-white  text-sm font-bold' >send Notification</label>
             <img alt='sendicon' src={sendIcon}></img>
            </button>

         </div>

          {
            diplay_users()
          }

         </div>
         }
         
       
        
         

       </div>
       </div>
  )
}
