import React from "react";
import { FaBars } from "react-icons/fa";
import Logo1 from "../../../icons/logo1.jpeg";

const Header = () => {
  return (
    <div className="flex items-center justify-between p-4">
      <div className="flex items-center">
        <div className="bg-primary p-2 rounded-full">
          <img src={Logo1} alt="Logo" className="w-10 h-10" />
        </div>
      </div>
      <FaBars className="text-2xl cursor-pointer" />
    </div>
  );
};

export default Header;
