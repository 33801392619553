import React, {useState, useRef, useEffect} from 'react'
import "../../navs/Navs.css"
import {golds} from "../../data/intresttypes"
import cardIcon from "../../icons/cardIcon.svg"
import goldIcon from "../../icons/icons8.png"
import { useNavigate } from 'react-router-dom'
import { loadStripe } from "@stripe/stripe-js";
import { PAY_API } from '../../data/intresttypes'
import { useSelector } from 'react-redux'
import { sucess_url } from '../../api/config'
import { fail_url } from '../../api/config'
export const Topup = () => {

  const ref = useRef(null)
  const navigate = useNavigate()
  const [currency_value, setcurrency_value] = useState("")
  const token = useSelector(state => state.register.refreshtoken)
  const userid = useSelector(state => state.register.userID)
   const login = useSelector(state => state.register.logedin)

  const show_items = ()=>{
     return golds.map(value =>{
        return <option value={value.value}>
           {value.amount} <br></br> {value.bonus}
        </option>
      })
  }

  useEffect(()=>{
  if(!login){
    navigate("/")
  }
  },[])

  const pay = async()=>{

    if(!currency_value){
      return
    }

    localStorage.setItem("token",token)
    let index = golds.findIndex(value=>{
      return value.value === currency_value
    })
    let amount = golds[index]
    localStorage.setItem("amount",amount.price)
    localStorage.setItem("userid",userid)

    let stripepromise = await loadStripe(PAY_API)
    
    stripepromise.redirectToCheckout({
      lineItems :[{
        price: currency_value,
        quantity:1
      }],
      mode:"payment",
      successUrl:`${sucess_url}`,
      cancelUrl:`${fail_url}`,
    }).then(result=>
      {
        console.log(result)
      })
    
   
  }
  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
      <div className='w-full md:w-2/4'>
      <div className='w-full h-full flex flex-col items-center justify-center pl-1 pr-1 mt-16'>
          <div className='w-full rounded-lg flex flex-col justify-center pl-2 pr-2'>
            <p className='text-center text-slate-600 mb-5 font-bold '>Choose Gold Amount</p>
            <div className='w-full flex justify-center bg-[#292d31] h-fit mb-12 rounded-lg'>
              <img alt='cardIcon' src={cardIcon} className='ml-2'></img>
                <select required className='payment-list bg-[#292d31]' >
                  <option value='' selected disabled hidden>Select Payment Method</option>
                  <option >Credit/Debit Card (Visa/Mastercard/Discover)</option>
           
                </select>
            </div>
           


          <div className='w-full flex justify-center bg-[#292d31] h-fit mb-12 rounded-lg'>
              <img alt='cardIcon' src={goldIcon} className='ml-2 w-7 h-7 object-cover mt-3'></img>
                <select required className='payment-list bg-[#292d31]' onChange={async(e)=>{
                  setcurrency_value(e.currentTarget.value)
                  console.log(currency_value)
                }}>
                <option value='' selected disabled hidden>Choose Gold Amount</option>
                {show_items()}
              </select>
            </div>

           <div className='w-full flex justify-center'>
             <button className='bg-orange-500 h-12 rounded-lg pl-2 pr-2 hover:bg-orange-400 active:bg-orange-300'onClick={(e)=>pay()}>
            <p className='text-white font-bold text-center'>Continue To Payment Page</p>
           </button>

           </div>

          
          </div>

          <p className='text-slate-400 text-xs mt-5 text-center '>In order to serve you better, please take a minute to  give us <button className='text-orange-500 hover:text-orange-400 active:text-orange-300'>feedback</button> </p>
        
        </div>
      </div>
       
        
    </div>
  )
}
