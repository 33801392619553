import React from 'react'
import failedicon from '../icons/failedicon.svg'
import { useNavigate } from 'react-router-dom'
export const Fail = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("userid")
  const amount = localStorage.getItem("amount")
  const navigate = useNavigate()
    return (
        <div className='sm:flex  sm:justify-center sm:w-1/2 sm:ml-16 sm:rounded-xl sm:mb-0 mb-8 relative'>
    
        <div className='text-black mx-auto text-center bg-black my-auto
        border-0 flex flex-col
        '>
             <div className='flex justify-center mt-16'>
            <p className='text-red-500 text-xl font-bold text-center mt-2'>{amount} Gold Deposit Failure</p>
    
             <img
               alt='messageicon'
               src={failedicon}
               className='w-10 h-10 object-contain'
            >
            </img>
    
            </div>
    
         
            <div className='mt-4 px-3'>
    
                 
                 <button className='btn w-full mt-10 h-8 mb-5'
                 onClick={()=>{
                  navigate("/topup")
                   
                 }}
                 >
                  Continue
                 </button>
    
               
    
            </div>
        </div>
    
        </div>
      )
}
