import React, { useEffect, useState } from "react";
import dodo from "../../icons/person.svg";
import onlineIcon from "../../icons/onlineIcon.svg";
import offlineIcon from "../../icons/offlineIcon.svg";
import femaleicon from "../../icons/femaleIcon.svg";
import maleIcon from "../../icons/maleIcon.svg";
import transIcon from "../../icons/transIcon.svg";
import viewIcon from "../../icons/clicksIcon.svg";
import { downloadImage } from "../../api/sendImage";
import { interst } from "../../data/intresttypes";
import { useNavigate } from "react-router-dom";
import { Bookinginfo } from "../../fragment/bookingFrag/Bookinginfo";
import { Bookingsuccess } from "../../fragment/bookingFrag/Bookingsuccess";
import { getCountryData } from "../../api/getCountries";

export const ListofModels = ({
  photolink,
  hosttype,
  online,
  name,
  age,
  gender,
  location,
  interest,
  amount,
  modelid,
  userid,
}) => {
  const navigate = useNavigate();
  const [hostimg, sethostimg] = useState(dodo);
  const [modelID, setmodelID] = useState([modelid, userid]);
  const [countryData, setCountryData] = useState({
    flag: "",
    abbreviation: "",
    fifa: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCountryData(location);
      if (data) setCountryData(data);
    };
    fetchData();
  }, []);

  const fetchgender = () => {
    if (gender === "Man") {
      return (
        <img
          alt="maleIcon"
          src={maleIcon}
          className="object-contain h-5 w-5"
        ></img>
      );
    } else if (gender === "Woman") {
      return (
        <img
          alt="femaleIcon"
          src={femaleicon}
          className="object-contain h-5 w-5"
        ></img>
      );
    } else {
      return (
        <img
          alt="transIcon"
          src={transIcon}
          className="object-contain h-5 w-5"
        ></img>
      );
    }
  };

  useEffect(() => {
    if (photolink) {
      let imglink = photolink.split(",");
      sethostimg(downloadImage(imglink[0], "model"));
    }
    getCountryData(countryData);
  }, []);

  return (
    <li
      className="overflow-hidden"
      onClick={() => {
        navigate(`/modelbyid/${modelID.toString()}`);
      }}
    >
      <div className="relative">
        <div>
          <img
            alt="verified"
            src={hostimg}
            className="rounded h-80 w-full object-cover"
          />
        </div>

        <div className="absolute top-0 m-1 w-6 h-6 ">
          <img
            alt={online ? "online" : "offline"}
            src={online ? onlineIcon : offlineIcon}
            className={`object-cover rounded-full w-5 h-5 ${
              online ? "bg-[#d3f6e0]" : "bg-[#ffd8d9]"
            }`}
          />
        </div>

        <div className="absolute bottom-1 ">
          <div className="flex flex-row gap-1 items-center px-1 overflow-auto sm:gap-2">
            <div className="flex items-center p-1 gap-1 bg-black bg-opacity-40 rounded-lg overflow-auto">
              <img
                src={countryData.flag}
                alt={`${countryData.abbreviation} flag`}
                className="w-3 h-3 object-cover rounded-full"
              />
              <span className="text-white text-xs ">{countryData.fifa}</span>
            </div>
            <h4 className="text-xs bg-black bg-opacity-40 rounded-lg p-1 whitespace-nowrap">
              {hosttype}
            </h4>
            <h4 className="text-xs bg-black bg-opacity-50 rounded-lg p-1 overflow-auto">
              {name.slice(0, 4)}
            </h4>
          </div>
        </div>
      </div>
    </li>
  );
};
