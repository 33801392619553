import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";
export const Converterate = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Fans" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">Conversion Rates :</h4>
            
          </header>
         
         <table className="bg-green-500 border border-solid border-black">
            <tr className="font-bold border border-solid border-black">
                <th className="border border-solid border-black">Golds </th>
                <th className="border border-solid border-black">Conversion Rate </th>
                <th className="border border-solid border-black">Total Payout </th>
            </tr>

            <tr className="border border-solid border-black">
                <td className="border border-solid border-black">100 </td>
                <td className="border-solid border-black">.05</td>
                <td className="border border-solid border-black">$5.00</td>
            </tr>

            <tr className="border border-solid border-black">
                <td className="border border-solid border-black">1000 </td>
                <td className="border-solid border-black">.05</td>
                <td className="border border-solid border-black">$50.00</td>
            </tr>

            <tr className="border border-solid border-black">
                <td className="border border-solid border-black">10,000 </td>
                <td className="border-solid border-black">.05</td>
                <td className="border border-solid border-black">$500.00</td>
            </tr>

            <tr className="border border-solid border-black">
                <td className="border border-solid border-black">100,000 </td>
                <td className="border-solid border-black">.05</td>
                <td className="border border-solid border-black">$5,000.00</td>
            </tr>
         </table>

          </div>
      </div>
     
    </div>
  );
  
};