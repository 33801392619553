import { configureStore } from "@reduxjs/toolkit";
import registerReducer from "./features/register/registerSlice";
import profileReducer from './features/profile/profile'
import comprofileReducer from "./features/profile/comprofile";
import postReducer from './features/post/post'
import commentReducer from "./features/comment/comment";
import likeReducer from './features/like/like'
import modelSlice from "./features/model/modelSlice";
import messageSlice from "./features/message/messageSlice";
import bookingSlice from "./features/booking/booking";
import adminSlice from "./features/admin/admin"


export default configureStore(
    {
        reducer :{
            register:registerReducer,
            profile:profileReducer,
            comprofile:comprofileReducer,
            post:postReducer,
            comment:commentReducer,
            like:likeReducer,
            model:modelSlice,
            message:messageSlice,
            booking:bookingSlice,
            admin:adminSlice
        }
    }
)