import React,{useState, useEffect} from 'react'
import profileIcon from "../../icons/icons8-profile_Icon.png"
import femaleIcon from "../../icons/femaleIcon.svg"
import maleIcon from "../../icons/maleIcon.svg"
import optionIcon from "../../icons/navmenu.svg"
import deleteIcon from "../../icons/deleteicon.svg"
import waitIcon from "../../icons/hourglassIcon.svg"
import cancelIcon from "../../icons/closeIcon.svg"
import { downloadImage } from '../../api/sendImage'
import PacmanLoader from "react-spinners/RingLoader";
import { useSelector, useDispatch } from 'react-redux'
import { deleteuser, deleteuser_photo, remove_user, reset_alluser, suspend_user } from '../../app/features/admin/admin'
import { getalluser } from '../../app/features/admin/admin'

export const List_of_users = ({mark, markall,firstname, lastname, photolink,gender,country,id, mark_user}) => {

  const token = useSelector(state => state.register.refreshtoken)
  const userphotos_list = useSelector(state => state.admin.userphotos_list)
  const deleteuser_stats = useSelector(state => state.admin.deleteuser_stats)
  const deleteuser_message = useSelector(state => state.admin.deleteuser_message)
  const suspenduser_message = useSelector(state => state.admin.suspenduser_message)
  const suspenduser_stats = useSelector(state => state.admin.suspenduser_stats)

   const delete_photo_stats = useSelector(state => state.admin.delete_photo_stats)
   const delete_photo_message = useSelector(state => state.admin.delete_photo_message)
   const dispatch = useDispatch()



   const [option_click, setoption_click] = useState(false)
   const [suspend_click, setsuspend_click] = useState(false)
   let suspend_date = ""

   const [showmark, setshowmark] = useState(false)
   const [tick , settick] = useState(false)
   const [gender_type, setgender_type] = useState(maleIcon)
   const [proIMg, setproIMG] = useState(profileIcon)
   let [loading, setLoading] = useState(false);
   let [color, setColor] = useState("#f54242"); 
   const [status, setstatus] =  useState("")

   useEffect(()=>{
    if(mark === true){
      setshowmark(true)
      settick(false)
    }

    if(markall === true){
       setshowmark(true)
       settick(true)
    }
   },[mark,markall])

   useEffect(()=>{

    check_gender()
    checkphoto()

   },[])

   useEffect(()=>{
   if(deleteuser_stats === "succeeded"){
    dispatch(reset_alluser())
    if(delete_photo_stats !== "loading"){
      setLoading(false)
      dispatch(
        getalluser({token})
      )
    }

   }

   if(deleteuser_stats === "failed"){
    setLoading(false)

    
   }

   },[deleteuser_stats])

  

   useEffect(()=>{
    if(suspenduser_stats === "succeeded"){
      setLoading(false)
      dispatch(remove_user(id))
      dispatch(reset_alluser())
    }

    if(suspenduser_stats === "failed"){
      setLoading(false)
      console.log(suspenduser_message)
      
    }
   },[suspenduser_stats])

   const checkphoto = ()=>{
    if(photolink){
      setproIMG(downloadImage(photolink,"profile"))
    }
   }

   const check_gender = ()=>{
    if(gender === "MALE"){
       setgender_type(maleIcon)
    }else if(gender === "FEMALE"){
        setgender_type(femaleIcon)
    }
   }

   const deleteButton = ()=>{
     if(deleteuser_stats !== "loading"){
       setLoading(true)
       setoption_click(false)
       setstatus("deleting user..")

       dispatch(
         deleteuser({userid:id, token})
       )
     }
   }

   const three_day = (Days)=>{
      let day = parseInt(Days)
      let date = Date.now()
      let first_date = new Date(Number(date))
      let enddate = new Date( first_date.setDate(first_date.getDate() + Number(day) ) )

      return enddate
    }

    const suspendButton = ()=>{
      if(suspend_date){
        if(suspenduser_stats !== "loading"){
          setLoading(true)
          setoption_click(false)
          setstatus("suspending user..")
          let end_date = three_day(suspend_date).getTime()

          dispatch(suspend_user({token,end_date,userid:id}))
        }
      }
    }


  return (
    <li className='flex pl-1 pr-1 rounded-lg bg-slate-400 w-full mt-2 justify-between'>

      <div>
           <div className='flex '>
        <img alt='profileIcon' src={proIMg} className='w-7 h-7 rounded-full'></img>
        <p className='text-white ml-1 text-sm font-bold'>{`${firstname} ${lastname}`}</p>

        </div>

       <div className='flex '>
         <p className='text-white ml-1 text-sm font-bold mt-1'>Gender</p>
        <img alt='maleIcon' src={gender_type} className='w-7 h-7 rounded-full'></img>
       

       </div>

       <div className='flex '>
         <p className='text-white ml-1 text-sm font-bold mt-1'>Loaction:</p>
         <p className='text-white ml-1 text-sm font-bold mt-1'>{country}</p>
        </div>
      </div>

      {
        loading && 
            <div className='flex flex-col items-center mt-8 w-full'>
              <PacmanLoader
              color={color}
              loading={loading}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
              margin={ "auto"}/>
              <p className='text-xs'>{status}..</p>
            </div>
      }



      <div className='my-auto flex flex-col'>
        <div>
           {
            option_click &&
            <div className='w-fit h-fit p-2 bg-indigo-500 absolute z-10  right-1/4 overflow-auto rounded-lg shadow shadow-white'>
           <button className='flex bg-red-950 rounded-lg p-1 shadow shadow-black mb-2' onClick={(e)=>{
             deleteButton()
           }}>
            <img alt ='deleteIcon' src={deleteIcon} className='w-5 h-5 object-cover'></img>
            <p className='font-bold text-xs text-white'>delete account</p>

           </button>

            <button className='flex bg-orange-500 rounded-lg p-1 shadow shadow-black' onClick={(e)=>setsuspend_click(true)}>
            <img alt='waitIcon' src={waitIcon} className='w-5 h-5 object-cover'></img>
            <p className='font-bold text-xs text-white'>suspend account</p>

           </button>

           {
            suspend_click &&
            <div className='w-full h-fit flex flex-col mt-1 bg-yellow-200 rounded-md'>
            <div className='w-full flex justify-end'>
               
               <button onClick={(e)=>setsuspend_click(false)}>
                   <img alt='cancelIcon' src={cancelIcon} className='w-5 h-5 object-cover'></img>

               </button>
            </div>
              <select required className='payment-list mt-1 rounded-lg border border-black' onChange={(e)=>{
             
                suspend_date = e.currentTarget.value
              }}>
                <option value='' selected disabled hidden>Choose time</option>
                 <option value='7'>1 week</option>
                 <option value='28'>1 month</option>
                  <option value='84'>3 months</option>
                  <option value='336'>12 months</option>

               
              </select>

              <button className='bg-blue-500 mt-1 rounded-lg text-xs font-bold ml-1 mr-1 text-white mb-2' onClick={(e)=>{
                suspendButton()
              }}>suspend</button>
           </div>
           }

           

          </div>
          }


         <button onClick={(e)=>setoption_click(!option_click)}>
            <img alt='optionIcon' src={optionIcon}></img>
         </button>

        </div>

        {
          showmark &&
           <input type='radio' checked={tick} onClick={(e)=>{
            settick(true)
           
             let index = mark_user.findIndex(value => value === id)
             if(index !== -1){

             }else{
              mark_user.push(id)
             }
            

            console.log("number of mark user "+mark_user.length)
           }}></input>
        }

       

        
      </div>


   
        
    </li>
  )
}
