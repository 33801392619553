import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  loginuser,
  forgetpass,
  changelogin,
} from "../../app/features/register/registerSlice";
import "react-toastify/dist/ReactToastify.css";

export const Loginview = ({ close }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux state selectors
  const { message, logstats: stats, forgetpassstate, errs } = useSelector(
    (state) => state.register
  );

  // Component state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleAlert = (msg, type, autoClose = true) =>
    toast(msg, { type, autoClose });

  const handleSubmit = async () => {
    if (!acceptedTerms) {
      handleAlert("You must accept the terms and conditions to continue.", "error");
      return;
    }

    if (!email) {
      handleAlert("Enter Email", "error");
      return;
    }

    if (!password) {
      handleAlert("Enter Password", "error");
      return;
    }

    if (stats !== "loading") {
      handleAlert("Logging in...", "info", false);
      await dispatch(loginuser({ email, password }));
    }
  };

  const handleForgetPassword = async () => {
    if (!email) {
      handleAlert("Enter your registered email to continue", "error");
      return;
    }
    if (forgetpassstate !== "loading") {
      handleAlert("Processing request...", "info", false);
      await dispatch(forgetpass({ email }));
    }
  };

  
  useEffect(() => {
    if (stats === "succeeded") {
      handleAlert(message, "success");
      dispatch(changelogin({ logstats: "idle", message: "" }));
      close();
    } else if (stats === "failed") {
      handleAlert(errs, "error");
      dispatch(changelogin({ logstats: "idle", message: "" }));
    }
  }, [stats, dispatch]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white px-4">
      <ToastContainer position="top-center" theme="dark" />
      <div className="bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md">
        <h1 className="text-orange-500 text-3xl font-bold text-center">
          Welcome Back
        </h1>
        <p className="text-gray-400 text-center mt-2">
          Log in to access your account
        </p>

        <div className="mt-6 space-y-4">
          <input
            type="email"
            className="w-full px-4 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="password"
            className="w-full px-4 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div className="flex items-start mt-4">
            <input
              type="checkbox"
              id="terms"
              className="mr-2"
              checked={acceptedTerms}
              onChange={(e) => setAcceptedTerms(e.target.checked)}
            />
            <label htmlFor="terms" className="text-gray-400 text-sm">
              I accept the{" "}
              <span
                className="text-orange-500 underline cursor-pointer"
                onClick={() => alert("Display Terms and Conditions here")}
              >
                Terms and Conditions
              </span>
            </label>
          </div>

          <button
            className="w-full bg-orange-500 hover:bg-orange-600 text-white py-2 rounded shadow transition"
            onClick={handleSubmit}
          >
            Log In
          </button>

          <p
            className="text-blue-500 text-sm text-center hover:text-blue-400 cursor-pointer"
            onClick={handleForgetPassword}
          >
            Forgot Password?
          </p>

          <p className="text-gray-400 text-sm text-center mt-4">
            Don't have an account?{" "}
            <span
              className="text-orange-500 font-bold hover:underline cursor-pointer"
              onClick={() => {
                navigate("/register");
                close();
              }}
            >
              Register
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
