import React from "react";
import Breadcrumbs from "./components/breadcrumb";
import TitleSection from "./components/titleSection";
import ArticleList from "./components/articlelist";
import SearchBar from "./components/searchbar";
import Header from "./components/header";
import { FaGraduationCap } from "react-icons/fa";
import { TawkToIntegration } from "../../api/tawk";

export const FansPage = () => {
    const articles = [
        { title:"How to purchase Golds?" ,ref:"/Purchasegold"},
         {title :"How do Private show work?",ref:"/Howdoprivate"},
         {title:"How do Fan meet or Fan date work?",ref:"/Datemeetandfanwork"},
         {title:"Can I get a refund?",ref:"/Refound"},
         
       ];

       return (
        <div className="bg-black min-h-screen text-white ">
          <Header />
          <SearchBar />
    
          <div className="p-4">
            <Breadcrumbs title="Fans" />
            <TitleSection
              title="Fans"
              article="4 articles"
              icon={<FaGraduationCap color="" size={30} />}
            />
    
              <ArticleList articles={articles} />
            
          </div>
        </div>
      );

}