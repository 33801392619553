import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";
export const Outdoorcontent = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">Outdoor Content</h4>
            
          </header>
         
         <p>
         Mmeko welcomes all models/creators and tries to be as inclusive as possible of their content styles. Due to 
         regulations, laws and restrictions, there are some styles of content we cannot allow and are classed as 
         prohibited.
            
         </p>

         <p className="mt-1">
          Do not upload, post, or share content that in the case of Content featuring public nudity, was recorded in or 
          is being broadcast from a country, State or province where public nudity is illegal.
         </p>

         <p className="mt-1">
          Do not upload, post, or share content that in the case of Content featuring sexual activities, was recorded in 
          or is being broadcast from a public place where members of the public are reasonably likely to see the 
          activities being performed (this does not include outdoor places where members of the public are not present, 
          for example, private property such as a private backyard, or secluded areas in nature where members of the 
          public are not present).
         </p>

       

          </div>
      </div>
     
    </div>
  );
  
};