import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";
export const ReceivePayout = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">How to receive a payout on Mmeko ?</h4>
            
          </header>
         
         <p>
          Withdrawing your money is quick and easy on Mmeko, whether this is your first time withdrawing or you’ve been 
          in the game for a while! 
            
         </p>

         <p className="pt-2">
          To request a withdrawal for the first time, please complete the following steps:
            <ul className="list-disc list-inside mt-2 text-gray-300">
              <li>Make sure you have a minimum of $50 in your Available Balance.</li>
              <li>From the Gold stat page, hit 'Add a payout method'.</li>
              <li>Click 'Set up' on your preferred payout method.</li>
              <li>click withdraw!</li>
            </ul>
          </p>

          <p className="font-semibold">
          <span className="text-blue-500 font-bold">&#9757;</span>Note: First-time withdraws are subject to a routine fraud check, so the wait may be slightly longer than usual<br></br>
          Your request will then be immediately processed and sent to your chosen payout method within 3-7 business days, depending on your chosen payout method!
          </p>

          </div>
      </div>
     
    </div>
  );
  
};