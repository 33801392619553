import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";
export const Explicitcontent = () => {
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
    <Header />
    <div className="p-4">
      <Breadcrumbs title="About" />
      <div>
        <header className="my-4">
          <h4 className="font-bold  text-2xl">Do Mmeko allow explicit content ?</h4>
          
        </header>

        <p>
         This platform allows adult-themed content, but we don't allow sharing explicit or graphic material.
         We strive to maintain a respectful and safe environment for all users.
        </p>
        
        
        </div>
    </div>
  </div>
  )
}