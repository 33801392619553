import React, { useEffect, useState } from "react";
import { Mainpost } from "../homeviews/Mainpost";
import { Post } from "./Post";
import { useSelector, useDispatch } from "react-redux";
import { Mainheaderbar } from "../../navs/Mainheaderbar";
import { Model } from "../modelviews/Model";
import { useCall } from "../messageview/context";
import { set_calling } from "../../app/features/message/messageSlice";
import { useNavigate } from "react-router-dom";
import { socket } from "../../api/config";

let data = {};

export const Main = () => {
  const login = useSelector((state) => state.register.logedin);
  const video_call_datas = useSelector(
    (state) => state.message.video_call_data
  );
  const [isOpen, setIsOpen] = useState(false);
  const [modelMenu, setModelMenu] = useState(false);
  const [genderSearchQuery, setGenderSearchQuery] = useState("");
  const [click,setclick] = useState(true)

  const handleGenderSearchQuery = (value) => {
    setGenderSearchQuery(value);
  };

  const calling = useSelector((state) => state.message.calling);
  const video_call_data = useSelector(
    (state) => state.message.video_call_message
  );

  const callInfo = useSelector((state) => state.booking.privatecallData);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toggletab = () => {
    setIsOpen(!isOpen);
    setModelMenu(!modelMenu);
  };

  const {
    triggerCall,
    acceptCall,
    declineCall,
    setcancel,
    cancel,
    setaccept,
    accept,
    setcallername,
  } = useCall();

  const simulateIncomingCall = () => {
    // Simulate a call
    triggerCall(calling);
    if (video_call_data) {
      setcallername(video_call_data);
      // console.log("video call sata "+video_call_data)
    }
  };

  useEffect(() => {
    console.log("accpt call button " + accept);
    console.log("calling call button " + calling);
    simulateIncomingCall();
    if (accept === true) {
      dispatch(set_calling(false));
      setaccept(false);
      if(callInfo.length > 0){
        
        navigate(`/privatecall`);
        return
        
      }else{
        let call = ["answer"];
        navigate(`/videocall/${call.toString()}`);
        return
      }
     
    }

    if (cancel === true) {
      if (video_call_datas) {
        data.answer_id = video_call_datas.answer_id;
        data.caller_id = video_call_datas.caller_id;
        data.my_id = video_call_datas.answer_id;
        data.name = "";
        data.message = "";
        data.offer_can = "";
        data.answer_can = "";
        data.sdp_c_offer = "";
        data.sdp_a_offer = "";
        data.answer_message = "reject";
      }

      if(callInfo.length > 0 ){
        data.answer_id = callInfo[1];
        data.caller_id = callInfo[0];
        data.my_id = callInfo[2];
        data.name = "";
        data.message = "";
        data.offer_can = "";
        data.answer_can = "";
        data.sdp_c_offer = "";
        data.sdp_a_offer = "";
        data.answer_message = "reject";
        socket.emit("privatecall", data);
      dispatch(set_calling(false));
      setcancel(false);

      }else{

      socket.emit("videocall", data);
      dispatch(set_calling(false));
      setcancel(false);
      }

      
    }

    
  }, [accept, cancel, calling]);

  

 
  return (
    <div className="bg-black sm:w-4/6 mr-2 mb-10 "  >
      <Mainheaderbar
        isOpen={isOpen}
        modelMenu={modelMenu}
        handleToggleTab={toggletab}
        login={login}
        handleGenderSearchQuery={handleGenderSearchQuery}
        click={click}
        setclick={setclick}
      />

      {calling && simulateIncomingCall()}
      {!isOpen ? (
        <div className="px-4  " onClick={(e)=>{setclick(true) }}>
          {login && <Mainpost />}
          <div className="md:w-full md:flex md:flex-col md:items-center">
            <div className='md:w-4/5'>
            <Post />
            </div>

          </div>

        </div>
      ) : (
        <Model genderSearchQuery={genderSearchQuery}/>
      )}
    </div>
  );
};
