import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Modelaccount = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">Steps to create a model account :</h4>
            
          </header>
         
          <p>
            At Mmeko, we strive to provide models, creators and fans with the
            best experiences possible. We have a team of passionate and
            dedicated people who are committed to building and evolving a
            cutting-edge platform for all creators and all fans. Our core values
            include:
            <ol className="list-decimal list-inside mt-2 text-gray-300">
              <li> Navigate to <a href="/register" ><span className="text-blue-500 font-semibold">www.Mmeko.com and click</span></a> “Register” found below.</li>
              <li> Register using the email address and password you want for your Model account</li>
              <li> Once you've created a Fan account, click "Model Application" to start your modeling journey.</li>
              <li> Now it’s time to complete your official account set up! Follow the prompt and ensure all steps are completed for your account to be approved. </li>
              <li>Honesty & Transparency</li>
            </ol>
          </p>
          <p className="text-gray-300">
             <span className="font-semibold text-blue-500">&#9757;</span>Note: You will need a form of ID and a high-quality selfie (even if you won't be showing your face on your 
             Mmeko account, this is needed for KYC purposes). Rest assured, this information will remain fully confidential 
             and isn’t posted on your public profile. 
          </p>
           <ol className="list-decimal list-inside text-gray-300" start={5}>
             <li>Once your model Application is approved, Navigate to the menubar, Click "Model Portfolio" and create a new model.</li>
             <li>Finalize your account details, Choose category you wish to offer your fans and complete your profile. </li>
            </ol>

            <p className="text-gray-300 mt-2">
             <span className="font-semibold text-blue-500">&#9757;</span>We recommend fully completing your profile before promoting your Mmeko account on your socials. 
             This includes adding a profile, banner picture, and posting at least 10 pieces of content.
            </p>

            <p className="text-gray-300 mt-2">
             Once your profile has been set up and you’ve begun promoting your account, it is time to start 
             earning. At Mmeko your earnings are unlimited! You have complete control and take home 70% of your earnings. 
             There are creators on the platform making hundreds of thousands each month - the sky's the limit! 🚀
            </p>
          
         
        
       
        
        
          </div>
      </div>
     
    </div>
  );
  
};