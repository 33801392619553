import { getGeo } from "./getGeo";
import {fetchLocation} from './getLocation'

export const userlocation = async()=>{
 let geo = getGeo();

 if (!geo.err){
  let succeeded = await fetchLocation(geo.latitude,geo.longtitude);
  if(!succeeded.err){
    return {
        success:"pass",
        state:succeeded.state,
        country:succeeded.country

    }
  }else{
    return {
        err:geo.err,
        success:"failed"
    }
  }
 }else{
   return {
        err:geo.err,
        success:"failed"
    }
  
 }
}