import React from "react";
import { toast, ToastContainer } from "react-toastify";

export function useToast() {
  const toastId = React.useRef(null);

  const successalert = (message, type = "info", close = 5000) => {
    const validTypes = ["info", "success", "warning", "error"];
    if (!validTypes.includes(type)) {
      console.error(`Invalid toast type: ${type}`);
      return;
    }

    toastId.current = toast(message, {
      type,
      autoClose: close,
    });
  };

  const dismissalert = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
    } else {
      console.warn("No active toast to dismiss");
    }
  };

  return { successalert, dismissalert };
}
