import React,{useState} from 'react'
import { Crushlist } from './collectionList/Crushlist'
import { useSelector } from 'react-redux'

export const Crush = () => {

const listofcrush = useSelector((state) => state.profile.listofcrush);

let showcontent = ()=>{

    if(listofcrush.length > 0){
      
        return(
            <div className="grid grid-cols-2 gap-2 mb-3 p-2 ">
                {
                    listofcrush.map((value,index)=>{
                        return <Crushlist photolink={value.photolink} name={value.name} id={value.id} userid={value.userid} hosttype={value.hosttype} modelid={value.modelid} location={value.location} online={value.online} key={index}/>
                    })
                }

            </div>
        )
    }else{
        return <div className='w-full'>

               <p className='mt-16 text-yellow-200 text-xs w-full text-center'>No crush on your crush list</p>

               </div> 
    }

}
  return (
    <div className='w-full flex flex-col'>
        <p className='text-slate-50 text-center font-bold mt-2'>List Of My Crush Models</p>

        {showcontent()}
        
        
    </div>
  )
}
