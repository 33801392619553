const Checklist = () => {
  const rules = [
    "Make sure all information is correct.",
    "Ensure your document is valid and not expired.",
    "Photo must be clear and in color.",
    "ID must be fully in frame.",
  ];

  return (
    <div className="mb-4">
      <h4 className="font-bold text-md">Checklist</h4>
      <ul className="list-disc list-inside text-gray-400">
        {rules.map((rule, index) => (
          <li key={index} className="text-sm">
            {rule}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Checklist;
