import React, { useState, useEffect } from "react";
import dodo from "../../../icons/icons8-profile_Icon.png";
import { downloadImage } from "../../../api/sendImage";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import onlineIcon from "../../../icons/onlineIcon.svg";
import offlineIcon from "../../../icons/offlineIcon.svg";
import { format, isToday } from "date-fns";

export const
  RecentList = ({
  photolink,
  username,
  content,
  toid,
  fromid,
  date,
  online,
}) => {
  const [userphoto, setuserphoto] = useState(dodo);
  const [modelid, setmodelid] = useState([]);
  const navigate = useNavigate();
  let myid = useSelector((state) => state.register.userID);
  let taken = false;
  // let date1 = new Date(Number(date)).toString();
  // const dates = format(date1, "MM/dd/yyyy 'at' h:mm a");


  let date1 = new Date(Number(date)).toString(); 
  const dates = isToday(date1) ? format(date1, "h:mm a") : format(date1, "MM/dd/yyyy");

  useEffect(() => {
   
  
    if (fromid === myid) {
      setmodelid([toid, fromid]);
     
    }


 
    if (toid === myid) {
      setmodelid([fromid, toid]);
    }

   
      if (photolink) {
        let photo1 = downloadImage(photolink, "profile");

        //console.log('Good Photo '+photo1)
        setuserphoto(photo1);
      }
    

    //console.log('modelid'+modelid)
  }, []);

  const sliceContent = () => {
    // console.log("here is model "+modelid)
    if (content) {
      return content.slice(0, 10) + `...`;
    }
  };

  return (
    // <li
    //   className="flex justify-between items-center rounded-lg px-4 py-3 sm:px-2 mx-4  bg-slate-800"
    //   onClick={(e) => {
    //     navigate(`/message/${modelid.toString()}`);
    //   }}
    // >
    //   <div className="flex ml-3">
    //     <img alt="userimg" src={photo} className="w-5 h-5 rounded-full"></img>
    //     <p className="text-white ml-1 text-sm font-semibold">{name}</p>
    //   </div>
    //   <div className="text-black ml-3 mt-3 text-sm">
    //     <p>{sliceContent()}</p>
    //   </div>
    // </li>

    <li className="mb-1"
      onClick={(e) => {
        // removenotification(date);
        navigate(`/message/${modelid.toString()}`);
      }}
      
    >
      <div className="flex justify-between items-center rounded-lg px-4 py-3 sm:px-2 mx-2  bg-slate-800 ">
        <div className="flex items-center gap-4 ">
          <div className="relative w-12 h-12">
            <img
              src={userphoto}
              alt="message-image"
              className="w-full h-full object-cover rounded-full"
            />
            <div className="absolute top-6 left-6 m-1 w-6 h-6 z-10">
              <img
                alt={online ? "online" : "offline"}
                src={online ? onlineIcon : offlineIcon}
                className={`object-cover rounded-full w-5 h-5 ${
                  online ? "bg-[#d3f6e0]" : "bg-[#ffd8d9]"
                }`}
              />
            </div>
          </div>

          <div>
            <h4 className="text-slate-300 text-md sm:text-sm font-semibold">
              {username.split(" ")[0]}
            </h4>

            <p className="text-slate-400 text-sm sm:text-xs">
              {sliceContent()}
            </p>
          </div>
        </div>

        <div>
          <h4 className="text-slate-400 text-sm">{dates}</h4>
        </div>
      </div>
    </li>
  );
};
