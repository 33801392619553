import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Whathappen = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">What happens if the fan refuse to mark as completed after the Fan meet or Fan date is completed?</h4>
            
          </header>
         
         <p>
          You immediately have to contact mmeko within 7 days and send us the evidence that the fan meet or fan date 
          occurred, Evidence can be in messages, photo or video, So we strongly advice you use our platform to 
          communicate to your fans.
            
         </p>

         <h6 className="font-semibold text-gray-300 mt-1">What if i wasn't able to contact Mmeko after 7 days??</h6>

         <p>
          After 7 days, We can't guarantee for you to get your tip.
         </p>
          </div>
      </div>
     
    </div>
  );
  
};