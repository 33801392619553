import React,{useState, useEffect} from 'react'


export const Bookinginfo = ({setbookclick,amount,setsuccess,type}) => {

 //let  type =  "Private show"
 const chooseinfo = ()=>{
   
  if(type){
    console.log("type is is "+type)
    if(type === "fan Meet"){
   
      return "will be deducted from your balance"
    }
    if(type === "Fan date"){
      
      return "will be deducted from your balance"
    }
     if(type === "Private show"){
      
      return "will be deducted per minute during the Private show"
    }
  }else{
    return "nothing"
  }
 
}
  
  

  return (
    <fieldset className='w-48 h-fit bg-slate-200 mx-auto p-5 rounded-lg z-50' >

        <p className='text-center pt-2 text-sm text-black'>The Amount of  <span className='font-semibold text-yellow-500'>{parseFloat(amount)}</span> coin {`${chooseinfo()}`}</p>

        <div className='flex justify-between'>
            <button className='bg-green-500 mr-1 text-sm p-2 ml-1 mt-3 rounded-lg w-1/2 shadow-2xl' onClick={(e)=>{
             
              setsuccess(true)
              setbookclick(false)
            
            }}>
                Contiue
            </button>

             <button className='bg-red-500 ml-1 text-sm p-2 mr-1 mt-3 rounded-lg w-1/2 shadow-2xl' onClick={(e)=>setbookclick(false)}>
                Cancel
            </button>
        </div>
       
    </fieldset>
  )
}