import React, { useState , useEffect} from "react";
import { Logins } from "../auth/Logins";
import { Profilenav } from "../navs/Profilenav";
import { Sidemenu } from "./Sidemenu";
import "../navs/Navs.css";
import { useSelector, useDispatch } from "react-redux";
import Homelogo from "../icons/logo.jpeg";
import { ModelSideMenu } from "./ModelSideMenu";
import { useNavigate } from "react-router-dom";


let data = {};

export const Gennavigation = ({
  isOpen,
  modelMenu,
  handleGenderSearchQuery,
  stoptop,
  click,
  setclick
}) => {
  const login = useSelector((state) => state.register.logedin);
  const [open, setOpen] = useState(false);
  const [modelToggle, setModelToggle] = useState(false);
 

 

 
  const handleMenubar = () => setOpen(!open);
  const handleModelToggle = () => setModelToggle(!modelToggle);

  useEffect(()=>{
    if(click){
     setOpen(false)
      setclick(false)
    }
  })
  return (
    <div className="bg-black sticky top-0 z-50">
      <div className="flex flex-row justify-between items-center mb-4 md:w-full bg-black z-50 px-4 ">
        {stoptop && (<div className="pt-2">
          <a href="/">
            <img
              src={Homelogo}
              alt="logo"
              className="w-20 h-8 sm:w-20 sm:h-20 lg:w-40 lg:h-8"
            />
          </a>
        </div>)}

       

        <div className="flex flex-nowrap flex-shrink-0 flex-row genav ">
          {!login && <Logins />}
          {login && (
            <Profilenav
              handleMenubar={handleMenubar}
              isOpen={isOpen}
              modelMenu={modelMenu}
              handleModelToggle={handleModelToggle}
            />
          )}
          {login && <Sidemenu open={open} handleMenubar={handleMenubar} />}
          {login && (
            <ModelSideMenu
              open={modelToggle}
              handleMenubar={handleModelToggle}
              handleGenderSearchQuery={handleGenderSearchQuery}
            />
          )}
        </div>
      </div>
    </div>
  );
};
