import React from "react";
import Header from "./components/header";
import SearchBar from "./components/searchbar";
import CategoryCard from "./components/categorycard";
import { FaGraduationCap, FaStar, FaUser, FaFolderOpen } from "react-icons/fa";
import { GiGoldBar } from "react-icons/gi";
import { TawkToIntegration } from "../../api/tawk";
import cusmericon from "../../icons/cusmerchat.png"
import { useNavigate } from "react-router-dom";

export const Really_Help_Support_Page = () => {

  const navigate = useNavigate()
  const categories = [
    {
      icon: <FaGraduationCap />,
      title: "About Mmeko",
      articles: 6,
      link: "about",
    },
    { icon: <FaStar />, title: "For Creators", articles: 11, link: "creator" },
    { icon: <FaUser />, title: "For Fans", articles: 4, link: "fans" },
    {
      icon: <GiGoldBar />,
      title: "Golds",
      articles: 2,
      link: "gold",
    },
   
  ];

  return (
    <div className="bg-black min-h-screen text-white min-w-screen md:w-screen md:pr-16">
      {/* <Header /> */}
      <div className="p-2 mt-16">
        <h1 className="ml-4 text-lg font-bold mb-4 text-center ">
          HOW CAN WE HELP?
        </h1>
        <SearchBar />


        <div className="mt-6 space-y-4 pr-4">
          {categories.map((category, index) => (
            <a href={`/${category.link}`} className="my-2">
              <CategoryCard key={index} {...category} />
            </a>
          ))}
        </div>
       
          <button className="w-12 h-12 bg-white rounded-full shadow shadow-gray-300 absolute bottom-2 z-40 right-4 md:right-16"
          onClick={(e)=>navigate("/speaker")}
          >
           <img alt="customerimg" src={cusmericon} className="rounded-full w-12 h-12 object-cover"></img>
          </button>
       
      </div>
    </div>
  );
};
