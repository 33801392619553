import React from "react";
import Breadcrumbs from "./components/breadcrumb";
import TitleSection from "./components/titleSection";
import ArticleList from "./components/articlelist";
import SearchBar from "./components/searchbar";
import Header from "./components/header";
import { FaGraduationCap } from "react-icons/fa";


export const Creators = () => {
  const articles = [
   { title:"Becoming a model" ,ref:"/Becomeamodel"},
    {title :"Steps to create a model account",ref:"/Modelaccount"},
    {title:"steps to make your first $10k on Mmeko",ref:"/Makeyourfirst"},
    {title:"How does Private show work?",ref:"/Privateshowwork"},
    {title:"How does tip works on Fan meet and Fan date?",ref:"/Fanmeetanddate"},
    {title:"What happens if the fan refuse to mark as completed after the Fan meet or Fan date is completed?",ref:"/Whathappen"},
    {title:"Payouts",ref:"/Payout"},
    {title:"What is My Revenue Share?",ref:"/Revenueshare"},
    {title:"How to receive a payout on Mmeko?",ref:"/ReceivePayout"},
    {title:"Outdoor Content",ref:"/Outdoorcontent"},
    {title:"Content Not Allowed On Mmeko",ref:"/Contentnotallow"},

    
  ];

  return (
    <div className="bg-black min-h-screen text-white ">
      <Header />
      <SearchBar />

      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <TitleSection
          title="Creators"
          article="11 articles"
          icon={<FaGraduationCap color="" size={30} />}
        />

          <ArticleList articles={articles} />
        
      </div>
    </div>
  );
};
