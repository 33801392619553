import React,{useState , useEffect} from 'react'
import addMonths from 'date-fns/addDays';
import format from 'date-fns/format';
import PacmanLoader from "react-spinners/RotateLoader";
import { useSelector } from 'react-redux';
import {bookmdel , resetstat} from "../../app/features/booking/booking"
import { useDispatch } from 'react-redux';

export const Requestform = ({setsuccess, setrequested,modelid , type, toast, price}) => {
      let [loading, setLoading] = useState(false);
      let [color, setColor] = useState("#d49115");
      let [disable,setdisable] = useState(false)
      let [date,setdate] = useState('')
      let [time,settime] = useState("")
      let [place,setplace] = useState("")
      const userid = useSelector(state => state.register.userID)
      const token = useSelector(state => state.register.refreshtoken)
      const bookingstats = useSelector(state => state.booking.bookingstats)
      const bookingmessage = useSelector(state => state.booking.bookingmessage)
      const dispatch = useDispatch()

      useEffect(()=>{
        if(bookingstats === "succeeded"){
           setsuccess(false)
           setrequested(true)
           setLoading(!loading)
           dispatch(resetstat())
           

        }

         if(bookingstats === "failed"){
           setsuccess(false)
           setLoading(!loading)
            dispatch(resetstat())
            toast.error(`${bookingmessage}`,{autoClose:2000})
           //setrequested(true)

        }

      },[bookingstats])
    

//const [eventDate, setEventDate] = useState(format(new Date(), "yyyy-MM-dd"));
const maxDate = format(addMonths(new Date(), 13), "yyyy-MM-dd");

const checkInput = ()=>{
  if(!time){
    toast.error("input time",{autoClose:2000})
    return
  }
  if(!date){
     toast.error("input date",{autoClose:2000})
     return
  }

  if(!place){
     toast.error("input Venue",{autoClose:2000})
     return
  }

  if(bookingstats !== "Loading"){
     setLoading(!loading)
    dispatch(
      bookmdel({
        type,
        place,
        time,
        date,
        token,
        modelid,
        userid,
        price
      })
    )

  }


}

  return (
    <div className=' w-fit  h-fit bg-slate-300 shadow-black shadow rounded-lg'>
        <p className='text-center text-xs underline underline-offset-8'>Please Enter Request Details</p>

        <fieldset className='mt-5'>
            <div className='flex p-1'>
                <p className='text-xs'>Enter Date</p>

                 <input type='date' className='ml-2 rounded-lg' min={new Date().toJSON().slice(0, 10)}
                 max={maxDate} onInput={(e)=>{
                  setdate(e.currentTarget.value)
                 }}
                 >
                  </input>

            </div>

             <div className='flex p-1'>
                <p className='text-xs'>Enter Time</p>

                 <input type='time' className='ml-2 rounded-lg' onInput={(e)=>{
                  settime(e.currentTarget.value)
                 }}
                 >
                  </input>

            </div>

            <div className='flex p-1'>
                <p className='text-xs'>Input Venue</p>

                 <input type='text' className='ml-2 rounded-lg' onInput={(e)=>{
                  setplace(e.currentTarget.value)
                 }}
                 >
                  </input>

            </div>

             <div className='flex p-1 mt-3 justify-center'>
               <button className='bg-green-500 p-1 text-xs rounded-lg shadow shadow-yellow-800' onClick={(e)=>{
                //console.log(place+" "+time+" "+" "+date +" "+modelid)
                checkInput()
               
               }}>
                Done
               </button>

            </div>
                    <div className='flex justify-center p-3'>
                    <PacmanLoader
                        color={color}
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    
                    />
                </div>
        </fieldset>

    </div>
  )
}
