import React from "react";

const CategoryButtomCompoent = ({ buttons, selected, onButtonClick }) => {
  return (
    <div className="flex gap-4">
      {buttons.map((button) => (
        <button
          key={button.value}
          className={`border border-slate p-2 rounded-lg bg-slate-400 text-blue-600
          ${selected === button.value && "border-green-500 text-white"}`}
          onClick={() => onButtonClick(button.value)}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default CategoryButtomCompoent;
