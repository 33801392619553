import React from "react";
import Popup from "reactjs-popup";
import { Loginview } from "./authviews/Loginview";

export const Logins = () => {
  return (
    <div className="mt-2">
      <Popup trigger={<button className="btn">Log in</button>} modal nested>
        {(close) => (
          <div className=" border-black  bg-black w-2/3">
            <Loginview close={close} />
          </div>
        )}
      </Popup>
    </div>
  );
};
