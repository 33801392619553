import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetprofilebyid } from '../../../app/features/profile/comprofile'
import { verifymodel, rejectmodel, changemodelstatus, delete_exclusive_ids } from '../../../app/features/model/modelSlice'
import { useState, useEffect } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import proimage1 from "../../../icons/icons8-profile_Icon.png"
import { downloadImage } from '../../../api/sendImage'
import PacmanLoader from "react-spinners/ClockLoader";



let stats = ""
let holdIMG = proimage1
let idIMG = proimage1
let userIMG = proimage1
let showimage1 = false
let image1 = { type: "", photo: proimage1 }
let disableButton = false
let loading = false



export const Hostlist = ({prob}) => {
  //console.log(prob.data)

  const { userid, firstname, lastname, username, email, dob, city, country, address, documentType, holdingIdPhoto, idPhoto, id, image } = prob
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const myid = useSelector(state => state.register.userID)
  const token = useSelector(state => state.register.refreshtoken)
  const rejectmodelstatus = useSelector(state => state.model.rejectmodelstatus)
  const verifymodelstatus = useSelector(state => state.model.verifymodelstatus)
  const delete_docs_stats = useSelector(state => state.model.delete_docs_stats)
  let [color, setColor] = useState("#d49115");
  
  //let [showimage1, setshowimage1] = useState(false)
  
  
 

  useEffect(()=>{
    if(holdingIdPhoto){
      holdIMG = downloadImage(holdingIdPhoto,"model")
    }

    if(idPhoto){
      idIMG = downloadImage(idPhoto,"model") 
    }

    if(image){
      userIMG = downloadImage(image,"profile")
    }
  },[])

  useEffect(()=>{
    if (delete_docs_stats === "succeeded") {
      dispatch(changemodelstatus("idle"))
      console.log("delete image success")
      if(stats === "reject"){
        stats = ""
        dispatch(rejectmodel({
          token,
          docid: id,
          userid:userid
        }
        ))

      }
      
      if(stats === "verify"){
        stats = ""
        dispatch(verifymodel({
          token,
          docid: id,
          userid:userid
        }
        ))
      }
  }

    if (delete_docs_stats === "failed") {
       loading = false
    }

    if (verifymodelstatus === "succeeded") {
      dispatch(changemodelstatus("idle"))
      loading = false
    }

    if (verifymodelstatus === "failed") {
      loading = false
    }

    if (rejectmodelstatus === "succeeded") {
      dispatch(changemodelstatus("idle"))
      loading = false
    }

    if (rejectmodelstatus === "failed") {
      loading = false
    }

  },[rejectmodelstatus,verifymodelstatus,delete_docs_stats])

  const verify = async() => {
    if (userid) {
      if (verifymodelstatus !== "loading") {
        disableButton = true
        stats = "verify"
        loading = true
        dispatch(delete_exclusive_ids({
          file:[holdingIdPhoto,idPhoto]
        }
        ))
        //setLoading(true)
        
       
      }

    }
  }

  const reject = async() => {
    if (userid) {
      if (rejectmodelstatus !== "loading") {
        disableButton = true
        stats = "reject"
        loading = true
      
        dispatch(delete_exclusive_ids({
          file:[holdingIdPhoto,idPhoto]
        }
        ))

       
        //setLoading(true)
       

        
        // setdisable(true)
      }

    }
  }

  const [showDetails, setShowDetails] = useState(false);
 // const [image1, setimage1] = useState({ type: "", photo: "" });
 

  const handleToggleDetails = () => {
    showDetails ? setShowDetails(false) : setShowDetails(true)
  };

  const handleToggleimage1 = (type, photo) => {
     showimage1 = true;
    // setshowimage1(true)
    // setimage1({ type, photo})
    image1.type = type
    image1.photo = photo
  }

  return (
    <>
      <div className='jost bg-[#161515] py-3 px-2  mb-2'>
      {loading && (
          <div className="flex flex-col items-center mt-16 w-full z-10 relative top-3/4">
            <PacmanLoader
              color={color}
              loading={loading}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
              margin={"auto"}
            />

            <p className="jost text-yellow-500 font-bold
            
            ">submitting...</p>
          </div>
        )}
        <div className='text-stone-200 flex gap-4 rounded-md '>
          <div className=''>
            <div className='w-[60px] rounded-full overflow-hidden'
              onClick={(e) => {
                dispatch(resetprofilebyid())
                navigate(`/profile/${userid}`)
              }}
            >
              <img src={userIMG} alt="" className='w-full h-full' />
            </div>
          </div>
          <div className='w-[100%]'>
            <div>
              <h2 className='text-[1.3em] font-bold '>{`${firstname} ${lastname}`}</h2>
              <h2 className='text-[1.1em] '>{username}</h2>
            </div>
            <div className='flex justify-between mt-3 w-[100%]'>
              <button className='bg-blue-800 py-2 w-[48%] rounded-[3px] '
                onClick={async(e) => {
                  await verify()
                }}
              disabled={disableButton}>Accept</button>
              <button className='bg-slate-800 py-2 w-[48%] rounded-[3px] '
                onClick={async(e) => {
                  await reject()
                }}
              disabled={disableButton}>Decline</button>
            </div>
            <div><button className='bg-[black] py-2 w-full rounded-[3px] text-white mt-3'
              onClick={handleToggleDetails}
            >{showDetails ? 'Hide Details' : 'View Details'}</button></div>
          </div>
        </div>
        <div className={`bg-dark mt-3 text-slate-100 text-[1.14em] ${!showDetails ? "hidden" : null}`}>
          <ul>
            <li>First Name: {firstname}</li>
            <li>Last Name: {lastname}</li>
            <li>Country: {country}</li>
            <li>City: {city}</li>
            <li>Email: {email}</li>
            <li>Date Of Birth: {dob}</li>
            <li>Resident Address: {address}</li>
          </ul>
          <div className="flex w-full justify-between mt-5">
            <button className="bg-amber-500 py-2 w-[48%] rounded-[3px]" onClick={(e)=>{handleToggleimage1("photo",holdIMG)}}>View photo</button>
            <button className="bg-gray-500 py-2 w-[48%] rounded-[3px]" onClick={(e)=>{handleToggleimage1("id",idIMG)}}>View ID</button>
          </div>

        </div>

      <div className={`w-[90%] h-screen bg-slate-50 absolute top-0 ${!showimage1 ? "hidden" : null}`} >
          <div className="w-full relative h-12 mt-2">
            <MdOutlineClose className="absolute right-[2%] text-5xl text-black font-bold" onClick={()=>{
              console.log("off image "+showimage1)
               showimage1 = false
            }} />
          </div>
          {image1.type === "photo" ? (
            <p className="font-bold text-center text-xl ">Applicant Verication Selfie</p>
          ) : (
            <p className="font-bold text-center text-xl ">Applicant ID Card</p>
          )}
          <div className="m-[2%]">
            <img src={image1.photo} alt="" className='w-full h-full' />
          </div>
        </div>
        
      </div>
    </>
  )
}