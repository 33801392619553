import React from "react";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";


export const AccountinfoPage = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
      
      <div className='w-full md:w-2/4 flex flex-col'>
      <header className="flex gap-4 items-center">
        <FaAngleLeft
          color="white"
          size={30}
          onClick={() => {
            navigate(-1);
          }}
        />

        <h4 className="font-bold text-lg text-white">ACCOUNT INFO</h4>
      </header>
      <div className="w-full max-w-md space-y-6 mt-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-white">Email</label>
          <input
            type="text"
            value="user@example.com"
            disabled
            className="w-full px-4 py-4 bg-black text-white border border-gray-600 rounded-md"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-white">Joined</label>
          <input
            type="text"
            value="20/08/2024"
            disabled
            className="w-full px-4 py-4 bg-black text-white border border-gray-600 rounded-md"
          />
        </div>
      </div>

      {/* Logout Button */}
      <button className="mt-6 w-full max-w-md px-4 py-3 bg-white text-black font-medium rounded-lg hover:bg-gray-500">
        Log out all devices and accounts
      </button>
      </div>
     
    </div>
  );
};
