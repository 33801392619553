import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-04K08K0FND');

function AnalyticsTracker() {
  const location = useLocation();
  
  useEffect(() => {
    // console.log("GA Initialized:", ReactGA.isInitialized);

    
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
  
  return null;
}

export default AnalyticsTracker;