import React from "react";

import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
      

      <div className="w-full md:w-2/4 flex flex-col text-gray-400 px-4 md:px-0">
        <header className="flex gap-4 items-center mb-4">
          <FaAngleLeft
            color="white"
            size={30}
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          />
          <h4 className="font-bold text-lg text-white">Privacy Policy</h4>
        </header>
        <section>
          <div className="">
            <p className="mb-3">
              We respect your privacy and are committed to protecting it through
              this Privacy Policy (the "Policy"). This Policy describes the
              types of personal information we may collect from you, the user,
              (you, your, yours, etc.) or that you may provide to us when you
              use the services offered by us on the Site (the "Services"), and
              our practices for collecting, using, keeping, protecting, and
              disclosing your personal information.
            </p>

            <p className="mb-3">
              Please read this Policy carefully to understand our practices
              regarding your personal information and how we will treat it. If
              you do not agree with this Policy, your sole choice is to leave
              the Site. By accessing or using the Site, you agree to this Policy
              and consent to our collection, use, disclosure, retention, and
              protection of your information as described in this Policy.
            </p>

            <p className="mb-3">
              We reserve the right to revise, amend, or modify this Policy at
              any time and in any manner. We will consider your continued use of
              the Site after we make changes to this Policy as your acceptance
              of the changes, so you must periodically revisit this Policy and
              check the "Last Updated" date above. If changed, this Policy has
              been updated or edited, and the updated or edited version
              supersedes any prior versions immediately upon posting.
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              1. How old do you have to be to use the Services?
            </h2>
            <p className="mb-3">
              We prohibit anyone under the age of eighteen (18) from accessing
              the Site or using the Services. We do not knowingly market to or
              collect or solicit any information from or about anyone under the
              age of eighteen (18). If you are under the age of eighteen (18),
              you must not submit information to us and must immediately leave
              the Site. If we learn that we have collected information from or
              about a person under the age of eighteen (18), we will delete that
              information as quickly as possible. If you believe that we might
              have such information, please contact us at www.mmeko.com
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              2. What types of information do we collect about you?
            </h2>
            <p className="mb-3">
              We may collect several types of "personal information" from and
              about users of the Site, including any information that personally
              identifies you or that could be reasonable linked to you or your
              household, including your name; alias; username or other unique
              personal identifier, and password; postal, billing, and shipping
              address; email address; IP address; telephone number; social
              security number; driver's license, passport, or other
              identification card number; credit card or banking information;
              order history; personal property records; biometric information;
              characteristics of protected classifications; and inferences drawn
              from any of this information about your preferences,
              characteristics, psychological trends, predispositions, behavior,
              attitudes, intelligence, abilities, or aptitudes. However, this
              Policy does not apply to personal information that has been
              de-identified or that is otherwise publicly available.
            </p>
            <p className="mb-3">
              We, or our contractors, may collect, store, and/or maintain
              biometric information including, but not limited to, a retina or
              iris scan, fingerprint, voiceprint, or scan of hand or face
              geometry, from any content or verification documents you provide
              to us or upload to the Site.
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              3. How do we collect your personal information?
            </h2>
            <p className="mb-3">
              We collect your personal information directly from you when you
              provide it to us, such as through:
            </p>

            <ul className="list-disc list-inside mt-2">
              <li>The account registration process;</li>
              <li>Your profile information or other posts;</li>
              <li>Your purchases and other financial transactions;</li>
              <li>Your search queries;</li>
              <li>Your linked social media accounts;</li>
              <li>Your responses to any surveys we ask you to complete; and</li>
              <li>
                Your other communications and interactions with us, whether by
                contact form, phone, mail, email, text, or other means,
                including on third-party social media platforms.
              </li>
            </ul>

            <p className="mb-3">
              We also collect your personal information automatically from your
              computer device or mobile phone and through cookies, web beacons,
              and other tracking technologies.
            </p>

            <p className="mb-3">
              This Policy does not apply to information collected by us offline
              or through any other means, or by any third party that is linked
              to or accessible through the Site.
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              4. Do third parties collect my personal information on the Site?
            </h2>
            <p className="mb-3">
              Third parties including users, advertisers, content or application
              providers, and third-party plug-ins may provide materials to the
              site which use cookies, web beacons, or other tracking
              technologies to collect your personal information, including
              information about your online activities over time and across
              different websites and other online services. Those third parties
              may use this information to provide you with interest-based
              (behavioral) advertising or other targeted content. We do not
              control third-party tracking technologies or how third parties use
              them. Your use of third-party plug-ins are governed by the user
              terms and privacy policy of the third party that provided that
              plug-in. If you have any questions about an advertisement or
              plug-in, you should contact the responsible provider directly.
            </p>

            <p className="mb-3">
              Please be aware that we do not operate, control, or endorse
              third-party websites that may be linked on the Site, nor are we
              responsible for the content or privacy practices of third-party
              websites. We disclaim any responsibility for your personal
              information on third-party websites, and we do not make any
              warranties or representations that any third-party website (or
              even this Site) will function without error or interruption, that
              defects will be corrected, or that any third-party websites or
              their servers are free of viruses or other problems that may harm
              your computer. We encourage you to be aware when you leave the
              Site and to read the privacy policies of any third-party website
              that collects your personal information.
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              5. How do we use your personal information?
            </h2>
            <p>We may use your personal information:</p>

            <ul className="list-disc list-inside mt-2">
              <li>
                To provide you with access to the Site and use of the Services;
              </li>
              <li>
                To speed up the Services, such as by automatically updating your
                account information;
              </li>
              <li>To recognize you when you return to the Services;</li>
              <li>
                To personalize the Services according to your preferences and
                individual interests;
              </li>
              <li>
                To notify you about changes to the Services and our policies;
              </li>
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts between you and us, including this Policy and our
                Terms of Service;
              </li>
              <li>
                To monitor and analyze traffic and usage trends related to the
                Services;
              </li>
              <li>To verify the integrity and security of the Services;</li>
              <li>To improve the Services and provide customer service;</li>
              <li>
                To investigate and prevent unauthorized or prohibited uses of
                the Services;
              </li>
              <li>For marketing or advertising purposes; and</li>
              <li>For any other purpose with your consent.</li>
            </ul>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              6. Do we share your personal information with third parties?
            </h2>
            <p>
              We may share publicly available information and de-identified
              information with third parties without restriction. However, we
              may only disclose your personal information to:
            </p>

            <ul className="list-disc list-inside mt-2">
              <li>
                Our subsidiaries, affiliates, contractors, service providers,
                and other third parties as necessary to provide the Services to
                you;
              </li>
              <li>
                Potential buyers or other successors in the event of a merger,
                joint venture, assignment, divestiture, restructuring,
                reorganization, dissolution, or other sale or transfer of assets
                including bankruptcy, liquidation, or similar proceeding;
              </li>
              <li>
                Law enforcement authorities, government agencies, and private
                litigants, such as in response to lawful criminal, civil, or
                administrative process or discovery requests, subpoenas, court
                orders, writs, or reasonable requests of authorities or persons
                with the reasonable power to obtain such process;
              </li>
              <li>
                Any other party as necessary to identify, contact, or bring
                legal action against someone who may be violating our policies;
              </li>
              <li>
                Any other party as necessary to protect the rights, property, or
                safety of us, our users, or the general public, including but
                not limited to disclosures for the purposes of fraud protection
                and credit risk reduction; and
              </li>
              <li>Any other party with your consent.</li>
            </ul>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              7. What choices do you have over your personal information?
            </h2>
            <p className="mb-3">
              We strive to provide you with choices about the personal
              information you provide directly to us. You can always delete or
              restrict any personal information that you provided directly to
              us. In the case of personal information contained in your content,
              you may (i) change the privacy settings associated with the
              content and/or your account, or (ii) delete the content containing
              the personal information from your profile. In all other cases, we
              will delete any personal information that you have provided
              directly to us, if you request to permanently delete your account.
              However, we may retain your personal information for any use set
              forth herein. Further, we may refuse to accommodate any change if
              we believe doing so would violate any law or legal requirement or
              cause the information to be incorrect.
            </p>

            <p className="mb-3">
              We also strive to provide you with choices about the personal
              information that we collect from you automatically. You may refuse
              to accept cookies by activating the appropriate setting on your
              browser. To learn how you can manage your other cookies, visit
              www.allaboutcookies.org/manage-cookies/. However, if you select
              this setting you may be unable to access certain parts of the
              Site. Unless you have adjusted your browser setting so that it
              will refuse cookies, we will issue cookies when you access the
              Site.
            </p>

            <p className="mb-3">
              Do Not Track ("DNT") is a privacy preference that you can set in
              your browser. DNT is a way for you to inform websites and services
              that you do not want certain information about your browser
              history collected over time and across websites or online
              services. However, we do not recognize or respond to any DNT
              signals as the Internet industry works toward defining exactly
              what DNT means, what it means to comply with DNT, and a common
              approach to responding to DNT. For more information, visit
              www.allaboutdnt.com.
            </p>

            <p className="mb-3">
              We do not control third parties' collection or use of your
              personal information to serve interest-based advertising. However,
              these third parties may provide you with ways to choose not to
              have your information collected or used in this way.
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              8. How long do we retain your personal information?
            </h2>
            <p>
              Except as otherwise permitted or required by applicable law or
              regulation, we will retain your personal information only for as
              long as necessary to fulfill any use of your personal information
              set forth herein. However, we reserve the right to retain publicly
              available information and de-identified information for any
              legitimate business purpose without further notice to you or your
              consent.
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              9. Is my personal information secure?
            </h2>
            <p>
              We are committed to data security, and we have implemented
              measures designed to secure your personal information from
              accidental loss and from unauthorized access, use, change, and
              disclosure. All information you provide to us is stored on our
              secure servers behind firewalls. However, you understand and agree
              that the transmission of your personal information over the
              Internet is not completely secure. While we do our best to protect
              your personal information, we cannot guarantee the security of
              your personal information transmitted through the Site. Any
              transmission of personal information is at your own risk. We are
              not responsible for circumvention of any privacy settings or
              security measures used by the Site.
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              10. No Third-Party Rights
            </h2>
            <p>
              This Policy does not create rights enforceable by third parties or
              require disclosure of any personal information relating to users
              of the Services.
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              11. International Users
            </h2>
            <p>
              This Policy is intended to cover collection of personal
              information within our home jurisdiction. Some countries may
              require stricter privacy policies than those described in this
              Policy. If you are accessing the Site from a foreign country, you
              understand and agree that your personal information may be
              transferred to, stored, and processed in our home jurisdiction or
              the jurisdiction of the third parties described herein, and that
              the data protection and other laws of our home jurisdiction or the
              jurisdiction of such third parties might not be as comprehensive
              as those in your country.
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              Use of Personal Information
            </h2>
            <p>
              We may use or disclose the personal information we collect for one
              or more of the following purposes:
            </p>

            <ul className="list-disc list-inside mt-2">
              <li>
                To fulfill or meet the reason you provided the information. For
                example, if you share your name and contact information to ask a
                question about our products or services, we will use that
                personal information to respond to your inquiry. If you provide
                your personal information to purchase a product or service, we
                will use that information to process your payment. We may also
                save your information to facilitate new product orders.
              </li>
              <li>
                To provide, support, personalize, and develop our Website,
                products, and services.
              </li>
              <li>
                To create, maintain, customize, and secure your account with us.
              </li>
              <li>
                To process your requests, purchases, transactions, and payments
                and prevent transactional fraud.
              </li>
              <li>
                To provide you with support and to respond to your inquiries,
                including to investigate and address your concerns and monitor
                and improve our responses.
              </li>
              <li>
                To personalize your Website experience and to deliver content
                and product and service offerings relevant to your interests,
                including targeted offers and ads through our Website,
                third-party sites, and via email or text message (with your
                consent, where required by law).
              </li>
              <li>
                To help maintain the safety, security, and integrity of our
                Website, products and services, databases and other technology
                assets, and business.
              </li>
              <li>
                For testing, research, analysis, and product development,
                including to develop and improve our Website, products, and
                services.
              </li>
              <li>
                To respond to law enforcement requests and as required by
                applicable law, court order, or governmental regulations.
              </li>
              <li>
                As described to you when collecting your personal information or
                as otherwise stated in the CCPA.
              </li>
              <li>
                To evaluate or conduct a merger, divestiture, restructuring,
                reorganization, dissolution, or other sale or transfer of some
                or all of our assets, whether as a going concern or as part of
                bankruptcy, liquidation, or similar proceeding, in which
                personal information held by us about our Website users is among
                the assets transferred.
              </li>
              <li>To verify your identity.</li>
            </ul>

            <p>
              We will not collect additional categories of personal information
              or use the personal information we collected for materially
              different, unrelated, or incompatible purposes without providing
              you notice.
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              Sharing Personal Information
            </h2>
            <p>
              We may disclose your personal information to a third party for a
              business purpose. When we disclose personal information for a
              business purpose, we enter a contract that describes the purpose
              and requires the recipient to both keep that personal information
              confidential and not use it for any purpose except performing the
              contract.
            </p>

            <p>
              We share your personal information with the following categories
              of third parties:
            </p>

            <ul className="list-disc list-inside mt-2">
              <li>Service providers.</li>
              <li>Internet cookie data recipients, like Google Analytics.</li>
            </ul>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              Deletion Request Rights
            </h2>
            <p className="mb-3">
              You have the right to request that we delete any of your personal
              information that we collected from you and retained, subject to
              certain exceptions. Once we receive and confirm your verifiable
              consumer request (see Exercising Access, Data Portability, and
              Deletion Rights), we will delete (and direct our service providers
              to delete) your personal information from our records, unless an
              exception applies.
            </p>

            <p>
              We may deny your deletion request if retaining the information is
              necessary for us or our service providers to:
            </p>

            <ol className=" mt-2">
              <li>
                Complete the transaction for which we collected the personal
                information, provide a good or service that you requested, take
                actions reasonably anticipated within the context of our ongoing
                business relationship with you, fulfill the terms of a written
                warranty or product recall conducted in accordance with federal
                law, or otherwise perform our contract with you.
              </li>
              <li>
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity, or prosecute those responsible
                for such activities.
              </li>
              <li>
                Debug products to identify and repair errors that impair
                existing intended functionality.
              </li>
              <li>
                Exercise free speech, ensure the right of another consumer to
                exercise their free speech rights, or exercise another right
                provided for by law.
              </li>
              <li>
                Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all
                other applicable ethics and privacy laws, when the information's
                deletion may likely render impossible or seriously impair the
                research's achievement, if you previously provided informed
                consent.
              </li>
              <li>
                Enable solely internal uses that are reasonably aligned with
                consumer expectations based on your relationship with us.
              </li>
              <li>Comply with a legal obligation.</li>
              <li>
                Make other internal and lawful uses of that information that are
                compatible with the context in which you provided it.
              </li>
            </ol>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              Exercising Access, Data Portability, and Deletion Rights
            </h2>
            <p className="">
              To exercise the access, data portability, and deletion rights
              described above, please submit a verifiable consumer request to us
              at:
            </p>
            
            <p>
              <a
                href="https://www.mmeko.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.mmeko.com
              </a>
            </p>

            <p className="mb-3">
              Only you, or someone legally authorized to act on your behalf, may
              make a verifiable consumer request related to your personal
              information. You may also make a verifiable consumer request on
              behalf of your minor child
            </p>

            <p>
              You may only make a verifiable consumer request for access or data
              portability twice within a 12-month period. The verifiable
              consumer request must:
            </p>
            <ul className="list-disc list-inside mt-2">
              <li>
                Provide sufficient information that allows us to reasonably
                verify you are the person about whom we collected personal
                information or an authorized representative.
              </li>
              <li>
                Describe your request with sufficient detail that allows us to
                properly understand, evaluate, and respond to it. We cannot
                respond to your request or provide you with personal information
                if we cannot verify your identity or authority to make the
                request and confirm the personal information relates to you.
              </li>
            </ul>
            <p className="mb-3">
              Making a verifiable consumer request does not require you to
              create an account with us.
            </p>
            <p className="mb-3">
              We will only use personal information provided in a verifiable
              consumer request to verify the requestor’s identity or authority
              to make the request.
            </p>

            <h2 className="text-gray-300 text-xl font-semibold my-2">
              Response Timing and Format
            </h2>
            <p className="mb-3">
              We endeavor to respond to a verifiable consumer request within
              forty-five (45) days of its receipt. If we require more time (up
              to 45 days), we will inform you of the reason and extension period
              in writing.{" "}
              <p className="mb-3">
                If you have an account with us, we will deliver our written
                response to that account. If you do not have an account with us,
                we will deliver our written response by mail or electronically,
                at your option.
              </p>
              <p className="mb-3">
                Any disclosures we provide will only cover the twelve (12) month
                period preceding the verifiable consumer request’s receipt. The
                response we provide will also explain the reasons we cannot
                comply with a request, if applicable. For data portability
                requests, we will select a format to provide your personal
                information that is readily useable and should allow you to
                transmit the information from one entity to another entity
                without hindrance.
              </p>
              We do not charge a fee to process or respond to your verifiable
              consumer request unless it is excessive, repetitive, or manifestly
              unfounded. If we determine that the request warrants a fee, we
              will tell you why we made that decision and provide you with a
              cost estimate before completing your request.
            </p>
            <h2 className="text-gray-300 text-xl font-semibold my-2">
              Contact Information
            </h2>

            <p className="mb-3">
              If you have any questions or comments about this notice, the ways
              in which Select Media LLC collects and uses your information
              described below and in the Privacy Policy, your choices and rights
              regarding that use, or wish to exercise your rights under
              California law, please do not hesitate to contact us at:
            </p>
            
            <p className="mb-16">
              <strong>Website:</strong>{" "}
              <a
                href="https://www.mmeko.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.mmeko.com
              </a>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
