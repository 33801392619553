import React, { useEffect } from "react";
import { loginuser, changelogin } from "../app/features/register/registerSlice";
import {
  getprofile,
  ProfilechangeStatus,
} from "../app/features/profile/profile";
import {
  getcomprofile,
  getprofilephoto,
  Compfstatus,
  comprofilechangeStatus,
} from "../app/features/profile/comprofile";
import { useDispatch, useSelector } from "react-redux";
import { Main } from "./homeviews/Main";
import { getallpost } from "../app/features/post/post";
import { PostchangeStatus } from "../app/features/post/post";
import { Gennavigation } from "../navs/Gennav";
export const Home = () => {
  const dispatch = useDispatch();

  const login = useSelector((state) => state.register.logedin);
  const userid = useSelector((state) => state.register.userID);
  const prostatus = useSelector((state) => state.profile.status);
  const Compfstats = useSelector(Compfstatus);
  const photoLink = useSelector((state) => state.comprofile.photoLink);
  const poststatus = useSelector((state) => state.post.poststatus);
  const allpost = useSelector((state) => state.post.allPost);

  const obj = JSON.parse(localStorage.getItem("login"));

  useEffect(() => {
    
    if (obj) {
      dispatch(
        loginuser({
          email: obj.email,
          password: obj.password,
        })
      );
      console.log(login);

      dispatch(changelogin({ logstats: "idle", message: "" }));
    }

    dispatch(getallpost({}));
    
   
  }, []);

  useEffect(() => {
    if (login === true) {
      dispatch(getallpost({userid}));
      console.log("user id "+userid)
      dispatch(getprofile({ userid }));
      console.log(prostatus);
    }
  }, [login]);

  useEffect(() => {
    //console.log(prostatus)

    if (prostatus === "succeeded") {
      dispatch(getcomprofile({ userid }));
      dispatch(ProfilechangeStatus("idle"));
    }

    if (Compfstats === "succeeded") {
      dispatch(getprofilephoto(photoLink));
      dispatch(comprofilechangeStatus("idle"));
    }
  }, [prostatus, Compfstats, dispatch]);

  useEffect(() => {
    if (poststatus === "succeeded") {
      console.log(allpost);
      dispatch(PostchangeStatus("idle"));
    }

    if (poststatus === "failed") {
      dispatch(PostchangeStatus("idle"));
    }
  }, [poststatus]);

  return (
    <div className="lg:pr-28 sm:overflow-x-clip md:w-5/6">
      <Main />
    </div>
  );
};
