import React from 'react'
import person from "../icons/person.svg"
import {interst} from '../data/intresttypes'
import { useState,useEffect } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {registercomplete,error} from '../app/features/register/registerSlice'
import {changecompleate} from '../app/features/register/registerSlice'
import { toast,ToastContainer } from 'react-toastify'



export const Compregis = () => {

  const toastId = React.useRef(null);
  
  const alert = (message,type,close)=>{
   
    toastId.current = toast(`${message}`,{type :`${type}`,autoClose:close});

  }

  const dismissalert = ()=>{
    toast.dismiss(toastId.current)
  }




  const navigate = useNavigate();
  const dispatch = useDispatch()
  const errs = useSelector(error)
  const stats = useSelector(state=> state.register.compstats)

    const [personimg,setpersonimg]=useState(person)
    const [useraccountId,setuseraccountId]=useState("")
    const [interestedIn,setinterestedIn]=useState("")
    const [relationshipType,setrelationshipType]=useState('Family')
    const [photoLink,setphotoLink]=useState()
    const [details,setdetails]=useState('Hey I am using Mmeko')
    const {userID} = useParams()

    const [MALE,setMALE] = useState(false)
    const [FEMALE,setFEMALE] = useState(false)

    useEffect(()=>{
      setuseraccountId(userID)
      if(stats === "succeeded"){
        dismissalert()
        alert(`succed`,'success',true)
        dispatch(changecompleate('idle'));
        navigate('/')

      }
      if(stats === "failed"){
        dismissalert()
        alert(`${errs}`,'error',true)
        dispatch(changecompleate('idle'));
      }

    },[stats])

    useEffect(()=>{
  console.log(photoLink)
    },[photoLink,details,useraccountId])

    const checkInputs = async()=>{

      

      if(!interestedIn){
        toast.error("Please choose your reletionship intrest",{autoClose:2000})
        return
      }
      if(!useraccountId){
        toast.error("Please enter ID",{autoClose:2000})
        return
      }
      if(!relationshipType){
        toast.error("Please choose reletionship type",{autoClose:2000})
        return
      }
      

      if(stats !== "loading"){
      
        alert('please wait..',"info",false)
        await dispatch(
          registercomplete(
            {
              useraccountId,
              interestedIn,
              photoLink,
              relationshipType,
              details
            }
          )
        )
        
      
      }
    }
   
  return (
    <div className='bg-black h-fit w-full'>
       <ToastContainer position='top-center'  theme='dark'/>
        <p 
        className='text-yellow-600 text-center font-bold text-xl mt-4'
         >Fill Out Your Profile Form</p>

         <form className='mx-auto '>
            <div className='mx-auto rounded-full bg-slate-400 w-fit h-fit overflow-auto border-2 border-solid border-green-400 mt-3'>
                <img className='mx-auto rounded-full object-contain w-36 h-36'
                alt='profileimg'
                src={personimg}
                >
                </img>
            </div>

            
       <fieldset className='flex flex-col border border-solid border-slate-300 w-1/4 rounded-lg justify-between py-3 px-2 mx-auto h-fit mt-2'>
       <label className='text-slate-300 font-semibold text-sm text-center inline-block mx-auto'>Intrested In</label>
       <div className='flex flex-row'>
       <input type='radio' className='block' checked={MALE}
       onClick={()=>{
        setFEMALE(false)
        setMALE(true)
        setinterestedIn('MALE')
       }}
       ></input>
        <label className='text-slate-300 text-sm ml-1'>Male</label>
       </div>
      
       <div className='flex flex-row'>
       <input type='radio' className='block' checked ={FEMALE}
        onClick={()=>{
          setFEMALE(true)
          setMALE(false)
          setinterestedIn('FEMALE')
         }}
       ></input>
        <label className='text-slate-300 text-sm ml-1'>Female</label>
       </div>
      
       </fieldset>

       <fieldset className='flex flex-col border border-solid border-slate-300 w-1/4 rounded-lg justify-between py-3 px-2 mx-auto h-fit mt-2'>
       <label className='text-slate-300 font-semibold text-sm text-center inline-block mx-auto  text-nowrap whitespace-pre '>Relationship Type</label>
       
       <select className='btn' onChange={(e)=>{
        setrelationshipType(e.currentTarget.value)
       }}>
        <optgroup>
         {
            interst.map(
              (value)=>{
                return (<option value={`${value}`}>
                  {`${value}`}
                </option>)
              }              
            )
         }
        </optgroup>
       </select>

       </fieldset>

       <fieldset className='flex flex-col border border-solid border-slate-300 w-40 sm:w-2/4 rounded-lg justify-between py-3 px-2 mx-auto h-fit mt-2'>
       <label className='text-slate-300 font-semibold text-sm text-center inline-block mx-auto whitespace-pre '>Tell Us More About Your Self</label>
       
       <textarea cols={50} rows={5} className='rounded-md textinpt w-40 sm:w-full mx-auto' onChange={(e)=>{
        setdetails(e.currentTarget.value)
       }}>

       </textarea>
      
       </fieldset>

       <fieldset className='flex flex-col border border-solid border-slate-300 sm:w-2/4 rounded-lg justify-between py-3 px-2 mx-auto h-fit mt-2 mb-7 w-40'>
       <label className='text-slate-300 font-semibold text-sm text-center inline-block mx-auto  text-nowrap whitespace-pre '>Add Image</label>
       
      <input type='file'
      className='rounded-md font-bold  bg-yellow-600 sm:full w-40'
      onChange={async (e)=>{
        

        if(e.target.files[0]){
          setpersonimg(URL.createObjectURL(e.target.files[0]))
          setphotoLink(e.target.files[0])
        }
        
      
        
      }}>
      </input>
      
       </fieldset>

       <div className='w-full  px-5 '>
       <button className='btn text-center mx-auto  h-11 w-full mb-6 ' onClick={async(e)=>{
         e.preventDefault()
        
         await checkInputs()
       }}>
        Procced
       </button>
       </div>

         </form>

    </div>
  )
}

