import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Whoismmeko = () => {
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
    <Header />
    <div className="p-4">
      <Breadcrumbs title="About" />
      <div>
        <header className="my-4">
          <h4 className="font-bold  text-2xl">Who is Mmeko for ?</h4>
          
        </header>
        <p className='pt-2'>
         Mmeko is a platform for everyone. Our goal is to provide a safe place
         for models and creators to share their work, earn, and connect with 
         their audience. We don't censor content as long as it follows the rules..
        </p>
        
        
        </div>
    </div>
  </div>
  )
}
