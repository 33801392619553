import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Payout = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">Payouts</h4>
            
          </header>
         
         <p className="font-semibold">
          Payout methods available on Mmeko
            
         </p>

         <p className="mt-1">
            Mmeko currently offers multiple different payment methods, depending on your location, to help you withdraw your 
            funds.

         <ul className="list-disc list-inside mt-2 text-gray-300">
            <li>Paypal</li>
            <li>Paxum</li>

         </ul>
         </p>

         
          </div>
      </div>
     
    </div>
  );
  
};