import React, { useState } from "react";
import { Meetuplist } from "./modelnotifylist/Meetuplist";
import { useSelector } from "react-redux";

export const Meetupview = () => {
  const Meetupnotelist = useSelector((state) => state.booking.bookingnote);
  var latter;

  const Showmeets = () => {
    if (Meetupnotelist) {
      console.log("inside meet up 1")
      if(Meetupnotelist.model.length > 0){
        console.log("must show meetup meet up")
        return (
          <ul className="flex flex-col items-center pr-2 pl-2">
            {Meetupnotelist.model.map((value) => {
              if (value.ismessage === false && value.notification === false) {
                let messaging = check_status(
                  value.status,
                  value.type,
                  value.name
                );
                return (
                  <Meetuplist
                    clientname={value.name}
                    type={value.type}
                    photolink={value.photolink}
                    date={value.date}
                    time={value.time}
                    venue={value.place}
                    postuserid={value.clientid}
                    modelid={value.modelid}
                    status={value.status}
                    latter1={messaging}
                  />
                );
              }
            })}
          </ul>
        );
      }else{
        return (
          <p className="text-slate-400 text-center mt-16 text-xs">
            !You currently don`t have any meetup request
          </p>
        );
      }
      
     
    } else {
      return (
        <p className="text-slate-400 text-center mt-16 text-xs">
          !You currently don`t have any meetup request
        </p>
      );
    }
  };

  const check_status = (status, type, clientname) => {
    if (status === "accepted") {
      return (latter = `you accepted a ${type} request with ${clientname}`);
    } else {
      return (latter = `${clientname} Reqested a ${type} with you`);
    }
  };

  return <div>{Showmeets()}</div>;
};
