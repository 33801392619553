import WebPushService from "./webPush"
import axios from "axios";
import { URL } from "./config";

export async function init(userid){
    if(!WebPushService.hasPermission()){
    
       await WebPushService.requestPermission()
       let sub = await WebPushService.unsubscribe()
       
         let subcription = await WebPushService.subscribe()
         let data = {
            userid:userid,
            subinfo:JSON.stringify(subcription)
         }
         try{
        
            
            let response = await axios.post(`${URL}/subpushid`,data)
           
           
            return response.data
            
        }catch(err){
           // console.log('erro get profile')
            throw(err.response.data.message)
            
           
        }
         
       
     }
}
