import React, { useState, useEffect } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Switch } from "../../components/switch";
import { useSelector, useDispatch } from "react-redux";
import { updatesetting, ProfilechangeStatus,getprofile } from "../../app/features/profile/profile";
import WebPushService from "../../api/webPush"


export const PushNotificationPage = () => {
  const navigate = useNavigate();
  let updatesettingstats = useSelector((state) => state.profile.updatesettingstats);
  let pushnote = useSelector((state) => state.profile.pushnote);
  const [isOn, setIsOn] = useState(pushnote);
  const [notification, setnotification] = useState(false);
  const token = useSelector((state) => state.register.refreshtoken);
  const userid = useSelector((state) => state.register.userID);
  const dispatch = useDispatch()

  useEffect(()=>{
    if(updatesettingstats === "succeeded"){
      dispatch(getprofile({ userid }));
      setIsOn(!isOn)
      
      dispatch(ProfilechangeStatus("idle"))
    }
  },[updatesettingstats])
  
 
  const handleToggle = async() => {
    
    let pushsubinfo = ""
    if(updatesettingstats !== "loading"){
      let isnote = !isOn
      try{
        if(isnote === false){
          console.log("inside false")
          const payload = await WebPushService.unsubscribe()
          dispatch(updatesetting({token, userid,emailnot:"nothing",pushnot:!isOn,subinfo:pushsubinfo}))
  
        }
        
        if(isnote === true){
          console.log("inside true")
          if(!WebPushService.hasPermission()){
             console.log("ontop request permissin")
            await WebPushService.requestPermission()
            console.log("inside permission")
          }
        
          let subcription = await WebPushService.getSubscription()
          console.log("after subcribtionget")
          if(!subcription){
            console.log("ontop subcribe")
            subcription = await WebPushService.subscribe()
            console.log("after subcribe")
          }


          dispatch(updatesetting({token, userid,emailnot:"nothing",pushnot:!isOn,subinfo:JSON.stringify(subcription)}))

          
        }
  

      }catch(e){
        console.log("error subcribe"+e)
      }
    
     
      
    }
  }

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black text-white">

    <div className='w-full md:w-2/4 flex flex-col'>
    <header className="flex gap-4 items-center">
        <FaAngleLeft
          color="white"
          size={30}
          onClick={() => {
            navigate("/notification");
          }}
        />

        <h4 className="font-bold text-lg text-white">PUSH NOTIFICATION</h4>
      </header>

      <div className="flex justify-between items-center  ">
        <div className="flex gap-2 items-center">
          <h4 className="text-md text-white font-semibold">
            Enable push notification on this device
          </h4>
        </div>
        <div>
          <Switch isOn={isOn} handleToggle={handleToggle} />
        </div>
      </div>
      <p className="text-sm text-slate-400 py-2 mb-4">
        Get push notification from your fans when you are not on
      </p>

      <div className="pt-4 border-t-2 border-slate-400">
        <div className="flex justify-between items-center mb-6 ">
          <div className="flex gap-2 items-center">
            <h4 className="text-lg text-white font-semibold">Promotion</h4>
          </div>
          <div>
            <Switch isOn={isOn} handleToggle={handleToggle} />
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-6 ">
        <div className="flex gap-2 items-center">
          <h4 className="text-lg text-white font-semibold">Message</h4>
        </div>
        <div>
          <Switch isOn={isOn} handleToggle={handleToggle} />
        </div>
      </div>
    </div>
     
    </div>
  );
};
