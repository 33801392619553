import React,{useState, useEffect} from 'react'
import detailIcon from "../../icons/icons8-detail.png"
import closeIcon from "../../icons/closeIcon.svg"
import timeIcon from "../../icons/icons8-date.png"
import incomeIcon from "../../icons/icons8-income.png"
import spendIcon from "../../icons/icons8-debit.png"
import PacmanLoader from "react-spinners/RingLoader";
import { get_monthly_history, ProfilechangeStatus } from '../../app/features/profile/profile'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'



// const Months = [
//    "January", "February", "March", "April", "May", "June",
//      "July", "August", "September", "October", "November", "December",
// ]

const Month_count = [0,1,2,3,4,5]


export const Earnings = () => {

     const [detail_click, setdetail_click] = useState(false)
     const [month_click, setmonth_click] = useState("")

     const userid = useSelector(state => state.register.userID)
     const Month_count =   useSelector(state=> state.profile.monthly)
     const monthly_history_stats =   useSelector(state=> state.profile.monthly_history_stats)
     const token = useSelector(state => state.register.refreshtoken)
     const [click,setclick] = useState(true)

     let [earns_list , setearns_list] = useState([])
     let [index, setindex] = useState()

     const dispatch = useDispatch()

     useEffect(()=>{
       show_Month()
     },[])

     useEffect(()=>{
          if(monthly_history_stats === "succeeded"){
               setLoading(false)
          }
     },[monthly_history_stats])

     let [loading, setLoading] = useState(true);
     let [color, setColor] = useState("#FFFFFF");

    const displayMonth = ()=>{
       return Month_count.map(value =>{
        return <div className='w-full h-full mb-3'>
         <div className='flex justify-between'>

            <div className='flex'>
                <p className='text-white font-bold'>{`${value.month}`}</p>
                 <p className='text-white font-bold ml-3'>{`${value.data.year}`}</p>

            </div>

            <div className='flex'>
                 <p className='text-white font-semibold'>US${value.total}</p>

                 <button onClick={(e)=>{
                    setdetail_click(true)
                    setmonth_click(value.month)
                    setearns_list(value.data.earning)
                 }}>
                     <img alt='detailsIcon' src={detailIcon} className='w-5 h-5 '>
                     </img>
                 </button>
            </div>

           
            

          </div>

          <hr className='h-1 bg-slate-500 w-full rounded-md'>
          </hr>

        </div>
       })
    }

    const list_of_month_trans = ()=>{

     if(earns_list.length <= 0){
        return  <div className='w-full h-full flex justify-center items-center '>
               <p className='text-yellow-500 '>Your have 0 transaction on {month_click}</p>

          </div>
     }
      let list_rev = []
      earns_list.forEach(value=>{
          list_rev.push(value)
      })
      list_rev.reverse()
     return list_rev.map(value =>{
          return monthTransations(value)
     })
    }

    const showDate = (time)=>{
      let date1 = new Date(parseInt(time))
    //console.log('post date '+ date)
     const date =   format(date1, "MM/dd/yyyy 'at' h:mm a");

     return date
    }

    const monthTransations = (value)=>{
      return <div className='flex flex-col w-full bg-blue-500 pt-1 pb-1 mb-2 rounded-md'>
          <div className='flex w-full pl-2 pr-2'> 
          <img alt='dateIconn' src={timeIcon} className='w-5 h-5 object-cover'></img>
          <p className='ml-2 text-xs '>{showDate(value.date)}</p>

          </div>

          <div className='flex w-full pl-2 pr-2 mt-1'> 
          <img alt='detailIcon' src={detailIcon} className='w-5 h-5 object-cover'></img>
          <p className='ml-2 text-xs '>{value.detail}</p>

          </div>

          <div className='flex w-full pl-2 pr-2 mt-1'> 
          <img alt='incomeIcon' src={incomeIcon} className='w-5 h-5 object-cover'></img>
          <p className='ml-2 text-xs font-bold text-green-600 '>{value.income}</p>

          </div>

          <div className='flex w-full pl-2 pr-2 mt-1'> 
          <img alt='spendIcon' src={spendIcon} className='w-5 h-5 object-cover'></img>
          <p className='ml-2 text-xs font-bold text-red-600 '>-{value.spend}</p>

          </div>

      </div>
    }

    const show_Month = ()=>{
     if(monthly_history_stats !== "loading"){
       dispatch(
          get_monthly_history({userid,token})
       )
     }
    }


    const show_month_bool = ()=>{
     if(loading === false){
          return true
     }else{
          return false
     }
    }

  return (
     <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">

         <div className='w-full md:w-2/4 flex flex-col' onClick={(e)=>setclick(true)}>
          <div className='w-full h-full'>
                    
                    <div className='w-full flex flex-col items-center mt-1'>
                    <p className='text-xl font-bold text-white'>How much you are earning</p>
                    <p className='text-sm text-slate-300'>Estimated - Last 12 months</p>
               </div>

                    {
                    loading &&
                    <div className='flex w-full mt-16 flex-col items-center'>
                                   <PacmanLoader
                                   color={color}
                                   loading={loading}
                                   size={35}
                                   aria-label="Loading Spinner"
                                   data-testid="loader"
                                   />
                                   <p className='text-sm text-white'>Fetching history...</p>
               </div>

               }

               {
               show_month_bool() && 
               <div className='flex flex-col h-full w-full pl-4 pr-4 mt-5'>
                    {
                         displayMonth()
                    }

                    {
                         detail_click && 
                         <div className='bg-slate-300 left-5 right-5 h-40 mb-3 mt-2  pr-2 pl-2 rounded-md
                              z-20 top-1/4 absolute shadow shadow-black note
                              '>
                         <div className='flex flex-col w-full h-full relative'>
                              <div className='flex w-full'>
                                   <p className='text-center w-2/3 ml-7 text-sm font-bold'>{`${month_click}`} transactions</p>
                                   <div className='w-1/3 flex justify-end'>

                                   <button onClick={(e)=>setdetail_click(false)}>
                                        <img alt='closeIcon' src={closeIcon}></img>
                                   </button>
                              </div>

                              </div>

                              <div className='flex w-full h-full overflow-auto  flex-col'>
                                   
                                   {
                                   list_of_month_trans()
                                   }

                              </div>
                              
                         </div>

                    </div>
                    }

                    

               </div>
               }


               

               </div>
    </div>
   </div>
  )
}
