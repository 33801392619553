import React from "react";
import Breadcrumbs from "./components/breadcrumb";
import TitleSection from "./components/titleSection";
import ArticleList from "./components/articlelist";
import SearchBar from "./components/searchbar";
import Header from "./components/header";
import { FaGraduationCap } from "react-icons/fa";
import { TawkToIntegration } from "../../api/tawk";

export const AboutMmekoPage = () => {
  const articles = [
    "What is Mmeko?",
    "Who is Mmeko for?",
    "Does Mmeko allow adult content creators?",
    "How does Mmeko work?",
    "Who uses Mmeko?",
    "How to add Mmeko as an app on your iOS or Android device?",
    "How do I change my password?",
  ];

  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="About" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">What is Mmeko ?</h4>
            
          </header>
          <p>
            This is a social platform where fans can give tips to interact
            personally with their favorite model or creator, They can choose
            wheather it's through private conversation, fan meet or fan date.
            Our mission is to empower all Models and creators to create, share
            and earn on a safe and secure platform. We believe great
            interactions take place on great platforms with great tech. We're
            committed to building and always evolving a cutting-edge platform
            for all models and all fans.
          </p>
          <p className="pt-2">
            At Mmeko, we strive to provide models, creators and fans with the
            best experiences possible. We have a team of passionate and
            dedicated people who are committed to building and evolving a
            cutting-edge platform for all creators and all fans. Our core values
            include:
            <ul className="list-disc list-inside mt-2 text-gray-300">
              <li>Execute with excellence</li>
              <li>Innovate aggressively</li>
              <li>Deliver value, fast</li> <li>Take ownership</li>{" "}
              <li>Honesty & Transparency</li>
            </ul>
          </p>
          <p>
            We are here to help you make the most out of Mmeko. If you have any
            questions, please don't hesitate to contact us. We are here to
            support you
          </p>
          
         
        
       
        
        
          </div>
      </div>
     
    </div>
  );
  
};
