import React, { useState, useEffect } from "react";
import PacmanLoader from "react-spinners/RotateLoader";
import { downloadImage } from "../../../../api/sendImage";
import person from "../../../../icons/person.svg";
import {
  Cancelrequest,
  deleterequest,
  resetstat,
} from "../../../../app/features/booking/booking";
import { useSelector, useDispatch } from "react-redux";

export const Requestlist = ({
  modeltype,
  modelname,
  date,
  photolink,
  time,
  modelid,
  id,
}) => {
  const [loading, setloading] = useState(false);
  let [color, setColor] = useState("#d49115");
  let [image, setimage] = useState(person);
  const token = useSelector((state) => state.register.refreshtoken);
  const cancelstats = useSelector((state) => state.booking.cancelstats);
  const userid = useSelector((state) => state.register.userID);
  const dispatch = useDispatch();

  useEffect(() => {
    setimage(downloadImage(photolink, "model"));
  }, []);

  useEffect(() => {
    if (cancelstats === "succeeded") {
      dispatch(deleterequest({ modelid: modelid, date: date, time: time }));
      dispatch(resetstat());
      setloading(!loading);
    }

    if (cancelstats === "failed") {
      setloading(!loading);
    }
  }, [cancelstats]);

  const deleterequests = () => {
    if (cancelstats !== "loading") {
      setloading(!loading);
      dispatch(
        Cancelrequest({
          token,
          userid,
          modelid,
          time,
          date,
        })
      );
    }
  };

  return (
    <li className="bg-slate-300 p-1 w-full rounded-lg">
      {loading && (
        <div className="w-full flex flex-col items-center">
          <PacmanLoader
            color={color}
            loading={loading}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />

          <p className="text-xs">Please wait...</p>
        </div>
      )}

      <div className="flex">
        <img
          alt="modelimg"
          src={image}
          className="w-7 h-7 object-cover rounded-full"
        ></img>
        <div className="flex flex-col items-center ml-1 mt-1 p-1">
          <p className="bg-blue-100 text-xs">
            You requested a {`${modeltype}`} from {`${modelname}`} on{" "}
            {`${date}`} at {`${time}`}
          </p>
          <button
            className="bg-red-600 p-1 mt-2 text-xs rounded-xl shadow-red-200 shadow-2xl"
            onClick={(e) => deleterequests()}
          >
            Cancel request
          </button>
        </div>
      </div>
    </li>
  );
};
