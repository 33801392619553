import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const Fanmeetanddate = () => {
 
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
      <Header />
      <div className="p-4">
        <Breadcrumbs title="Creators" />
        <div>
          <header className="my-4">
            <h4 className="font-bold  text-2xl">How does tip works on Fan meet and Fan date ?</h4>
            
          </header>
         
         <p>
            Once you accept a Fan meet or a Fan date request, The tip will immediately be deducted from the fans balance 
            and will be held by Mmeko on the pending area. When the Fan meet or Fan date is done, Then the fan will mark 
            it as completed on their end and you will immediately receive the tip on your balance.
            
         </p>

         
          </div>
      </div>
     
    </div>
  );
  
};