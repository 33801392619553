import React, { useState } from "react";

const FileInput = ({ label, name, icon, onChange }) => {
  const [fileName, setFileName] = useState("Click to upload or drag and drop");

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
    }
    if (onChange) onChange(e); // Pass event to parent handler if provided
  };

  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-sm font-medium mb-2">
        {label}:
      </label>
      <label
        htmlFor={name}
        className="flex items-center justify-between border-2 border-gray-300 rounded-md p-4 cursor-pointer hover:border-blue-500"
      >
        <p className="text-sm text-gray-400 truncate">{fileName}</p>
        {icon}
        <input
          type="file"
          id={name}
          name={name}
          onChange={handleFileChange}
          className="hidden"
        />
      </label>
    </div>
  );
};

export default FileInput;
