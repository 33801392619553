import React, { useEffect, useState } from "react";
import { Postlist } from "./homeviews/Post/Postlist";
import {
  comprofilechangeStatus,
  getprofilebyid,
} from "../app/features/profile/comprofile";
import {
  follow,
  unfollow,
  ProfilechangeStatus,
} from "../app/features/profile/profile";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import person from "../icons/icons8-profile_Icon.png";
import { downloadImage } from "../api/sendImage";

import PacmanLoader from "react-spinners/BeatLoader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import locationIcon from "../icons/locationIcon.svg";
import StarIcon from "../icons/transparentstar.png";
import StarIcon2 from "../icons/star.png";
import CalenderIcon from "../icons/calendar.svg";
import messageIcon from "../icons/messageIcon.png";
import Tabs from "../../src/components/tab";
import DropdownMenu from "../../src/components/dropdown";
import backIcon from "../icons/backIcon.svg";
import { Exclusive } from "./profileview/exclusive";
import { Info } from "./profileview/info";
import Empty from "../icons/empty.svg";
import like from "../app/features/like/like";


let month = "";
let year = "";
let followers = 0;
let likes = 0;
let follow_text = "follow";

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const Profile = () => {
  const navigate = useNavigate();
  const getprofilebyidstats = useSelector(
    (state) => state.comprofile.getprofileidstatus
  );
  const profile = useSelector((state) => state.comprofile.profile);
  const follow_stats = useSelector((state) => state.profile.follow_stats);
  const unfollow_stats = useSelector((state) => state.profile.unfollow_stats);
  const dispatch = useDispatch();
  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const { postuserid } = useParams();
  const formatter = new Intl.NumberFormat("en-US");

  const [username, setusername] = useState("");
  const [nickname, setnickname] = useState("");
  const [isbuying, setisbuying] = useState(false)
  const [gender, setgender] = useState("");
  const [location, setlocation] = useState("");
  const [about, setabout] = useState("");
  const [active, setactive] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [userphoto, setuserphoto] = useState(person);
  const [disablehost, setdisable] = useState(false);
  const [exclusive_verify, set_exclusive_verify] = useState(false);
  const [disabledButton, setdisableButton] = useState(false);
  const model = useSelector((state) => state.profile.model);
  const [followimg, setfollowimg] = useState(StarIcon);
  const [isfollwed, setisfollowed] = useState(false);
  const [modelid, setmodelid] = useState([]);
  const [click,setclick] = useState(true)
  // const [modelid, setmodelid] = useState([Model[1], userid, "client"]);

  const [content, setcontent] = useState([]);

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#d49115");

  useEffect(() => {
    if (getprofilebyidstats !== "loading") {
      dispatch(getprofilebyid({ userid: postuserid, clientid: userid }));
    }

    if (isbuying === true) {
      setisbuying(false)
      dispatch(getprofilebyid({ userid: postuserid, clientid: userid }));
    }
  }, [postuserid, isbuying]);

  useEffect(() => {
    if (getprofilebyidstats === "succeeded") {
      setLoading(true);
      cantfandc();
      setusername(profile.username);
      setgender(profile.gender);
      setlocation(profile.location);
      setabout(profile.aboutuser);
      setactive(profile.active);
      setfirstname(profile.firstname);
      setlastname(profile.lastname);
      setnickname(profile.nickname);
      set_exclusive_verify(profile.exclusive);
      month = Months[Number(profile.joined_month)];
      year = profile.joined_year;
      likes = profile.likecount;
      console.log("date joined " + month);
      console.log("date joined " + year);
      followers = profile.followers.length;
      if (profile.userphoto) {
        setuserphoto(downloadImage(profile.userphoto, "profile"));
      } else {
        setuserphoto(person);
      }

      // if (profile.followers.length > 0 ) {
      //   followers = formatter.format(Number(profile.followers.length))
      // }

      // if (profile.exclusive_content.length > 0 ) {
      //   setcontent(profile.exclusive_content)
      // }

      dispatch(comprofilechangeStatus("idle"));
    }

    if (getprofilebyidstats === "failed") {
      dispatch(comprofilechangeStatus("idle"));
    }
  }, [getprofilebyidstats]);

  useEffect(() => {
    if (unfollow_stats === "succeeded") {
      setisfollowed(false)
      dispatch(ProfilechangeStatus("idle"))
     
    }

    if (follow_stats === "succeeded") {
      setisfollowed(true)
      dispatch(ProfilechangeStatus("idle"))
    }
  }, [unfollow_stats, follow_stats]);

  const checkonline = (active) => {
    if (active === true) {
      return <p className=" text-green-600 ml-5 ">online</p>;
    } else {
      return <p className=" text-red-600 ml-5 ">offline</p>;
    }
  };

  const fectuser = () => {
    if (profile.username) {
      return false;
    } else {
      return true;
    }
  };

  const myposts = () => {
    if (profile.username) {
      return profile.post.map((value) => {
        return (
          <Postlist
            key={value.postid}
            postlog={value.postid}
            userphoto={profile.userphoto}
            username={value.username}
            datetime={value.posttime}
            likes={value.like.length}
            comments={value.comment.length}
            postphoto={value.postphoto}
            content={value.content}
            posttype={value.posttype}
            postuserid={value.userid}
            likelist={value.like}
            isfollow={value.isfollow}
          />
        );
      });
    }
  };

 const cantfandc = ()=>{

    if(userid){
      setmodelid([postuserid,userid])

    }

    if(userid){
      if(profile.following){
        setisfollowed(true)
        setfollowimg(StarIcon2)
        follow_text = ""
      }
    
      if (!userid) {
        setdisableButton(true);
      } else if (userid === profile.userid) {
        setdisableButton(true);
      } else {
        setdisableButton(false);
      }
    };
 }

  const exclusive = () => {
    if (loading === true) {
      if (userid === profile.userid) {
        console.log("you are user");
        if (exclusive_verify === true) {
          if (profile.exclusive_content.length > 0) {
            /// view from her as user first
            return (
              <div className="flex flex-col overflow-auto">
                 <div className="grid grid-cols-2 gap-2 mb-3 overflow-hidden ">
                {profile.exclusive_content.map((c, index) => (
                  <Exclusive key={`${index}_${c.id}`} image={userphoto} price={c.price} content_type={c.content_type} contentlink={c.contentlink} contentname={c.contentname} buy={true} setisbuying={setisbuying} id={c._id} me={true} thumblink={c.thumblink} />
                ))}
                </div>

                <div className="my-4 flex justify-center py-4 mb-10">
                  <button onClick={(e)=>navigate("/addexclusive")} className='text-slate-50 font-bold'>
                   ADD Exclusive
                  </button>
                </div>

              </div>
             
            );
          } else {
            return (
              <div className="flex flex-col">
                <div className="my-4 flex justify-center py-4 mb-10">
                  <button onClick={(e)=>navigate("/addexclusive")}>
                  <img
                    src={Empty}
                    alt="empty"
                    className="h-10 w-10 object-cover"
                  />
                  </button>
                </div>
         

              </div>
            );
          }
        } else {
          //we put verifing button here
          return (
            <div className="flex flex-col">
              <div className="flex justify-end mb-2">
                <button
                  onClick={() => navigate("/verification")}
                  className="btn"
                >
                  Upload
                </button>
              </div>
            </div>
          );
        }
      } else if (userid !== profile.userid) {
        if (profile.exclusive_content.length > 0) {
          return (
            <div className="flex flex-col ">
              <div className="grid grid-cols-2 gap-2 mb-10 ">
                {profile.exclusive_content.map((c, index) => (
                  <Exclusive key={`${index}_${c.id}`} image={userphoto} price={c.price} content_type={c.content_type} contentlink={c.contentlink} contentname={c.contentname} buy={c.buy} setisbuying={setisbuying} id={c._id} me={false} thumblink={c.thumblink}  />
                ))}
              </div>
            </div>
          );
        } else {
          return (
            <div className="my-4 flex justify-center py-4 mb-10">
              <p className="text-slate-50 text-sm ">No content yet!</p>
            </div>
          );
        }
      }
    }
    // const contentLength = 0;
  };

  const info = () => {
    return (
      <Info
        firstname={profile.firstname}
        lastname={profile.lastname}
        birthday={profile.dob}
        gender={gender}
        location={location}
      />
    );
  };

  const follow_button = ()=>{
    if(isfollwed === true){
     if(unfollow_stats !== "loading"){
      setfollowimg(StarIcon)
      follow_text = "follow"
      dispatch(unfollow({userid:postuserid, followerid:userid, token}))
     }
    }else if(isfollwed === false){
      if(follow_stats !== "loading"){
        setfollowimg(StarIcon2)
        follow_text = ""
        dispatch(follow({userid:postuserid, followerid:userid, token}))
       }
    }
  };

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
      
      <div className='w-full   mx-auto md:w-3/5 text-white  md:mr-auto md:ml-0 sm:mt-16' onClick={(e)=>setclick(true)}>
      <div className="bg-black pb-6 ">
        <div>
          {fectuser() && (
            <>
              <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <div className="w-full max-w-sm bg-black text-white rounded-lg shadow-md p-4 space-y-4 mx-auto">
        
        <div className="w-full h-36">
          <Skeleton width="100%" height="100%" />
        </div>

     
        <div className="relative -mt-12 flex items-center justify-between px-4">
          <Skeleton width={70} height={70} circle />
          <Skeleton width={80} height={30} className="rounded-md" />
        </div>

       
        <div className="text-left space-y-1">
          <Skeleton width={140} height={20} className="rounded-md" />
          <Skeleton width={100} height={15} className="rounded-md" />
        </div>

      
        <div className="flex justify-start space-x-4">
          <Skeleton width={50} height={15} />
          <Skeleton width={50} height={15} />
                    </div>
                    
        <div className="text-left ">
          <Skeleton width="80%" height={15} className="rounded-md" />
        </div>

        

        {/* Other Details */}
        <div className="space-y-2 ">
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <div key={index} className="flex justify-between w-full">
                <Skeleton width={160} height={20} />
                {/* <Skeleton width={140} height={20} /> */}
              </div>
            ))}
        </div>
      </div>
    </SkeletonTheme>
            </>
          )}

          {profile.username && (
            <div className="flex flex-col">
              <div className="bg-red-300 relative w-full h-52 ">
                <img
                  alt="background img"
                  src={userphoto}
                  className="object-cover w-full h-full"
                />

                <div className=" px-4 absolute top-0 w-full">
                  <div className=" flex  justify-between items-center">
                    <div>
                      <img
                        src={backIcon}
                        className="w-5 h-5"
                        alt="rating"
                        onClick={() => navigate(-1)}
                      />
                    </div>
                    <DropdownMenu />
                  </div>
                </div>

                <div className="absolute px-2 top-3/4  w-full">
                  <div className="flex items-start justify-between">
                    <div>
                      <img
                        alt="profile picture"
                        src={userphoto}
                        className="object-cover rounded-full w-32 h-32 border-4 border-orange-300"
                      />
                    </div>
                    <div className="flex flex-row gap-2 items-center mt-14">
                      
                        <div className="flex flex-row rounded-lg">
                          <button
                            className=" p-0 px-2 rounded-lg"
                            disabled={disabledButton}
                            onClick={(e) =>
                              navigate(`/message/${modelid.toString()}`)
                            }
                          >
                            <img
                              src={messageIcon}
                              className="w-7 h-7"
                              alt="rating"
                            />
                          </button>
                        </div>
                      
                      <div className="flex flex-row bg-orange-500 p-1  rounded-lg">
                        <img src={followimg} className="w-5 h-5" alt="rating" />

                        <button
                          className=" p-0 px-2 rounded-lg"
                          disabled={disabledButton}
                          onClick={(e) => follow_button()}
                        >
                          {follow_text}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className=" mt-20 ml-4">
                  <p className="text-slate-200 font-bold pt-2 text-lg">
                    {firstname} {lastname}
                  </p>
                  <p className="text-slate-400">{nickname}</p>
                  <div className="flex gap-2">
                    <p className="font-bold text-slate-400">
                      {formatter.format(Number(likes))}{" "}
                      <span className="font-thin">likes</span>
                    </p>

                    <p className="font-bold text-slate-400">
                      {followers} <span className="font-thin">fans</span>
                    </p>
                  </div>

                  <p className="text-white font-bold">{about}</p>

                  {profile.ismodel && (
                    <button
                      className="bg-[#7e3500] text-[#eedfcb] rounded-lg p-1 px-2 mt-3"
                      onClick={(e) =>
                        navigate(`/modelbyid/${profile.modelid.toString()}`)
                      }
                    >
                      Request {profile.modeltype}
                    </button>
                  )}

                  <div className="flex gap-2 mt-3">
                    <img
                      src={locationIcon}
                      className="w-5 h-5"
                      alt="location"
                    />
                    <p className="text-slate-500">{location}</p>
                  </div>

                  <div className="flex gap-2">
                    <img
                      src={CalenderIcon}
                      className="w-5 h-5"
                      alt="joined date"
                    />

                    <p className="text-slate-500">
                      Joined {month}, {year}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className=" mx-4">
        <Tabs
          tabs={[
            { label: "Posts", content: myposts() },
            { label: "Exclusive", content: exclusive() },
            { label: "Info", content: info() },
          ]}
        />

        {/* <ul className="w-full mb-2">{myposts()}</ul> */}
      </div>
      </div>
    </div>
  );
}
