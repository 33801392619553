import React from "react";
import Breadcrumbs from "../components/breadcrumb";
import TitleSection from "../components/titleSection";
import ArticleList from "../components/articlelist";
import SearchBar from "../components/searchbar";
import Header from "../components/header";
import { FaGraduationCap } from "react-icons/fa";

export const DoesMmekowork = () => {
  return (
    <div className="bg-black min-h-screen text-gray-300 md:max-w-5xl m-auto ">
    <Header />
    <div className="p-4">
      <Breadcrumbs title="About" />
      <div>
        <header className="my-4">
          <h4 className="font-bold  text-2xl">How does Mmeko work ?</h4>
          
        </header>
        <p className='pt-1'>
         At Mmeko, Models use tip system. They set their own tip and fans can get special access to their 
         favourite models by tipping.

         Models on Mmeko can make money in different ways. Tips are the main income source, but they can also earn 
         extra through selling standalone content and more. In other words, they can make money in various ways, not just through tips.

        </p>

        
        </div>
    </div>
  </div>
  )
}