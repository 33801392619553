import React from "react";
import Breadcrumbs from "./components/breadcrumb";
import TitleSection from "./components/titleSection";
import ArticleList from "./components/articlelist";
import SearchBar from "./components/searchbar";
import Header from "./components/header";
import { FaGraduationCap } from "react-icons/fa";


export const AboutPage = () => {
  const articles = [
   { title:"What is Mmeko?" ,ref:"/aboutmmeko"},
    {title :"Who is Mmeko for?",ref:"/Whoismmeko"},
    {title:"Does Mmeko allow adult content creators?",ref:"/Contentcreators"},
    {title:"Do Mmeko allow explicit content?",ref:"/Explicitcontent"},
    {title:"How does Mmeko work?",ref:"/DoesMmekowork"},
    {title:"Who uses Mmeko?",ref:"/Whouse"},
    
  ];

  return (
    <div className="bg-black min-h-screen text-white ">
      <Header />
      <SearchBar />

      <div className="p-4">
        <Breadcrumbs title="About" />
        <TitleSection
          title="About"
          article="6 articles"
          icon={<FaGraduationCap color="" size={30} />}
        />

          <ArticleList articles={articles} />
        
      </div>
    </div>
  );
};
