import React, { useState, useEffect } from 'react'
import { Routes ,Route,useNavigate} from 'react-router-dom'
import { ToastContainer,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {verifyemail,error} from '../../app/features/register/registerSlice'
import {changeemailvery} from '../../app/features/register/registerSlice'
import { useDispatch, useSelector } from 'react-redux'

export const Confirmemail = ({setClosetoast,email,alert,dismissalert}) => {
 
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const errs = useSelector(error)
  const [code,setcode] = useState('')
  const stats = useSelector(state=> state.register.verifystatus)
  const userID = useSelector(state=> state.register.userID)

 useEffect(()=>{

  if(stats.toString() === "succeeded"){
  console.log(userID)
    alert(`succed`,'success',true)
    dispatch(changeemailvery('idle'));
    dismissalert();
    //setClosetoast(true)
    navigate(`/`)

  }
   if(stats.toString()=== "failed"){

    dispatch(changeemailvery('idle'));
    dismissalert()
    alert(`${errs}`,'error',true)

  }

 },[stats])

 

  const checkCode = async ()=>{
    if(!code){
      toast.error("enter Email",{autoClose:1000})
      return
    }

    if(stats.toString() !== "loading"){
      alert('please wait..',"info",false)
       dispatch(
        verifyemail({
          "email":email,
          "code":code
        })
       )
    }

  }

  return (
  
    <div className='text-black mx-auto text-center bg-black my-auto overflow-hidden
    border-0
    '>
      

        <p className='text-orange-500 text-xl font-bold'>Authenticate Email</p>
        <p className='text-orange-500 text-sm '>Confirmation code has been sent to your Email</p>

        <div className='mt-4 px-3'>

             <input type="text" className='inpt'
             placeholder='Enter Code'
             onInput={(e)=>{
              setcode(e.currentTarget.value)
             }}
             >
             </input>

            
             <button className='btn w-full mt-10 h-8 mb-3'
             onClick={async ()=>{
               await checkCode()
             }}
             >
              Confirm
             </button>


        </div>
        
    </div>
  )
}
