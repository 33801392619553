import React, { useState, useEffect } from "react";
import person from "../../../../icons/person.svg";
import { Viewdetails } from "../../../../fragment/modelnoteFrag/Viewdetails";
import PacmanLoader from "react-spinners/RotateLoader";
import { downloadImage } from "../../../../api/sendImage";
import { useNavigate } from "react-router-dom";
import {
  accepthost,
  resetstat,
  declinehost,
  getall_request,
  notifymodel
  
} from "../../../../app/features/booking/booking";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { CancelReq_frag } from "../componets/CancelReq_frag";

export const Meetuplist = ({
  clientname,
  type,
  photolink,
  date,
  time,
  venue,
  postuserid,
  modelid,
  status,
  latter1,
}) => {
  const [detailclick, setdetailclick] = useState(false);
  const [loading, setloading] = useState(false);
  let [color, setColor] = useState("#d49115");
  const [photo, setphoto] = useState(person);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accepstats = useSelector((state) => state.booking.accepstats);
  const acceptmessage = useSelector((state) => state.booking.acceptmessage);
  const token = useSelector((state) => state.register.refreshtoken);
  const userid = useSelector((state) => state.register.userID);
  const Mymodel = useSelector((state) => state.profile.modelID);
  const [delB_click , setdelB_click] = useState(false)
  

  useEffect(() => {
    if (photolink) {
      setphoto(downloadImage(photolink, "profile"));
    }
  }, []);

  useEffect(() => {
    if (accepstats === "succeeded") {
      setloading(!loading);
      dispatch(resetstat());
      check_status();
      dispatch(getall_request({ userid, token, modelid: Mymodel }));
      dispatch(notifymodel({ token,userid }));
      toast.success(`${acceptmessage}`, { autoClose: 2000 });
    }

    if (accepstats === "failed") {
      setloading(!loading);
      dispatch(resetstat());
      check_status();
      toast.success(`${acceptmessage}`, { autoClose: 2000 });
    }
  }, [accepstats]);

  const acceptClient = () => {
    if (accepstats !== "loading") {
      dispatch(accepthost({ modelid, time, date, userid: postuserid, token }));
      setloading(!loading);
    }
  };

  const check_status = () => {
    if (status === "accepted") {
      return false;
    } else {
      return true;
    }
  };

  const declineClient = () => {
    if (accepstats !== "loading") {
      dispatch(declinehost({ modelid, time, date, userid: postuserid, token }));
      setloading(!loading);
    }
  };

  return (
    <li className="bg-slate-300 w-full p-2 rounded-lg mb-2 ">
      {loading && (
        <div className="w-full flex flex-col items-center">
          <PacmanLoader
            color={color}
            loading={loading}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <p className="text-xs">Please wait...</p>
        </div>
      )}

{delB_click && (<CancelReq_frag setbutton={setdelB_click} cancel_req={declineClient}/>)}

      <ToastContainer position="top-center" theme="dark" />

      <div className=" w-full flex">
        <img
          alt="userimg"
          src={photo}
          className="rounded-full h-7 w-7 object-cover"
        ></img>
        <div className="ml-2">
          <p className="text-xs  p-1 bg-blue-50 rounded-sm">{latter1}</p>
          <div className="flex w-full justify-evenly space-x-1">
            {check_status() && (
              <button
                className="text-xs bg-green-500 p-1 rounded-xl"
                onClick={(e) => {
                  acceptClient();
                }}
              >
                Accept
              </button>
            )}

            <button
              className="text-xs bg-red-700 p-1 rounded-xl"
              onClick={(e) =>setdelB_click(true)}
            >
              Cancel
            </button>
            <button
              className="text-xs bg-blue-500 p-1 rounded-xl"
              onClick={(e) => {
                navigate(`/profile/${postuserid}`);
              }}
            >
              View profile
            </button>
            <button
              className="text-xs bg-yellow-500 p-1 rounded-xl"
              onClick={(e) => setdetailclick(true)}
            >
              View Details
            </button>
          </div>
        </div>
      </div>

      {detailclick && (
        <div className="w-full flex justify-center">
          <Viewdetails
            setdetailclick={setdetailclick}
            date={date}
            time={time}
            venue={venue}
            name={clientname}
            type={type}
          />
        </div>
      )}
    </li>
  );
};
