import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import notifymeIcon from "../../icons/notifymeIcon.svg";
import { Meetupview } from "./modelnotifyviews/Meetupview";
import { Allview } from "./modelnotifyviews/Allview";
import { Requestview } from "./modelnotifyviews/Requestview";
import { Acceptedview } from "./modelnotifyviews/Acceptedview";
import { useSelector } from "react-redux";
import { FaAngleLeft } from "react-icons/fa";

export const Modelnotify = () => {
  const navigate = useNavigate();
  const [all, setall] = useState("#292d31");
  const [meetup, setmeetup] = useState("");
  const [request, setrequest] = useState("");
  const [accepted, setaccepted] = useState("");
  const login = useSelector((state) => state.register.logedin);

  useEffect(() => {
    if (!login) {
      window.location.href = "/";
    }

    navigate("allview/");
  }, []);

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
      <header className="flex gap-4 items-center">
        <FaAngleLeft
          color="white"
          size={30}
          onClick={() => {
            navigate("/");
          }}
        />

        <h4 className="font-bold text-lg text-white">Notifications</h4>
      </header>

      <div className="pb-7 overflow-auto flex-col items-center w-full  mt-2">
        <div className=" overflow-auto flex justify-evenly md:mb-2">
          <button
            className="text-slate-400 text-center w-1/3 rounded-2xl p-0"
            onClick={(e) => {
              setall("#292d31");
              setmeetup("");
              setrequest("");
              setaccepted("");
              navigate("allview/");
            }}
            style={{ backgroundColor: `${all}` }}
          >
            <div className="flex justify-center">
              <p className="text-center text-xs">All</p>
            </div>
          </button>

          <button
            className="text-slate-400 mt-3  w-1/4 rounded-2xl"
            onClick={(e) => {
              setall("");
              setmeetup("#292d31");
              setrequest("");
              setaccepted("");
              navigate("meetupview/");
            }}
            style={{ backgroundColor: `${meetup}` }}
          >
            <div className="flex justify-center">
              <p className="text-center text-xs">Appointment</p>
            </div>
          </button>

          <button
            className="text-slate-400 mt-3  w-1/4 rounded-2xl"
            onClick={(e) => {
              setall("");
              setmeetup("");
              setrequest("#292d31");
              setaccepted("");
              navigate("requestview/");
            }}
            style={{ backgroundColor: `${request}` }}
          >
            <div className="flex justify-center">
              <p className="text-center text-xs">Request</p>
            </div>
          </button>

          <button
            className="text-slate-400 mt-3  w-1/4 rounded-2xl"
            onClick={(e) => {
              setall("");
              setmeetup("");
              setrequest("");
              setaccepted("#292d31");
              navigate("acceptedview/");
            }}
            style={{ backgroundColor: `${accepted}` }}
          >
            <div className="flex justify-center">
              <p className="text-center text-xs">Accepted</p>
            </div>
          </button>
        </div>

        <div className="overflow-auto"></div>
        <Routes>
          <Route path="meetupview/" element={<Meetupview />} />
          <Route path="allview/" element={<Allview />} />
          <Route path="requestview/" element={<Requestview />} />
          <Route path="acceptedview/" element={<Acceptedview />} />
        </Routes>
      </div>
    </div>
  );
};
