import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { URL } from "../../../api/config";
import axios from "axios";
import { saveImage, deleteImage, updateImage } from "../../../api/sendImage";

const initialState = {
  modelpoststatus: "idle",
  message: "",
  mymodel: [],
  mymodelstatus: "idle",
  modelbyid: {},
  modelbyidstatus: "idle",
  modelupdatestatus: "idle",
  modeldeletestatus: "idle",
  unverifiedhoststatus: "idle",
  Listofunverifiedhost: [],
  verifymodelstatus: "idle",
  rejectmodelstatus: "idle",
  ListofLivehost: [],
  Listofhoststatus: "idle",
  reviewstats: "idle",
  reviewmessage: "",
  getreviewstats: "idle",
  getreviewmessage: "",
  reviewList: [],
  review_delete_stats: "idle",
  review_delete_message: "",
  addcrush_stats: "idle",
  addcrush_message: "",
  getcrush_stats: "idle",
  getcrush_message: "",
  delete_msg_stats:"idle",
  delete_msg_message:"",
  remove_crush_stats:"idle",
  remove_crush_message:"",
  deletmodeImage_stats:"idle",
  exclusive_idphoto:"",
  exclusive_holdphoto:"",
  exclusive_ids_stats:"idle",
  exclusive_docs_stats:"idle",
  delete_docs_stats:"idle"
  
};

export const createmodel = createAsyncThunk(
  "model/createmodel",
  async (data) => {
    try {
      let infomfomation;
      let document = [];
      let photolink = [];

      if (data.photolink) {
        console.log("inside model photo");
        for (let i = 0; i < data.photolink.length; i++) {
          console.log("in document");
          const links = await saveImage(data.photolink[i], "model");
          photolink.push(links);
        }

     

        console.log(photolink);
      }

      infomfomation = {
        userid: data.userid,
        photolink: photolink.toString(),
        token: data.token,
        name: data.name,
        age: data.age,
        location: data.location,
        price: data.price,
        duration: data.duration,
        bodytype: data.bodytype,
        smoke: data.smoke,
        drink: data.drink,
        interestedin: data.interestedin,
        height: data.height,
        weight: data.weight,
        description: data.discription,
        gender: data.gender,
        timeava: data.timeava,
        daysava: data.daysava,
        token: data.token,
        hosttype: data.hosttype,
      };
      console.log("after info");

      //console.log('ontop get profile')
      let response = await axios.put(`${URL}/model`, infomfomation);
      // console.log('under get profile')

      return response.data;
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);

export const delete_exclusive_ids = createAsyncThunk(
  "model/delete_exclusive_ids",
  async (data) => {
    try {
     console.log("outside data")
      if (data.file > 0) {
        console.log("inside data")
        for(let i = 0; i < data.file; i++){
          console.log("deleting "+data.file[i])
          deleteImage(data.file[i],"model")
        }
       
         
      }

      return  "sucesss";
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);

export const post_exclusive_ids = createAsyncThunk(
  "model/post_exclusive_ids",
  async (data) => {
    try {
      let dataPonit = {
        holdphoto :"",
        id:""
      }
      
      if (data) {
        dataPonit.holdphoto = await saveImage(data.holdingIdPhotofile, "model");
        dataPonit.id = await saveImage(data.idPhotofile, "model");
         
      }

     

      return  dataPonit;
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);

export const post_exclusive_docs = createAsyncThunk(
  "model/post_exclusive_docs",
  async (data) => {
    try {
     
    
      let response = await axios.put(`${URL}/postdocument`, data);
      // console.log('under get profile')

      return response.data;
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);

export const getmymodel = createAsyncThunk("model/getmymodel", async (data) => {
  try {
    console.log("after info");

    //console.log('ontop get profile')
    let response = await axios.post(`${URL}/model`, data);
    // console.log('under get profile')

    return response.data;
  } catch (err) {
    if (!err.response.data.message) {
      throw "check internet connection";
    }
    throw err.response.data.message;
  }
});

export const getmymodelbyid = createAsyncThunk(
  "model/getmymodelbyid",
  async (data) => {
    try {
      console.log("after info");

      //console.log('ontop get profile')
      let response = await axios.patch(`${URL}/getmodelbyid`, data);
      // console.log('under get profile')

      return response.data;
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);

export const updatemodel = createAsyncThunk(
  "model/updatemodel",
  async (data) => {
    try {
      let newphotolink = [];

      if (data.photolink) {
        for (let i = 0; i < data.photolink.length; i++) {
          const links = await saveImage(data.photolink[i], "model");
          //console.log(links + "LINKS");
          newphotolink.push(links);
        }
      }
      console.log(" after upadting model db on server");
      let info = {
        age: data.age,
        location: data.location,
        price: data.price,
        duration: data.duration,
        bodytype: data.bodytype,
        smoke: data.smoke,
        drink: data.drink,
        interestedin: data.interestedin,
        height: data.height,
        weight: data.weight,
        description: data.description,
        gender: data.gender,
        timeava: data.timeava,
        daysava: data.daysava,
        hosttype: data.hosttype,
        hostid: data.hostid,
        token: data.token,
        photolink: newphotolink.toString(),
      };
      let response = await axios.post(`${URL}/editmodel`, info);

      return response.data;
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);
export const deletemodelImage = createAsyncThunk(
  "model/deletemodelImage",
  async (data) => {
    try {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          await deleteImage(data[i], "model");
        }
      }
   

      return "success";
    } catch (err) {
      throw err;
    }
  }
);

export const deletemodel = createAsyncThunk(
  "model/deletemodel ",
  async (data) => {
    try {
      if (data.photolink) {
        for (let i = 0; i < data.photocount; i++) {
          await deleteImage(data.oldlink[i], "model");
        }

        for (let i = 0; i < data.docCount; i++) {
          await deleteImage(data.documentlink[i], "model");
        }
      }
      console.log(" after upadting model db on server");

      let info = {
        document: data.documentlink,
        photolink: data.photolink,
        hostid: data.hostid,
        token: data.token,
      };

      let response = await axios.post(`${URL}/deletemodel`, info);

      return response.data;
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);

//undode for getting exclusive that we need to verify
export const unverifiedHost = createAsyncThunk(
  "model/unverifiedHost ",
  async (data) => {
    try {
      let response = await axios.post(`${URL}/getadminhost`, data);

      return response.data;
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);

export const verifymodel = createAsyncThunk(
  "model/verifymodel ",
  async (data) => {
    try {
      let response = await axios.post(`${URL}/verifymodel`, data);

      return response.data;
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);

export const rejectmodel = createAsyncThunk(
  "model/rejectmodel ",
  async (data) => {
    try {
      let response = await axios.post(`${URL}/rejectmodel`, data);

      return response.data;
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);

export const getverifyhost = createAsyncThunk(
  "model/getverifyhost ",
  async (data) => {
    try {
      let response = await axios.post(`${URL}/getverifymodel`, data);

      return response.data;
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);

export const review = createAsyncThunk("model/review ", async (data) => {
  try {
    let response = await axios.put(`${URL}/reviewmodel`, data);

    return response.data;
  } catch (err) {
    if (!err.response.data.message) {
      throw "check internet connection";
    }
    throw err.response.data.message;
  }
});

export const getreview = createAsyncThunk("model/getreview ", async (data) => {
  try {
    let response = await axios.put(`${URL}/getreviews`, data);

    return response.data;
  } catch (err) {
    if (!err.response.data.message) {
      throw "check internet connection";
    }
    throw err.response.data.message;
  }
});

export const deletereview = createAsyncThunk(
  "model/deletereview ",
  async (data) => {
    try {
      let response = await axios.put(`${URL}/deletereview`, data);

      return response.data;
    } catch (err) {
      if (!err.response.data.message) {
        throw "check internet connection";
      }
      throw err.response.data.message;
    }
  }
);

export const addcrush = createAsyncThunk("model/addcrush ", async (data) => {
  try {
    let response = await axios.post(`${URL}/addcrush`, data);

    return response.data;
  } catch (err) {
    if (!err.response.data.message) {
      throw "check internet connection";
    }
    throw err.response.data.message;
  }
});

export const getcrush = createAsyncThunk("model/getcrushs ", async (data) => {
  try {
    let response = await axios.get(`${URL}/getcrushs`, data);

    return response.data;
  } catch (err) {
    if (!err.response.data.message) {
      throw "check internet connection";
    }
    throw err.response.data.message;
  }
});

export const delete_MSG = createAsyncThunk("model/delete_MSG ", async (data) => {
  try {
    let response = await axios.post(`${URL}/deleteMsg`, data);

    return response.data;
  } catch (err) {
    if (!err.response.data.message) {
      throw "check internet connection";
    }
    throw err.response.data.message;
  }
});

export const remove_Crush = createAsyncThunk("model/remove_Crush ", async (data) => {
  try {
    let response = await axios.post(`${URL}/deletecrush`, data);

    return response.data;
  } catch (err) {
    if (!err.response.data.message) {
      throw "check internet connection";
    }
    throw err.response.data.message;
  }
});

const model = createSlice({
  name: "model",
  initialState,
  reducers: {
    changemodelstatus(state, action) {
      state.modelpoststatus = action.payload;
      state.mymodelstatus = action.payload;
      state.modelbyidstatus = action.payload;
      state.modelupdatestatus = action.payload;
      state.modeldeletestatus = action.payload;
      state.unverifiedhoststatus = action.payload;
      state.verifymodelstatus = action.payload;
      state.rejectmodelstatus = action.payload;
      state.Listofhoststatus = action.payload;
      state.reviewstats = action.payload;
      state.review_delete_stats = action.payload;
      state.addcrush_stats = action.payload;
      state.getcrush_stats = action.payload;
      state.delete_msg_stats = action.payload;
      state.remove_crush_stats = action.payload
      state.deletmodeImage_stats = action.payload
      state.exclusive_ids_stats = action.payload
      state.delete_docs_stats = action.payload
    },
   
  },
  extraReducers(builder) {
    builder
      .addCase(createmodel.pending, (state, action) => {
        state.modelpoststatus = "loading";
      })
      .addCase(createmodel.fulfilled, (state, action) => {
        state.modelpoststatus = "succeeded";
        state.message = action.payload.message;
      })
      .addCase(createmodel.rejected, (state, action) => {
        state.modelpoststatus = "failed";

        if (!action.error.message) {
          state.message = "Check internet connection";
        } else {
          state.message = action.error.message;
        }
      })
      .addCase(getmymodel.pending, (state, action) => {
        state.mymodelstatus = "loading";
      })
      .addCase(getmymodel.fulfilled, (state, action) => {
        state.mymodelstatus = "succeeded";
        state.message = action.payload.message;
        state.mymodel = action.payload.host;
      })
      .addCase(getmymodel.rejected, (state, action) => {
        state.mymodelstatus = "failed";

        if (!action.error.message) {
          state.message = "Check internet connection";
        } else {
          state.message = action.error.message;
        }
      })
      .addCase(getmymodelbyid.pending, (state, action) => {
        state.modelbyidstatus = "loading";
      })
      .addCase(getmymodelbyid.fulfilled, (state, action) => {
        state.modelbyidstatus = "succeeded";
        state.message = action.payload.message;
        state.modelbyid = action.payload.host;
      })
      .addCase(getmymodelbyid.rejected, (state, action) => {
        state.modelbyidstatus = "failed";

        if (!action.error.message) {
          state.message = "Check internet connection";
        } else {
          state.message = action.error.message;
        }
      })
      .addCase(updatemodel.pending, (state, action) => {
        state.modelupdatestatus = "loading";
      })
      .addCase(updatemodel.fulfilled, (state, action) => {
        state.modelupdatestatus = "succeeded";
        state.message = action.payload.message;
      })
      .addCase(updatemodel.rejected, (state, action) => {
        state.modelupdatestatus = "failed";

        if (!action.error.message) {
          state.message = "Check internet connection";
        } else {
          state.message = action.error.message;
        }
      })
      .addCase(deletemodel.pending, (state, action) => {
        state.modeldeletestatus = "loading";
      })
      .addCase(deletemodel.fulfilled, (state, action) => {
        state.modeldeletestatus = "succeeded";
        state.message = action.payload.message;
      })
      .addCase(deletemodel.rejected, (state, action) => {
        state.modeldeletestatus = "failed";

        if (action.error.message === undefined) {
          state.message = "Check internet connection";
        } else {
          state.message = action.error.message;
        }
      })
      .addCase(unverifiedHost.pending, (state, action) => {
        state.unverifiedhoststatus = "loading";
      })
      .addCase(unverifiedHost.fulfilled, (state, action) => {
        state.unverifiedhoststatus = "succeeded";
        state.message = action.payload.message;
        state.Listofunverifiedhost = action.payload.hosts;
      })
      .addCase(unverifiedHost.rejected, (state, action) => {
        state.unverifiedhoststatus = "failed";

        if (action.error.message === undefined) {
          state.message = "Check internet connection";
        } else {
          state.message = action.error.message;
        }
      })
      .addCase(verifymodel.pending, (state, action) => {
        state.verifymodelstatus = "loading";
      })
      .addCase(verifymodel.fulfilled, (state, action) => {
        state.verifymodelstatus = "succeeded";
        state.message = action.payload.message;
        if (action.payload.hostid) {
          let index = state.Listofunverifiedhost.findIndex((value) => {
            return value.id === action.payload.hostid;
          });

          if (index !== -1) {
            state.Listofunverifiedhost.splice(index, 1);
          }
        }
      })
      .addCase(verifymodel.rejected, (state, action) => {
        state.verifymodelstatus = "failed";

        if (action.error.message === undefined) {
          state.message = "Check internet connection";
        } else {
          state.message = action.error.message;
        }
      })
      .addCase(rejectmodel.pending, (state, action) => {
        state.rejectmodelstatus = "loading";
      })
      .addCase(rejectmodel.fulfilled, (state, action) => {
        state.rejectmodelstatus = "succeeded";
        state.message = action.payload.message;
        if (action.payload.hostid) {
          let index = state.Listofunverifiedhost.findIndex((value) => {
            return value.id === action.payload.hostid;
          });

          if (index !== -1) {
            state.Listofunverifiedhost.splice(index, 1);
          }
        }
      })
      .addCase(rejectmodel.rejected, (state, action) => {
        state.rejectmodelstatus = "failed";

        if (action.error.message === undefined) {
          state.message = "Check internet connection";
        } else {
          state.message = action.error.message;
        }
      })
      .addCase(getverifyhost.pending, (state, action) => {
        state.Listofhoststatus = "loading";
      })
      .addCase(getverifyhost.fulfilled, (state, action) => {
        state.Listofhoststatus = "succeeded";
        state.message = action.payload.message;
        state.ListofLivehost = action.payload.host;
      })
      .addCase(getverifyhost.rejected, (state, action) => {
        state.Listofhoststatus = "failed";

        if (action.error.message === undefined) {
          state.message = "Check internet connection";
        } else {
          state.message = action.error.message;
        }
      })
      .addCase(review.pending, (state, action) => {
        state.reviewstats = "loading";
      })
      .addCase(review.fulfilled, (state, action) => {
        state.reviewstats = "succeeded";
        state.reviewmessage = action.payload.message;
      })
      .addCase(review.rejected, (state, action) => {
        state.reviewstats = "failed";

        if (!action.error) {
          state.reviewmessage = "Check internet connection";
        } else {
          state.reviewmessage = action.error.message;
        }
      })
      .addCase(getreview.pending, (state, action) => {
        state.getreviewstats = "loading";
      })
      .addCase(getreview.fulfilled, (state, action) => {
        state.getreviewstats = "succeeded";
        state.getreviewmessage = action.payload.message;
        state.reviewList = action.payload.reviews;
      })
      .addCase(getreview.rejected, (state, action) => {
        state.getreviewstats = "failed";

        if (!action.error) {
          state.getreviewmessage = "Check internet connection";
        } else {
          state.getreviewmessage = action.error.message;
        }
      })
      .addCase(deletereview.pending, (state, action) => {
        state.review_delete_stats = "loading";
      })
      .addCase(deletereview.fulfilled, (state, action) => {
        state.review_delete_stats = "succeeded";
        state.review_delete_message = action.payload.message;
        let id = action.payload.id;

        let index = state.reviewList.findIndex((value) => {
          return id === value.id;
        });

        if (index !== -1) {
          state.reviewList.splice(index, 1);
        }
      })
      .addCase(deletereview.rejected, (state, action) => {
        state.review_delete_stats = "failed";

        if (!action.error) {
          state.review_delete_message = "Check internet connection";
        } else {
          state.review_delete_message = action.error.message;
        }
      })
      .addCase(addcrush.pending, (state, action) => {
        state.addcrush_stats = "loading";
      })
      .addCase(addcrush.fulfilled, (state, action) => {
        state.addcrush_stats = "succeeded";
        state.addcrush_message = action.payload.message;
      })
      .addCase(addcrush.rejected, (state, action) => {
        state.addcrush_stats = "failed";

        if (!action.error) {
          state.addcrush_message = "Check internet connection";
        } else {
          state.addcrush_message = action.error.message;
        }
      })
      .addCase(getcrush.pending, (state, action) => {
        state.getcrush_stats = "loading";
      })
      .addCase(getcrush.fulfilled, (state, action) => {
        state.getcrush_stats = "suceeded";
        state.getcrush_message = action.payload.message;
      })
      .addCase(getcrush.rejected, (state, action) => {
        state.getcrush_stats = "failed";
        if (!action.error) {
          state.getcrush_stats = "Check internet connection";
        } else {
          state.getcrush_stats = action.error.message;
        }
      })
       .addCase(delete_MSG.pending, (state, action) => {
        state.delete_msg_stats = "loading";
      })
      .addCase(delete_MSG.fulfilled, (state, action) => {
        state.delete_msg_stats = "suceeded";
        state.delete_msg_messge = action.payload.message;
      })
      .addCase(delete_MSG.rejected, (state, action) => {
        state.delete_msg_stats = "failed";
        if (!action.error) {
          state.delete_msg_messge = "Check internet connection";
        } else {
          state.delete_msg_messge = action.error.message;
        }
      })
       .addCase(remove_Crush.pending, (state, action) => {
        state.remove_crush_stats = "loading";
      })
      .addCase(remove_Crush.fulfilled, (state, action) => {
        state.remove_crush_stats = "succeeded";
        state.remove_crush_message = action.payload.message;
      })
      .addCase(remove_Crush.rejected, (state, action) => {
        state.remove_crush_stats = "failed";
        if (!action.error) {
          state.remove_crush_message = "Check internet connection";
        } else {
          state.remove_crush_message = action.error.message;
        }
      })
      .addCase(deletemodelImage.pending, (state, action) => {
        state.deletmodeImage_stats = "loading";
      })
      .addCase(deletemodelImage.fulfilled, (state, action) => {
        state.deletmodeImage_stats = "succeeded";
        
        
      })
      .addCase(deletemodelImage.rejected, (state, action) => {
        state.deletmodeImage_stats = "failed";
       
      })
      .addCase(post_exclusive_ids.pending, (state, action) => {
        state.exclusive_ids_stats = "loading";
      })
      .addCase(post_exclusive_ids.fulfilled, (state, action) => {
        state.exclusive_ids_stats = "succeeded";
       
        state.exclusive_holdphoto = action.payload.holdphoto
        state.exclusive_idphoto = action.payload.id
        
      })
      .addCase(post_exclusive_ids.rejected, (state, action) => {
        state.exclusive_ids_stats = "failed";
       
       
      })
      .addCase(post_exclusive_docs.pending, (state, action) => {
        state.exclusive_docs_stats = "loading";
      })
      .addCase(post_exclusive_docs.fulfilled, (state, action) => {
        state.exclusive_docs_stats = "succeeded";

        console.log("id success")

        
      })
      .addCase(post_exclusive_docs.rejected, (state, action) => {
        state.exclusive_docs_stats = "failed";
        console.log("id failed "+action.error.message)
       
      })
      .addCase(delete_exclusive_ids.pending, (state, action) => {
        state.delete_docs_stats = "loading";
      })
      .addCase(delete_exclusive_ids.fulfilled, (state, action) => {
        state.delete_docs_stats = "succeeded";

        
      })
      .addCase(delete_exclusive_ids.rejected, (state, action) => {
        state.delete_docs_stats = "failed";
       
      })
  },
});

export default model.reducer;

export const { changemodelstatus } = model.actions;
