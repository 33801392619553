import React,{useState , useEffect} from 'react'
import dodo from "../../../../icons/person.svg"
import deleteicon from "../../../../icons/deleteicon.svg"
import reviewicon from "../../../../icons/icons8-review.png"
import PacmanLoader from "react-spinners/RotateLoader";
import { useSelector, useDispatch } from 'react-redux'
import { downloadImage } from '../../../../api/sendImage';
import { completepayment , resetstat, deleteModel,Cancelrequest } from '../../../../app/features/booking/booking';
import { review, changemodelstatus } from '../../../../app/features/model/modelSlice';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { getall_request, acceptedr_req,add_call_data } from '../../../../app/features/booking/booking';
import {CancelReq_frag} from "../componets/CancelReq_frag"
let comtext = "Mark as Complete"

export const Acceptedlist = ({name,status,type,date,time,modelid, photolink, id, modeluserid ,amount}) => {
     const [loading,setloading] = useState(false)
      let [color, setColor] = useState("#d49115");
      let [image,setimage] = useState(dodo)
      let [revcontent, setrevcontent] = useState("")
      const userid = useSelector(state => state.register.userID)
      const cancelstats = useSelector(state => state.booking.cancelstats)
      const cancelmessage = useSelector(state => state.booking.cancelmessage)
      const balance = useSelector((state) => state.profile.balance);
      const Mymodel = useSelector((state) => state.profile.modelID);
      const profilename = useSelector((state) => state.profile.firstname);
      let [approvebutton, setapprovebutton] = useState(false)
      const paystats = useSelector(state => state.booking.paystats)
      const paymessage = useSelector(state => state.booking.paymessage)
      const token = useSelector(state => state.register.refreshtoken)
      const reviewstats = useSelector(state => state.model.reviewstats)
      const reviewmessage = useSelector(state => state.model.reviewmessage)
      const [reviewbutt, setreviewbutt] = useState(false)
      const dispatch = useDispatch()
      const navigate = useNavigate()
      const [delB_click, setdelB_click] = useState(false)

      useEffect(()=>{
         setimage(downloadImage(photolink,"model"))
        if(status === "accepted"){
          setapprovebutton(true)

        }else if(status === "decline"){
          setapprovebutton(false)

        }

      },[])

      useEffect(()=>{
        if(paystats === "succeeded"){
          dispatch(deleteModel({modelid:modelid,date:date,time:time}))
          dispatch(getall_request({ userid, token, modelid: Mymodel }));
          dispatch(acceptedr_req({ userid, token }));
          dispatch(resetstat())
          setloading(!loading)
        }

         if(cancelstats === "succeeded"){
          dispatch(deleteModel({modelid:modelid,date:date,time:time}))
          dispatch(getall_request({ userid, token, modelid: Mymodel }));
          dispatch(acceptedr_req({ userid, token }));
          dispatch(resetstat())
          setloading(!loading)
        }

         if(paystats === "failed"){
         
          setloading(!loading)

          console.log("complete payment message "+paystats)
        }

         if(cancelstats === "failed"){
         
          setloading(!loading)

          console.log("complete payment message "+cancelmessage)
        }

        if(reviewstats === "succeeded"){
          setrevcontent('')
          setreviewbutt(false)
          setloading(false)
          dispatch(changemodelstatus("idle"))
        }

        if(reviewstats === "failed"){
          setloading(false)
          dispatch(changemodelstatus("idle"))
        }
      },[paystats,cancelstats, reviewstats])

      const showbutton = ()=>{
        if(approvebutton){
          return false
        }else{
          return true
        }
      }

      const paycomlete = ()=>{
        if(paystats !== "Loading"){
          setloading(!loading)
          dispatch(completepayment({token,userid,modelid,date,time}))
        }

      }

      const showPS_button = ()=>{

         if(type === "Private show"){

          return  <button className='text-xs bg-yellow-500 rounded-lg p-2 shadow shadow-black mt-1 active:bg-yellow-300 hover:bg-yellow-200 ' 
          onClick={(e)=>{
          
            let today = Date.now()
            if(today >= date){
              toast.info("your private call schedule not yet time",{autoClose:false})
              return
            }
            console.log("model price "+amount)
           let modelid2 = [modeluserid,userid,"caller",profilename,amount,balance]
           dispatch(add_call_data(modelid2))
           navigate(`/privatecall`)
          }}
          >
            start private show
          </button>

         }else{
          return  <button className='text-xs bg-yellow-500 rounded-lg p-2 shadow shadow-black mt-1 active:bg-yellow-300 hover:bg-yellow-200 ' 
          onClick={(e)=>{
           paycomlete()
           comtext = "Completed"
          }}
          >
            {comtext}
          </button>
         }
      }

      const deleterequest = ()=>{
        if(cancelstats !== "loading"){
          setloading(!loading)
          dispatch(Cancelrequest({token,modelid,userid,date,time}))
        }
      }

      const reviewbutton =  ()=>{
        if(reviewstats !== "loading"){
          setloading(true)
          dispatch(review({token, userid, modelid, content:revcontent}))
        }
      }


  return (
    <li className='bg-slate-300 p-1 w-full ml-2 mr-2 rounded-lg mb-2'>
      <ToastContainer position="top-center" theme="dark" />

          {loading &&
           <div className='w-full flex flex-col items-center mb-2'>
              <PacmanLoader
                color={color}
                loading={loading}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
               
              />

              <p className='text-xs'>please wait...</p>

        </div>
        }
        <div className='flex'>
            <img alt='modelimg' src={image} className='w-7 h-7 rounded-full object-cover'></img>
            <div className='flex flex-col ml-1 p-1'>
                <p className='text-xs bg-blue-100'>{`${name}`} {`${status}`} your {`${type}`} request on {`${date}`} {`${time}`}</p>

            </div>

        </div>

        {
          approvebutton &&
          <div className='flex w-full justify-center'>
          {showPS_button()}

        </div>

        }

        {delB_click && (<CancelReq_frag setbutton={setdelB_click} cancel_req={deleterequest}/>)}

        
         
          <div className='w-full flex justify-between'>
            {
              approvebutton &&
               <button onClick={(e)=>setreviewbutt(!reviewbutt)}>
                <div>
                  <div className='flex justify-center'>
                    <img alt='reviewicon' src={reviewicon} className='object-cover w-5 h-5'>
                    </img>
                  </div>
                  <p className='text-xs'>Drop review</p>
                </div>

              </button>

            }
           

            {
               showbutton() && 
              <button onClick={(e)=> deleterequest()}>
                      <img alt='deleteimg' src={deleteicon} className='w-4 h-4 object-cover mt-1'></img>
              </button>
            }
            
              

         </div>

         {reviewbutt && 

          <div className='w-full items-center flex flex-col'>
            <div className='flex justify-start'>
              
            </div>
          <textarea type='text'  className='rounded-md bg-slate-400 placeholder:text-white placeholder:text-xs placeholder:text-center w-1/2 h-14' placeholder='write review about this model'
          onInput={(e)=>setrevcontent(e.currentTarget.value)}
          >
          </textarea>
          <button className='bg-green-500 mt-2 mb-2 p-2 text-xs rounded text-slate-50 shadow shadow-black' onClick={(e)=>reviewbutton()}>
            Send
          </button>

         </div>
         }

        
        
    </li>
  )
}
